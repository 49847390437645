import "./Vision.scss";

const Vision = () => {
    return (
        <div className={"vision"}>
            <h6 className={"primary-color t-align-center"}>Vision</h6>
            <p className={"secondary-color t-align-center"}>To be the best leading car servicing centre.</p>
        </div>
    )
}

export default Vision;