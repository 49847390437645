import Hyundai_SantaFe_IMG_9951_Jpg from "./IMG_9951.jpeg";
import Hyundai_SantaFe_IMG_9952_Jpg from "./IMG_9952.jpeg";
import Hyundai_SantaFe_IMG_9954_Jpg from "./IMG_9954.jpeg";
import Hyundai_SantaFe_IMG_9964_Jpg from "./IMG_9964.jpeg";
import Hyundai_SantaFe_IMG_9971_Jpg from "./IMG_9971.jpeg";
import Hyundai_SantaFe_IMG_9975_Jpg from "./IMG_9975.jpeg";
import Hyundai_SantaFe_IMG_9988_Jpg from "./IMG_9988.jpeg";
import Hyundai_SantaFe_IMG_9991_Jpg from "./IMG_9991.jpeg";
import Hyundai_SantaFe_IMG_9994_Jpg from "./IMG_9994.jpeg";
import Hyundai_SantaFe_IMG_9998_Jpg from "./IMG_9998.jpeg";

import Hyundai_SantaFe_IMG_9951_Webp from "./IMG_9951.webp";
import Hyundai_SantaFe_IMG_9952_Webp from "./IMG_9952.webp";
import Hyundai_SantaFe_IMG_9954_Webp from "./IMG_9954.webp";
import Hyundai_SantaFe_IMG_9964_Webp from "./IMG_9964.webp";
import Hyundai_SantaFe_IMG_9971_Webp from "./IMG_9971.webp";
import Hyundai_SantaFe_IMG_9975_Webp from "./IMG_9975.webp";
import Hyundai_SantaFe_IMG_9988_Webp from "./IMG_9988.webp";
import Hyundai_SantaFe_IMG_9991_Webp from "./IMG_9991.webp";
import Hyundai_SantaFe_IMG_9994_Webp from "./IMG_9994.webp";
import Hyundai_SantaFe_IMG_9998_Webp from "./IMG_9998.webp";

export const Hyundai_SantaFe_IMG_9951 = {jpg: Hyundai_SantaFe_IMG_9951_Jpg, webp: Hyundai_SantaFe_IMG_9951_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9952 = {jpg: Hyundai_SantaFe_IMG_9952_Jpg, webp: Hyundai_SantaFe_IMG_9952_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9954 = {jpg: Hyundai_SantaFe_IMG_9954_Jpg, webp: Hyundai_SantaFe_IMG_9954_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9964 = {jpg: Hyundai_SantaFe_IMG_9964_Jpg, webp: Hyundai_SantaFe_IMG_9964_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9971 = {jpg: Hyundai_SantaFe_IMG_9971_Jpg, webp: Hyundai_SantaFe_IMG_9971_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9975 = {jpg: Hyundai_SantaFe_IMG_9975_Jpg, webp: Hyundai_SantaFe_IMG_9975_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9988 = {jpg: Hyundai_SantaFe_IMG_9988_Jpg, webp: Hyundai_SantaFe_IMG_9988_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9991 = {jpg: Hyundai_SantaFe_IMG_9991_Jpg, webp: Hyundai_SantaFe_IMG_9991_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9994 = {jpg: Hyundai_SantaFe_IMG_9994_Jpg, webp: Hyundai_SantaFe_IMG_9994_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};
export const Hyundai_SantaFe_IMG_9998 = {jpg: Hyundai_SantaFe_IMG_9998_Jpg, webp: Hyundai_SantaFe_IMG_9998_Webp, alt: "Hyundai SantaFe", w: "1080", h: "810"};