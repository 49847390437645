const base_path = "/new_images/ceramic_coating/3__KIA_Carnival/";

const file_names: string[] = [
    "IMG_4542",
    "IMG_4543",
    "IMG_4556",
    "IMG_4568",
    "IMG_4572",
    "IMG_4576",
    "IMG_4592",
    "IMG_4610",
    "IMG_4644",
    "IMG_4645",
    "IMG_4646",
    "IMG_4742",
    "IMG_4753",
    "IMG_4774",
    "IMG_4777",
    "IMG_4781",
    "IMG_4796",
    "IMG_4820",
    "IMG_4821",
    "IMG_4822",
    "IMG_4823",
    "IMG_4824",
    "IMG_4830", // preview
    "IMG_4831",
    "IMG_4832",
    "IMG_4836",
    "IMG_4837",
    "IMG_4863",
    "IMG_4864",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_4542": {w: 1920, h: 1440},
    "IMG_4543": {w: 1920, h: 1440},
    "IMG_4556": {w: 1920, h: 1440},
    "IMG_4568": {w: 1920, h: 1080},
    "IMG_4572": {w: 1920, h: 1080},
    "IMG_4576": {w: 1920, h: 1440},
    "IMG_4592": {w: 1920, h: 1080},
    "IMG_4610": {w: 1920, h: 1440},
    "IMG_4644": {w: 1920, h: 1080},
    "IMG_4645": {w: 1280, h: 960},
    "IMG_4646": {w: 1600, h: 1200},
    "IMG_4742": {w: 1920, h: 1080},
    "IMG_4753": {w: 1920, h: 1080},
    "IMG_4774": {w: 1920, h: 1080},
    "IMG_4777": {w: 1920, h: 1080},
    "IMG_4781": {w: 1920, h: 1080},
    "IMG_4796": {w: 1920, h: 1080},
    "IMG_4820": {w: 1920, h: 1440},
    "IMG_4821": {w: 1920, h: 1440},
    "IMG_4822": {w: 1920, h: 1440},
    "IMG_4823": {w: 1920, h: 1440},
    "IMG_4824": {w: 1920, h: 1440},
    "IMG_4830": {w: 1920, h: 1440},
    "IMG_4831": {w: 1920, h: 1440},
    "IMG_4832": {w: 1920, h: 1440},
    "IMG_4836": {w: 1920, h: 1440},
    "IMG_4837": {w: 1920, h: 1440},
    "IMG_4863": {w: 1920, h: 1440},
    "IMG_4864": {w: 1920, h: 1440},
}

export const KIA_Carnival = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "KIA Carnival",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});