const base_path = "/new_images/ceramic_coating/9__Maruti_Suzuki_Kiazashi/";

const file_names: string[] = [
    "IMG_3055",
    "IMG_3169",
    "IMG_3170",
    "IMG_4388", // preview
    "IMG_4403",
    "IMG_4407",
    "IMG_4408",
    "IMG_4411",
    "IMG_4424",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_3055": {w: 1920, h: 1440},
    "IMG_3169": {w: 1528, h: 1920},
    "IMG_3170": {w: 1920, h: 1440},
    "IMG_4388": {w: 1920, h: 1440},
    "IMG_4403": {w: 1920, h: 1440},
    "IMG_4407": {w: 1920, h: 1440},
    "IMG_4408": {w: 1920, h: 1440},
    "IMG_4411": {w: 1920, h: 1440},
    "IMG_4424": {w: 1920, h: 1440},
}

export const Maruti_Suzuki_Kiazashi = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Maruti Suzuki Kiazashi",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});