import './App.scss';

import { Suspense, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import Routes from './Common/Routes';
import Header from './Common/Header/Header';
import Footer from './Common/Footer/Footer';

import LoadingScreenUI, { LOADING_SCREEN } from './LoadingScreenUI/LoadingScreenUI';
import { PopupForm } from './Common/Components/Components';
import { usePageTracking } from './Utils/js/Utils';

function App() {

    usePageTracking({trackingCode: "UA-193016062-1"});

    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        const viewport = document.querySelector('meta[name="viewport"]');
        if (description) {
          description.remove();
        }
        if (viewport) {
            viewport.remove();
          }
    }, [])
    
    return (
        <>
        <div className="App">
            <Helmet>
                <title>Homepage</title>
                <meta name="viewport" content="width=device-width,minimum-scale=1"/>
                <meta name="description" content="Homepage"/>
            </Helmet>
            <Suspense fallback={<LoadingScreenUI name={LOADING_SCREEN["LOADING"]}/>}>
                <PopupForm />
                <Header />
                <div className={"main"} id={"main"}>
                    <Routes />
                </div>
                <Footer />
            </Suspense>
        </div>
        </>
    );
}

export default App;
