const base_path = "/new_images/ceramic_coating/10__Hyundai_Creta/";

const file_names: string[] = [
    "1",
    "2", // preview
    "3",
    "4",
    "5",
    "6",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "1": {w: 1080, h: 810},
    "2": {w: 1080, h: 810},
    "3": {w: 1080, h: 810},
    "4": {w: 1080, h: 810},
    "5": {w: 1080, h: 810},
    "6": {w: 1080, h: 810},
}

export const Hyundai_Creta = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Hyundai Creta",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});