import { Link } from "react-router-dom";

import "./BlogCard.scss";

interface BlogCardParams {
    title: string,
    content: string,
    img: string, alt?: string, imgWebp?: string, w?: string, h?: string,
    year: number,
    month: string,
    path: string
}

const BlogCard = ({title, content, img, alt, imgWebp, w, h, year, month, path}: BlogCardParams) => {
    return (
        <div className={"blog-card card-panel center"}>
            <div className={"blog-card-img"}>
                <picture>
                    {imgWebp && <source type={"image/webp"} srcSet={imgWebp}/>}
                    <img
                        src={img} alt={alt?alt:"Blog"}
                        width={w?w:undefined} height={h?h:undefined}    
                    />
                </picture>
                <div className={"blog-card-date t-align-right"}>
                    <div className={"blog-card-date-triangle"}></div>
                    <p className={"blog-month"}>{month}</p>
                    <p className={"blog-year"}>{year}</p>
                </div>
            </div>
            <div className={"blog-card-text"}>
                <h5 className={"secondary-color"}>{title}</h5>
                <p>{content}</p>
                <p>
                    <Link to={path} className={"primary-color"}><span>READ MORE<i className={"primary-color material-icons right"}>arrow_right_alt</i></span></Link>
                </p>
            </div>
        </div>
    )
}

export default BlogCard;