const base_path = "/new_images/ppf/8__Maruti_Suzuki_Brezza/";

const file_names: string[] = [
    "IMG_4460",
    "IMG_4468",
    "IMG_4479",
    "IMG_4492",
    "IMG_4670",
    "IMG_4674",
    "IMG_4678",
    "IMG_4682",
    "IMG_4683",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_4460": {w: 4032, h: 3024},
    "IMG_4468": {w: 3520, h: 1980},
    "IMG_4479": {w: 4032, h: 3024},
    "IMG_4492": {w: 3520, h: 1980},
    "IMG_4670": {w: 4032, h: 3024},
    "IMG_4674": {w: 1920, h: 1440},
    "IMG_4678": {w: 1920, h: 1440},
    "IMG_4682": {w: 1920, h: 1440},
    "IMG_4683": {w: 1920, h: 1440},
}

export const PPF_Maruti_Suzuki_Brezza = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Maruti Suzuki Brezza",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});