import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Globals";
import "./PremiumService.scss";

const PremiumService = () => {
  const aboutusJSX = useMemo(() => {
    return (
      <div>
        <h2 className={"white t-align-center center"}>
          WE ARE MULTI BRAND CAR SERVICE CENTRE & HAVE ACCREDITATION FOR PREMIUM
          QUALITY NANO TECHNOLOGY (9H) CERAMIC COATING, PPF, WRAPPING
        </h2>
        <p className={"white t-align-center center"}>
          Multibrand Car Interior Detailing, Exterior Detailing, Accessories,
          Insurance Renewal, Used Car Sell/Buy/Exchange.
        </p>
      </div>
    );
  }, []);

  const servicesJSX = useMemo(() => {
    return (
      <div>
        <h2 className={"white t-align-center center"}>
          WE DEAL WITH ALL BRANDS & PROVIDE GENUINE CAR SPARE PARTS FOR ALL THE
          BRANDS.
        </h2>
        <p className={"white t-align-center center"}>
          Our Multi-brand Car Services like: Audi, BMW, Chevrolet, Citroen,
          Datsun, Fiat, Ford, Honda, Hyundai, Isuzu, Jaguar, Jeep, Kia,
          Landrover, Lexus, Mahindra, Maruti Suzuki, Mercedes Benz, MG, Mini,
          Mitsubishi, Nexa, Nissan, Opel, Renault, Skoda, Tata, Toyota,
          Volkswagen, Volvo
        </p>
      </div>
    );
  }, []);

  const usualJsx = useMemo(() => {
    return (
      <div>
        {/* <h2 className={"white t-align-center center"}>
          PREMIUM CAR SERVICE MATCHED WITH GREAT WORKMANSHIP
        </h2> */}
        <h2 className={"white t-align-center center"}>
          Premium Ceramic Coating and PPF services with a warranty, ensuring
          lasting protection and enhanced aesthetics for your vehicle. Quality
          guaranteed.{" "}
        </h2>
        {/* <p className={"white t-align-center center"}>
          Best car care station for multi brand cars, with genuine car
          components for all the brands.
        </p> */}
        <p className={"white t-align-center center"}>
          Bangalore's leading Ceramic Coating and Paint Protection Film service
          provider, delivering top-tier quality and customer satisfaction.
        </p>
      </div>
    );
  }, []);

  // const [isAboutUsPage, setIsAboutUsPage] = useState(false);
  // const [isOther, setIsOther] = useState(false);

  const [contentJsx, setContentJsx] = useState<React.ReactNode>(null);

  const location = useLocation();

  useEffect(() => {
    // (location.pathname===ROUTES["ABOUT"])? setIsAboutUsPage(true) : setIsAboutUsPage(false);
    if (location.pathname === ROUTES["ABOUT"]) {
      setContentJsx(aboutusJSX);
    } else if (location.pathname === ROUTES["SERVICES"]) {
      setContentJsx(servicesJSX);
    } else {
      setContentJsx(usualJsx);
    }
  }, [location, aboutusJSX, usualJsx, servicesJSX]);

  return (
    <div className={"premium-service"}>
      <div className={"premium-service-overlay"}>
        {contentJsx}
        <div className={"t-align-center"}>
          <Link to={ROUTES["CONTACT"]} className={"button get-started-btn"}>
            <span>GET STARTED</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PremiumService;
