import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { postQuoteRequestFormAsync } from "../../../Common/Api/Form/QuoteRequest/QuoteRequest";
import { IQuoteRequestForm, IQuoteRequestFormErrorResponse, IQuoteRequestFormRequest } from "../../../Common/Api/Form/Types/Form/Form";

export const postQuoteRequestForm = createAsyncThunk
<{}, IQuoteRequestFormRequest, {rejectValue: IQuoteRequestFormErrorResponse}>(
    "quoteRequest_form/post", async (quoteRequestFormData, thunkAPI) => {
        const quoteRequestFormResponse = await postQuoteRequestFormAsync(quoteRequestFormData);

        //200: ok response
        if (quoteRequestFormResponse.responseStatus !== 200) {   
            let err_msg = "";
            if (await quoteRequestFormResponse.responseJson["detail"])
                err_msg = await quoteRequestFormResponse.responseJson["detail"]
            else if (await quoteRequestFormResponse.responseJson["message"])
                err_msg = await quoteRequestFormResponse.responseJson["message"]

            //400: bad request (probably validation error)
            if (quoteRequestFormResponse.responseStatus === 400) {
                let response = {
                    detail: {
                        validation_errors: {
                            ...quoteRequestFormResponse.responseJson
                        }
                    }
                }
                return thunkAPI.rejectWithValue((response) as IQuoteRequestFormErrorResponse)
            }
            let response = {
                error_description: err_msg,
            }
            return thunkAPI.rejectWithValue((response) as IQuoteRequestFormErrorResponse)
        }
        return {};
    }
)

export const postQuoteRequestFormBuilder = (builder: ActionReducerMapBuilder<IQuoteRequestForm>) => {
    builder.addCase(postQuoteRequestForm.pending, (state) => {
        return {
            ...state,
            request_states: {
                ...state.request_states,
                post: {
                    ...state.request_states.post,
                    pending: true,
                    fulfilled: false,
                    rejected: false,
                }
            },
        }
    })
    builder.addCase(postQuoteRequestForm.fulfilled, (state) => {
        return {
            ...state,
            request_states: {
                ...state.request_states,
                post: {
                    ...state.request_states.post,
                    pending: false,
                    fulfilled: true,
                    rejected: false,
                }
            },
        };
    })
    builder.addCase(postQuoteRequestForm.rejected, (state, action) => {
        console.log(action)
        return {
            ...state,
            detail: {
                ...state.detail,
                ...action.payload?.detail,
                validation_errors: {
                    ...state.detail.validation_errors,
                    ...action.payload?.detail?.validation_errors
                },
            },
            request_states: {
                ...state.request_states,
                post: {
                    ...state.request_states.post,
                    error_description: action.payload?.error_description,
                    pending: false,
                    fulfilled: false,
                    rejected: true,
                }
            },
        };
    })
}