import { useMemo } from "react";
import "./ProductTable.scss";

interface IFeatures {
    content: string[],
}
const Features = ({content}: IFeatures) => {
    const contentJSX = useMemo(() => {
        return content.map((c, i) => {
            return (
                <div className={"points"} key={i}>
                    <i className={"material-icons"}>keyboard_arrow_right</i>
                    <p>{c}</p>
                </div>
            )
        })
    }, [content])
    return (
        <>
            <div className={"features"}>
                <h5>Features</h5>
                <div className={"content"}>
                    {contentJSX}
                </div>
            </div>
        </>
    )
}

export interface ProductTableItemParams {
    img: string, alt?: string, imgWebp?: string, w?: string, h?: string,
    name: string,
    description: string,
    colorClass?: string,
    features?: IFeatures[],
}

const ProductTableItem = ({ img, alt, imgWebp, w, h, name, description, features }: ProductTableItemParams) => {
    const fJSX = useMemo(() => {
        if (features)
            return features.map((f, i)=>{
                return <Features {...f} key={i}/>
            })
        else return null;
    }, [features])
    return (
        <>
            <div className={"mb-col-12 t-col-12 d-col-12"}>
                <div className={"product-img-wrapper"}>
                    <picture>
                        {imgWebp && <source type={"image/webp"} srcSet={imgWebp}/>}
                        <img
                            src={img} alt={alt?alt:"Product"}
                            width={w?w:undefined} height={h?h:undefined}    
                        />
                    </picture>
                </div>
            </div>
            <div className={"mb-col-12 t-col-12 d-col-12 product-text-wrapper"}>
                <h5 className={"secondary-color t-align-center"}>{name}</h5>
                <p>{description}</p>
                {fJSX}
            </div>
        </>
    )
}

export interface ProductTableParams {
    title: string,
    threeProductsPerRow?: boolean,
    products: ProductTableItemParams[],
}

export const ProductTable = ({ title, products, threeProductsPerRow }: ProductTableParams) => {

    const _products = products.map( (product, index) => {
        if (threeProductsPerRow) {
            return (
                <div className={"mb-col-12 t-col-6 d-col-4"}  key={title + product.img + index }>
                    <div className={"product-table-card-panel-wrapper"}>
                        <div className={"col-wrapper-12 card-panel shadow-1 " + product.colorClass}>
                            <ProductTableItem {...product} key={title + index}/>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className={"mb-col-12 t-col-12 d-col-6"}  key={title + product.img + index }>
                <div className={"product-table-card-panel-wrapper"}>
                    <div className={"col-wrapper-12 card-panel shadow-1 " + product.colorClass}>
                        <ProductTableItem {...product} key={title + index}/>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className={"product-table"}>
            <h4 className={"primary-color t-align-center"}>{title}</h4>
            <div className={"col-wrapper-12"}>
                {_products}
            </div>
        </div>
    );
}