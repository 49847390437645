import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { IPopupForm } from "../Common/Api/Form/Types/Form/Form";
import { ROUTES } from "../Common/Globals";
import { hideAllPagePopupForm } from "../Store/Slices/App/AppSlice";

const Home = lazy( () => import('./Home/Home'));
const AboutUs = lazy( () => import('./AboutUs/AboutUs'));
const Services = lazy( () => import('./Services/Services'));
const Contact = lazy( () => import('./Contact/Contact'));
const CeramicCoating = lazy( () => import('./CeramicCoating/CeramicCoating'));
const CarDetailing = lazy( () => import('./CarDetailing/CarDetailing'));
const CarWrapping = lazy( () => import('./CarWrapping/CarWrapping'));
const PPF = lazy( () => import('./PPF/PPF'));
const SPF = lazy( () => import('./SPF/SPF'));
const BodyShop =  lazy(()=>  import( "./BodyShop/BodyShop"));
const Faq = lazy( () => import('./Faq/Faq'));
const Pricing = lazy( () => import('./Pricing/Pricing'));
const BlogList = lazy( () => import('./BlogList/BlogList'));
const SingleBlog = lazy( () => import('./SingleBlog/SingleBlog'));
const NotFound404 = lazy( () => import('./Error/NotFound404/NotFound404'));

const dontShowPopupOnTheseRoutes = [
    ROUTES["FAQ"], ROUTES["ABOUT"], ROUTES["CONTACT"]
];

const PagesRoutes = () => {
    const dispatch = useDispatch();
    const {pathname, hash} = useLocation();

    const popup = useSelector( (state: {popupForm: IPopupForm}) => state.popupForm);

    useEffect(() => {
        if (popup.request_states.post.fulfilled) {
            /** DO NOT SHOW THE POPUP FORM AGAIN ONCE FILLED */
            return;
        }

        if (dontShowPopupOnTheseRoutes.includes(pathname))
            dispatch(hideAllPagePopupForm());
        // else
        //     dispatch(unhideAllPagePopupForm());
    }, [pathname, dispatch, popup.request_states.post.fulfilled]);

    useEffect(() => {
        if (hash === "") {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash])

    return (
        <Switch>
            <Route exact path={ROUTES["HOMEPAGE"]} component={Home} />
            {/* <Route exact path={ROUTES["ABOUT"]} component={AboutUs} /> */}
            {/* <Route exact path={ROUTES["SERVICES"]} component={Services} /> */}
            <Route exact path={ROUTES["CONTACT"]} component={Contact} />

            <Route exact path={ROUTES["BODY_SHOP"]} component={BodyShop} />
            <Route exact path={ROUTES["CERAMIC_COATING"]} component={CeramicCoating} />
            <Route exact path={ROUTES["CAR_DETAILING"]} component={CarDetailing} />
            <Route exact path={ROUTES["CAR_WRAPPING"]} component={CarWrapping} />
            <Route exact path={ROUTES["PPF"]} component={PPF} />
            <Route exact path={ROUTES["SPF"]} component={SPF} />
            <Route exact path={ROUTES["FAQ"]} component={Faq} />
            {/* <Route exact path={ROUTES["PRICING"]} component={Pricing} /> */}
            <Route exact path={ROUTES["BLOG"]} component={BlogList} />
            <Route exact path={ROUTES["BLOG_WITH_PARAMS"]} component={SingleBlog} />
            <Route exact path={ROUTES["NOT_FOUND"]} component={NotFound404} />
            <Route component={NotFound404} />
        </Switch>
    )
}

export default PagesRoutes;