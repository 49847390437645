const SignoutLoading = () => {
    return (
        <>
            <div>
                <h5>Signing Out... Please Wait</h5>
            </div>
        </>
    )
}

export default SignoutLoading;