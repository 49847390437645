const SigninLoading = () => {
    return (
        <>
            <div>
                <h5>Signing in... Please Wait</h5>
            </div>
        </>
    )
}

export default SigninLoading;