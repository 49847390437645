import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { patchBlogAsyc } from "../../../Common/Api/BlogApi/BlogApi";
import { IBlog, IBlogData, IBlogErrorResponse, IBlogPatchRequest } from "../../../Common/Api/BlogApi/Interfaces/Blog";


export const patchBlog = createAsyncThunk
<IBlogData, IBlogPatchRequest, {rejectValue: IBlogErrorResponse}>(
    "blog/patch", async (blogData, thunkAPI) => {
        const {url, ...data} = blogData;
        const blogResponse = await patchBlogAsyc(data, url);

        if (blogResponse.responseStatus === 200) {
            return {
                ...blogResponse.responseJson
            } as IBlogData;
        }
        // else if error in input
        else if (blogResponse.responseStatus === 400) {
            let err_msg = "";
            if (blogResponse.responseJson["detail"])
                err_msg = blogResponse.responseJson["detail"]
            else if (blogResponse.responseJson["message"])
                err_msg = blogResponse.responseJson["message"]
            let response = {
                validation_errors: {
                    ...blogResponse.responseJson,
                },
                error_description: err_msg,
            }
            return thunkAPI.rejectWithValue((response) as IBlogErrorResponse)
        }
        else {
            let err_msg = "";
            if (blogResponse.responseJson["detail"])
                err_msg = blogResponse.responseJson["detail"]
            else if (blogResponse.responseJson["message"])
                err_msg = blogResponse.responseJson["message"]
            let response = {
                error_description: err_msg,
            }
            return thunkAPI.rejectWithValue((response) as IBlogErrorResponse)
        }
    }
)

export const patchBlogBuilder = (builder: ActionReducerMapBuilder<IBlog>) => {
    builder.addCase(patchBlog.pending, (state) => {
        return {
            ...state,
            request_states: {
                ...state.request_states,
                patch: {
                    ...state.request_states.patch,
                    pending: true, fulfilled: false, rejected: false,
                }
            },
        }
    })
    builder.addCase(patchBlog.fulfilled, (state, action) => {
        return {
            ...state,
            ...action.payload,
            request_states: {
                ...state.request_states,
                patch: {
                    ...state.request_states.patch,
                    pending: false, fulfilled: true, rejected: false,
                }
            },
        };
    })
    builder.addCase(patchBlog.rejected, (state, action) => {
        return {
            ...state,
            validation_errors: {
                ...state.validation_errors, ...action.payload?.validation_errors
            },
            request_states: {
                ...state.request_states,
                patch: {
                    ...state.request_states.patch,
                    pending: false, fulfilled: false, rejected: true,
                    error_description: action.payload?.error_description,
                }
            },
        };
    })
}