import HyundaiCreta1Webp from "./Images/1.webp"
import HyundaiCreta2Webp from "./Images/2.webp"
import HyundaiCreta3Webp from "./Images/3.webp"
import HyundaiCreta4Webp from "./Images/4.webp"
import HyundaiCreta5Webp from "./Images/5.webp"
import HyundaiCreta6Webp from "./Images/6.webp"

import HyundaiCreta1Jpg from "./Images/1.jpg"
import HyundaiCreta2Jpg from "./Images/2.jpg"
import HyundaiCreta3Jpg from "./Images/3.jpg"
import HyundaiCreta4Jpg from "./Images/4.jpg"
import HyundaiCreta5Jpg from "./Images/5.jpg"
import HyundaiCreta6Jpg from "./Images/6.jpg"

export const HyundaiCreta1 = {jpg: HyundaiCreta1Jpg, webp: HyundaiCreta1Webp, alt: "Hyundai Creta", w: "4032", h: "3024"}
export const HyundaiCreta2 = {jpg: HyundaiCreta2Jpg, webp: HyundaiCreta2Webp, alt: "Hyundai Creta", w: "4032", h: "3024"}
export const HyundaiCreta3 = {jpg: HyundaiCreta3Jpg, webp: HyundaiCreta3Webp, alt: "Hyundai Creta", w: "3520", h: "1980"}
export const HyundaiCreta4 = {jpg: HyundaiCreta4Jpg, webp: HyundaiCreta4Webp, alt: "Hyundai Creta", w: "3024", h: "4032"}
export const HyundaiCreta5 = {jpg: HyundaiCreta5Jpg, webp: HyundaiCreta5Webp, alt: "Hyundai Creta", w: "4032", h: "3024"}
export const HyundaiCreta6 = {jpg: HyundaiCreta6Jpg, webp: HyundaiCreta6Webp, alt: "Hyundai Creta", w: "4032", h: "3024"}