const GetUserProfileLoading = () => {
    return (
        <>
            <div>
                <h5>Getting User Profile... Please Wait</h5>
            </div>
        </>
    )
}

export default GetUserProfileLoading;