const base_path = "/new_images/ppf/9__Bikes/";

const file_names: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "1": {w: 1080, h: 810},
    "2": {w: 1024, h: 587},
    "3": {w: 1019, h: 355},
    "4": {w: 768, h: 1024},
    "5": {w: 768, h: 1024},
    "6": {w: 768, h: 1024},
    "7": {w: 1080, h: 717},
    "8": {w: 499, h: 1080},
    "9": {w: 810, h: 1080},
    "10": {w: 499, h: 1080},
    "11": {w: 1080, h: 717},
    "12": {w: 499, h: 1080},
}

export const Bikes = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Bikes",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});