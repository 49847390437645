import LogoMultiCarRectPng from './logo_multicar_rect.png';
import LogoMultiCarRectBlackPng from './logo_multicar_rect_black.png';

import LogoMultiCarRectWebp from './logo_multicar_rect.webp';
import LogoMultiCarRectBlackWebp from './logo_multicar_rect_black.webp';

const LogoMultiCarRect = {png: LogoMultiCarRectPng, webp: LogoMultiCarRectWebp, alt: "My Car Multi Brand - Logo", w: "586", h: "174"}
const LogoMultiCarRectBlack = {png: LogoMultiCarRectBlackPng, webp: LogoMultiCarRectBlackWebp, alt: "My Car Multi Brand - Logo", w: "703", h: "204"}

export * as BrandLogoImages from './BrandLogo/BrandLogoImages';
export * as BrandLogoImagesTextArray from './BrandLogo/BrandLogoImagesTextArray';
export {
    LogoMultiCarRect, LogoMultiCarRectBlack
}