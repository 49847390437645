import SPF_KiaSeltosGTLine_1Jpg from "./Images/1.jpg";
import SPF_KiaSeltosGTLine_10Jpg from "./Images/10.jpg";
import SPF_KiaSeltosGTLine_13Jpg from "./Images/13.jpg";
import SPF_KiaSeltosGTLine_15Jpg from "./Images/15.jpg";
import SPF_KiaSeltosGTLine_16Jpg from "./Images/16.jpg";
import SPF_KiaSeltosGTLine_4Jpg from "./Images/4.jpg";

import SPF_KiaSeltosGTLine_1Webp from "./Images/1.webp";
import SPF_KiaSeltosGTLine_10Webp from "./Images/10.webp";
import SPF_KiaSeltosGTLine_13Webp from "./Images/13.webp";
import SPF_KiaSeltosGTLine_15Webp from "./Images/15.webp";
import SPF_KiaSeltosGTLine_16Webp from "./Images/16.webp";
import SPF_KiaSeltosGTLine_4Webp from "./Images/4.webp";

export const SPF_KiaSeltosGTLine_1 = {jpg: SPF_KiaSeltosGTLine_1Jpg, webp: SPF_KiaSeltosGTLine_1Webp, alt: "Kia Seltos GT Line - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_KiaSeltosGTLine_10 = {jpg: SPF_KiaSeltosGTLine_10Jpg, webp: SPF_KiaSeltosGTLine_10Webp, alt: "Kia Seltos GT Line - Sun Protection Film", w: "4032", h: "3024"};
export const SPF_KiaSeltosGTLine_13 = {jpg: SPF_KiaSeltosGTLine_13Jpg, webp: SPF_KiaSeltosGTLine_13Webp, alt: "Kia Seltos GT Line - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_KiaSeltosGTLine_15 = {jpg: SPF_KiaSeltosGTLine_15Jpg, webp: SPF_KiaSeltosGTLine_15Webp, alt: "Kia Seltos GT Line - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_KiaSeltosGTLine_16 = {jpg: SPF_KiaSeltosGTLine_16Jpg, webp: SPF_KiaSeltosGTLine_16Webp, alt: "Kia Seltos GT Line - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_KiaSeltosGTLine_4 = {jpg: SPF_KiaSeltosGTLine_4Jpg, webp: SPF_KiaSeltosGTLine_4Webp, alt: "Kia Seltos GT Line - Sun Protection Film", w: "3024", h: "4032"};