import Testimonial1WagonarJpg from "./Images/1Wagonar.jpg"
import Testimonial2TataNexonXzaPlusJpg from "./Images/2TataNexonXzaPlus.jpg"
import Testimonial3TataNexonXzPlusJpg from "./Images/3TataNexonXzPlus.jpg"
import Testimonial4BMW5SeriesJpg from "./Images/4BMW5Series.jpg"
import Testimonial5MarutiSuzukiCiazJpg from "./Images/5MarutiSuzukiCiaz.jpg"
import Testimonial6AudiQ3Jpg from "./Images/6AudiQ3.jpg"
import Testimonial7AudiA4Jpg from "./Images/7AudiA4.jpg"
import Testimonial8MarutiSuzukiCiazJpg from "./Images/8MarutiSuzukiCiaz.jpg"
import Testimonial9ChevroletCruzeJpg from "./Images/9ChevroletCruze.jpg"

import Testimonial1WagonarWebp from "./Images/1Wagonar.jpg"
import Testimonial2TataNexonXzaPlusWebp from "./Images/2TataNexonXzaPlus.jpg"
import Testimonial3TataNexonXzPlusWebp from "./Images/3TataNexonXzPlus.jpg"
import Testimonial4BMW5SeriesWebp from "./Images/4BMW5Series.jpg"
import Testimonial5MarutiSuzukiCiazWebp from "./Images/5MarutiSuzukiCiaz.jpg"
import Testimonial6AudiQ3Webp from "./Images/6AudiQ3.jpg"
import Testimonial7AudiA4Webp from "./Images/7AudiA4.jpg"
import Testimonial8MarutiSuzukiCiazWebp from "./Images/8MarutiSuzukiCiaz.jpg"
import Testimonial9ChevroletCruzeWebp from "./Images/9ChevroletCruze.jpg"

const Testimonial1Wagonar = {jpg: Testimonial1WagonarJpg, webp: Testimonial1WagonarWebp, alt: "Wagon R - Testimonial", w: "960", h: "1280"};
const Testimonial2TataNexonXzaPlus = {jpg: Testimonial2TataNexonXzaPlusJpg, webp: Testimonial2TataNexonXzaPlusWebp, alt: "Tata Nexon XZA+ - Testimonial", w: "960", h: "1280"};
const Testimonial3TataNexonXzPlus = {jpg: Testimonial3TataNexonXzPlusJpg, webp: Testimonial3TataNexonXzPlusWebp, alt: "Tata Nexon XZ+ - Testimonial", w: "960", h: "1280"};
const Testimonial4BMW5Series = {jpg: Testimonial4BMW5SeriesJpg, webp: Testimonial4BMW5SeriesWebp, alt: "BMW 5 Series - Testimonial", w: "960", h: "1280"};
const Testimonial5MarutiSuzukiCiaz = {jpg: Testimonial5MarutiSuzukiCiazJpg, webp: Testimonial5MarutiSuzukiCiazWebp, alt: "Maruti Suzuki Ciaz - Testimonial", w: "960", h: "1280"};
const Testimonial6AudiQ3 = {jpg: Testimonial6AudiQ3Jpg, webp: Testimonial6AudiQ3Webp, alt: "Audi Q3 - Testimonial", w: "960", h: "1280"};
const Testimonial7AudiA4 = {jpg: Testimonial7AudiA4Jpg, webp: Testimonial7AudiA4Webp, alt: "Audi A4 - Testimonial", w: "1280", h: "960"};
const Testimonial8MarutiSuzukiCiaz = {jpg: Testimonial8MarutiSuzukiCiazJpg, webp: Testimonial8MarutiSuzukiCiazWebp, alt: "Maruti Suzuki Ciaz - Testimonial", w: "960", h: "1280"};
const Testimonial9ChevroletCruze = {jpg: Testimonial9ChevroletCruzeJpg, webp: Testimonial9ChevroletCruzeWebp, alt: "Chevrolet Cruze - Testimonial", w: "960", h: "1280"};

export {
    Testimonial1Wagonar,
    Testimonial2TataNexonXzaPlus,
    Testimonial3TataNexonXzPlus,
    Testimonial4BMW5Series,
    Testimonial5MarutiSuzukiCiaz,
    Testimonial6AudiQ3,
    Testimonial7AudiA4,
    Testimonial8MarutiSuzukiCiaz,
    Testimonial9ChevroletCruze,
};