const SignupLoading = () => {
    return (
        <>
            <div>
                <h5>Signing Up... Please Wait</h5>
            </div>
        </>
    )
}

export default SignupLoading;