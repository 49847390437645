const base_path = "/new_images/car_wrapping/gallery/";

const file_names: string[] = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
 
];

const file_names_sizes: { [key: string]: { w: number; h: number } } = {
  "1": { w: 4032, h: 3024 },
  "2": { w: 1280, h: 960 },
  "3": { w: 1080, h: 608 },
  "4": { w: 1080, h: 810 },
  "5": { w: 1080, h: 810 },
  "6": { w: 1080, h: 810 },
  "7": { w: 1080, h: 810 },
  "8": { w: 1080, h: 608 },
  "9": { w: 1080, h: 810 },
  "10": { w: 1080, h: 810 },
  "11": { w: 810, h: 1080 },
  "12": { w: 810, h: 1080 },
  "13": { w: 810, h: 1080 },
  "14": { w: 810, h: 1080 },
  "15": { w: 1080, h: 810 },
  "16": { w: 608, h: 1080 },
  "17": { w: 810, h: 1080 },
  "18": { w: 810, h: 1080 },
  "19": { w: 1080, h: 810 },
  "20": { w: 1080, h: 810 },
  "21": { w: 1080, h: 810 },
  "22": { w: 1080, h: 810 },
  "23": { w: 1080, h: 810 },

};

export const CarWarpping = file_names.map((f) => {
  const size = file_names_sizes[f];

  return {
    jpg: base_path + f + ".jpeg",
    webp: base_path + f + ".webp",
    alt: "Sun Protection Film",
    w: size.w.toString(),
    h: size.h.toString(),
  };
});
