import ToyotaInnovaCrysta1Webp from "./Images/1.webp"
import ToyotaInnovaCrysta2Webp from "./Images/2.webp"
import ToyotaInnovaCrysta3Webp from "./Images/3.webp"
import ToyotaInnovaCrysta4Webp from "./Images/4.webp"
import ToyotaInnovaCrysta5Webp from "./Images/5.webp"
import ToyotaInnovaCrysta6Webp from "./Images/6.webp"

import ToyotaInnovaCrysta1Jpg from "./Images/1.jpg"
import ToyotaInnovaCrysta2Jpg from "./Images/2.jpg"
import ToyotaInnovaCrysta3Jpg from "./Images/3.jpg"
import ToyotaInnovaCrysta4Jpg from "./Images/4.jpg"
import ToyotaInnovaCrysta5Jpg from "./Images/5.jpg"
import ToyotaInnovaCrysta6Jpg from "./Images/6.jpg"

export const ToyotaInnovaCrysta1 = {jpg: ToyotaInnovaCrysta1Jpg, webp: ToyotaInnovaCrysta1Webp, alt: "Toyota Innova Crysta", w: "3024", h: "4032"}
export const ToyotaInnovaCrysta2 = {jpg: ToyotaInnovaCrysta2Jpg, webp: ToyotaInnovaCrysta2Webp, alt: "Toyota Innova Crysta", w: "3520", h: "1980"}
export const ToyotaInnovaCrysta3 = {jpg: ToyotaInnovaCrysta3Jpg, webp: ToyotaInnovaCrysta3Webp, alt: "Toyota Innova Crysta", w: "3520", h: "1980"}
export const ToyotaInnovaCrysta4 = {jpg: ToyotaInnovaCrysta4Jpg, webp: ToyotaInnovaCrysta4Webp, alt: "Toyota Innova Crysta", w: "4032", h: "3024"}
export const ToyotaInnovaCrysta5 = {jpg: ToyotaInnovaCrysta5Jpg, webp: ToyotaInnovaCrysta5Webp, alt: "Toyota Innova Crysta", w: "4032", h: "3024"}
export const ToyotaInnovaCrysta6 = {jpg: ToyotaInnovaCrysta6Jpg, webp: ToyotaInnovaCrysta6Webp, alt: "Toyota Innova Crysta", w: "4032", h: "3024"}