import "./BrandLogo.scss";

interface BrandLogoParams {
    img: string, alt?: string, imgWebp?: string, w?: string, h?: string,
    title: string,
}

const BrandLogo = ({img, title, alt, imgWebp, w, h}: BrandLogoParams) => {
    return (
        <div className={"brand-logo"}>
            <a href={"#!"} className={"brand-logo-link"}>
                <picture>
                    {imgWebp && <source type={"image/webp"} srcSet={imgWebp}/>}
                    <img
                        src={img} alt={alt?alt:"brand-logo"} className={"brand-logo-img"}
                        width={w?w:undefined} height={h?h:undefined}    
                    />
                </picture>
                <span>{title}</span>
            </a>
        </div>
    );
}

export default BrandLogo;