import Mercedes_Benz_ML_350_CDI_1_Jpg from "./1.jpeg";
import Mercedes_Benz_ML_350_CDI_10_Jpg from "./10.jpeg";
import Mercedes_Benz_ML_350_CDI_11_Jpg from "./11.jpeg";
import Mercedes_Benz_ML_350_CDI_12_Jpg from "./12.jpeg";
import Mercedes_Benz_ML_350_CDI_13_Jpg from "./13.jpeg";
import Mercedes_Benz_ML_350_CDI_2_Jpg from "./2.jpeg";
import Mercedes_Benz_ML_350_CDI_3_Jpg from "./3.jpeg";
import Mercedes_Benz_ML_350_CDI_4_Jpg from "./4.jpeg";
import Mercedes_Benz_ML_350_CDI_5_Jpg from "./5.jpeg";
import Mercedes_Benz_ML_350_CDI_6_Jpg from "./6.jpeg";
import Mercedes_Benz_ML_350_CDI_7_Jpg from "./7.jpeg";
import Mercedes_Benz_ML_350_CDI_8_Jpg from "./8.jpeg";
import Mercedes_Benz_ML_350_CDI_9_Jpg from "./9.jpeg";

import Mercedes_Benz_ML_350_CDI_1_Webp from "./1.webp";
import Mercedes_Benz_ML_350_CDI_10_Webp from "./10.webp";
import Mercedes_Benz_ML_350_CDI_11_Webp from "./11.webp";
import Mercedes_Benz_ML_350_CDI_12_Webp from "./12.webp";
import Mercedes_Benz_ML_350_CDI_13_Webp from "./13.webp";
import Mercedes_Benz_ML_350_CDI_2_Webp from "./2.webp";
import Mercedes_Benz_ML_350_CDI_3_Webp from "./3.webp";
import Mercedes_Benz_ML_350_CDI_4_Webp from "./4.webp";
import Mercedes_Benz_ML_350_CDI_5_Webp from "./5.webp";
import Mercedes_Benz_ML_350_CDI_6_Webp from "./6.webp";
import Mercedes_Benz_ML_350_CDI_7_Webp from "./7.webp";
import Mercedes_Benz_ML_350_CDI_8_Webp from "./8.webp";
import Mercedes_Benz_ML_350_CDI_9_Webp from "./9.webp";

export const Mercedes_Benz_ML_350_CDI_1 = {jpg: Mercedes_Benz_ML_350_CDI_1_Jpg, webp: Mercedes_Benz_ML_350_CDI_1_Webp, alt: "Mercedes Benz ML 350 CDI", w: "768", h: "1024"};
export const Mercedes_Benz_ML_350_CDI_2 = {jpg: Mercedes_Benz_ML_350_CDI_2_Jpg, webp: Mercedes_Benz_ML_350_CDI_2_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_3 = {jpg: Mercedes_Benz_ML_350_CDI_3_Jpg, webp: Mercedes_Benz_ML_350_CDI_3_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_4 = {jpg: Mercedes_Benz_ML_350_CDI_4_Jpg, webp: Mercedes_Benz_ML_350_CDI_4_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_5 = {jpg: Mercedes_Benz_ML_350_CDI_5_Jpg, webp: Mercedes_Benz_ML_350_CDI_5_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_6 = {jpg: Mercedes_Benz_ML_350_CDI_6_Jpg, webp: Mercedes_Benz_ML_350_CDI_6_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_7 = {jpg: Mercedes_Benz_ML_350_CDI_7_Jpg, webp: Mercedes_Benz_ML_350_CDI_7_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_8 = {jpg: Mercedes_Benz_ML_350_CDI_8_Jpg, webp: Mercedes_Benz_ML_350_CDI_8_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_9 = {jpg: Mercedes_Benz_ML_350_CDI_9_Jpg, webp: Mercedes_Benz_ML_350_CDI_9_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_10 = {jpg: Mercedes_Benz_ML_350_CDI_10_Jpg, webp: Mercedes_Benz_ML_350_CDI_10_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_11 = {jpg: Mercedes_Benz_ML_350_CDI_11_Jpg, webp: Mercedes_Benz_ML_350_CDI_11_Webp, alt: "Mercedes Benz ML 350 CDI", w: "810", h: "1080"};
export const Mercedes_Benz_ML_350_CDI_12 = {jpg: Mercedes_Benz_ML_350_CDI_12_Jpg, webp: Mercedes_Benz_ML_350_CDI_12_Webp, alt: "Mercedes Benz ML 350 CDI", w: "1080", h: "810"};
export const Mercedes_Benz_ML_350_CDI_13 = {jpg: Mercedes_Benz_ML_350_CDI_13_Jpg, webp: Mercedes_Benz_ML_350_CDI_13_Webp, alt: "Mercedes Benz ML 350 CDI", w: "810", h: "1080"};