import {
  Accessories,
  PPF,
  CarWash2,
  CarInsurance,
  CarInteriorDetailing,
  CarExteriorDetailing,
  CarService,
  BodyShop,
  CarWrapping,
  SunFilm,
  UsedCars,
  BMW_3_Series_330Li,
} from "../../../Assets/Assets";
import ServicesBox, { ServicesBoxParams } from "../ServicesBox/ServicesBox";

const Services = () => {
  const ServicesBoxParamsData: ServicesBoxParams[] = [
    {
      content: "",
      title: "MULTI BRAND SERVICE AND REPAIR",
      className: "",
      img: CarService.jpg,
      alt: CarService.alt,
      imgWebp: CarService.webp,
      w: CarService.w,
      h: CarService.h,
    },
    {
      content: "",
      title: "BODYSHOP",
      className: "",
      img: BodyShop.jpg,
      alt: BodyShop.alt,
      imgWebp: BodyShop.webp,
      w: BodyShop.w,
      h: BodyShop.h,
    },
    {
      content: "",
      title: "WASHING",
      className: "",
      img: CarWash2.jpg,
      alt: CarWash2.alt,
      imgWebp: CarWash2.webp,
      w: CarWash2.w,
      h: CarWash2.h,
    },
    {
      content: "",
      title: "INTERIOR DETAILING",
      className: "",
      img: CarInteriorDetailing.jpg,
      alt: CarInteriorDetailing.alt,
      imgWebp: CarInteriorDetailing.webp,
      w: CarInteriorDetailing.w,
      h: CarInteriorDetailing.h,
    },
    {
      content: "",
      title: "EXTERIOR DETAILING",
      className: "",
      img: CarExteriorDetailing.jpg,
      alt: CarExteriorDetailing.alt,
      imgWebp: CarExteriorDetailing.webp,
      w: CarExteriorDetailing.w,
      h: CarExteriorDetailing.h,
    },
    {
      content: "",
      title: "CERAMIC COATING",
      className: "",
      img: BMW_3_Series_330Li[19].jpg,
      alt: BMW_3_Series_330Li[19].alt,
      imgWebp: BMW_3_Series_330Li[19].webp,
      w: BMW_3_Series_330Li[19].w,
      h: BMW_3_Series_330Li[19].h,
    },
    {
      content: "",
      title: "PAINT PROTECTION FILM",
      className: "",
      img: PPF.jpg,
      alt: PPF.alt,
      imgWebp: PPF.webp,
      w: PPF.w,
      h: PPF.h,
    },
    {
      content: "",
      title: "WRAPPING",
      className: "",
      img: CarWrapping.jpg,
      alt: CarWrapping.alt,
      imgWebp: CarWrapping.webp,
      w: CarWrapping.w,
      h: CarWrapping.h,
    },
    {
      content: "",
      title: "SUN FILM",
      className: "",
      img: SunFilm.jpg,
      alt: SunFilm.alt,
      imgWebp: SunFilm.webp,
      w: SunFilm.w,
      h: SunFilm.h,
    },
    {
      content: "",
      title: "ACCESSORIES",
      className: "",
      img: Accessories.jpg,
      alt: Accessories.alt,
      imgWebp: Accessories.webp,
      w: Accessories.w,
      h: Accessories.h,
    },
    {
      content: "",
      title: "INSURANCE RENEWAL",
      className: "",
      img: CarInsurance.jpg,
      alt: CarInsurance.alt,
      imgWebp: CarInsurance.webp,
      w: CarInsurance.w,
      h: CarInsurance.h,
    },
    {
      content: "",
      title: "USED CAR SELL/BUY/EXCHANGE",
      className: "",
      img: UsedCars.jpg,
      alt: UsedCars.alt,
      imgWebp: UsedCars.webp,
      w: UsedCars.w,
      h: UsedCars.h,
    },
  ];

  return (
    <div className={"services"}>
      <h6 className={"services-title-1 primary-color t-align-center"}>
        OUR SERVICES
      </h6>
      {/* <h4 className={"services-title-2 secondary-color t-align-center"}>GREAT CAR SERVICES</h4> */}
      <h4 className={"services-title-2 secondary-color t-align-center"}>
        Enhance Your Vehicle's Shine with Premium Ceramic Coating and Paint
        Protection Film (PPF)
      </h4>

      <div className={"services-content"}>
        <div className={"col-wrapper-12"}>
          {/* <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[0]} />
          </div> */}
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[1]} />
          </div>
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[2]} />
          </div>
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[3]} />
          </div>
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[4]} />
          </div>
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[5]} />
          </div>
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[6]} />
          </div>
          {/* <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[7]} />
          </div> */}
          {/* <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[8]} />
          </div> */}
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[9]} />
          </div>
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[10]} />
          </div>
          <div className={"mb-col-12 t-col-6 d-col-4"}>
            <ServicesBox {...ServicesBoxParamsData[11]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
