const base_path = "/new_images/ceramic_coating/1__BMW_3_Series_330Li/";

const file_names: string[] = [
    "IMG_6324",
    "IMG_6330",
    "IMG_6332",
    "IMG_6338",
    "IMG_6339",
    "IMG_6356",
    "IMG_6370",
    "IMG_6426",
    "IMG_6484",
    "IMG_6494",
    "IMG_6520",
    "IMG_6533",
    "IMG_6556",
    "IMG_6908",
    "IMG_6933",
    "IMG_6940",
    "IMG_6947",
    "IMG_6982",
    "IMG_6985",
    "1",
    "IMG_7070",
    "IMG_7073",
    "IMG_7074",
    "IMG_7075",
    "IMG_7116",
    "IMG_7117",
    "IMG_7142",
    "IMG_7158",
    "IMG_7179",
    "IMG_7180",
    "IMG_7186",
    "IMG_7199", // preview
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_6324": {w: 1080, h: 810},
    "IMG_6330": {w: 810, h: 1080},
    "IMG_6332": {w: 1080, h: 810},
    "IMG_6338": {w: 810, h: 1080},
    "IMG_6339": {w: 810, h: 1080},
    "IMG_6356": {w: 810, h: 1080},
    "IMG_6370": {w: 608, h: 1080},
    "IMG_6426": {w: 810, h: 1080},
    "IMG_6484": {w: 608, h: 1080},
    "IMG_6494": {w: 608, h: 1080},
    "IMG_6520": {w: 608, h: 1080},
    "IMG_6533": {w: 810, h: 1080},
    "IMG_6556": {w: 608, h: 1080},
    "IMG_6908": {w: 608, h: 1080},
    "IMG_6933": {w: 608, h: 1080},
    "IMG_6940": {w: 810, h: 1080},
    "IMG_6947": {w: 810, h: 1080},
    "IMG_6982": {w: 608, h: 1080},
    "IMG_6985": {w: 608, h: 1080},
    "1": {w: 1080, h: 810},
    "IMG_7070": {w: 1080, h: 810},
    "IMG_7073": {w: 810, h: 1080},
    "IMG_7074": {w: 810, h: 1080},
    "IMG_7075": {w: 810, h: 1080},
    "IMG_7116": {w: 810, h: 1080},
    "IMG_7117": {w: 1080, h: 810},
    "IMG_7142": {w: 810, h: 1080},
    "IMG_7158": {w: 810, h: 1080},
    "IMG_7179": {w: 1080, h: 810},
    "IMG_7180": {w: 810, h: 1080},
    "IMG_7186": {w: 1080, h: 810},
    "IMG_7199": {w: 810, h: 1080},
}

export const BMW_3_Series_330Li = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "BMW 3 Series 330Li",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});