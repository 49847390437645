import "./Testimonial.scss";
import { useCallback, useEffect, useState } from "react";
import { PagedDiv } from "../../../Utils/js/Utils";
import {
    Testimonial1Wagonar, Testimonial2TataNexonXzaPlus,
    Testimonial3TataNexonXzPlus, Testimonial4BMW5Series, Testimonial5MarutiSuzukiCiaz,
    Testimonial6AudiQ3, Testimonial7AudiA4, Testimonial8MarutiSuzukiCiaz, Testimonial9ChevroletCruze,
 } from "../../../Assets/Assets";

interface TestimonialCardParams {
    title: string,
    content: string,
    img: string, alt?: string, imgWebp?: string, w?: string, h?: string,
}

const testimonialCardData: TestimonialCardParams[] = [
    {
        content: "I got My Maruti Wagon R Suspension repair, cleaning and washing done at Mycar Multibrand. They have awesome service, very spacious place & customer friendly. Got my car serviced for – oil change, suspension, washing & cleaning. I am very happy with the service and I would recommend my friends & colleagues about this place. This is an honest establishment and I received high-quality work.",
        img: Testimonial1Wagonar.jpg, alt: Testimonial1Wagonar.alt, imgWebp: Testimonial1Wagonar.webp, w: Testimonial1Wagonar.w, h: Testimonial1Wagonar.h,
        title: "Oil Change, Suspension, Washing and Cleaning"
    },
    {
        content: "I got my washing and cleaning done for my vehicle Tata Nexon, I had a great experience with my car wash, very professional staff & my car looks just amazing after the wash and cleaning. Would love to come again and again. Good luck team “My Car Multibrand“ keep up the good work.",
        img: Testimonial2TataNexonXzaPlus.jpg, alt: Testimonial2TataNexonXzaPlus.alt, imgWebp: Testimonial2TataNexonXzaPlus.webp, w: Testimonial2TataNexonXzaPlus.w, h: Testimonial2TataNexonXzaPlus.h,
        title: "Washing and Cleaning"
    },
    {
        content: "These guys have been so helpful, prices are so reasonable, and they are honest. Our car had a little electric problem and they fixed it right away and the price was pretty much what you’d expect. Washing & cleaning done for My vehicle, Happy with their service.",
        img: Testimonial3TataNexonXzPlus.jpg, alt: Testimonial3TataNexonXzPlus.alt, imgWebp: Testimonial3TataNexonXzPlus.webp, w: Testimonial3TataNexonXzPlus.w, h: Testimonial3TataNexonXzPlus.h,
        title: "Washing and Cleaning"
    },
    {
        content: "It has been so refreshing to finally find a car repair centre that I can fully trust! Got the service like oil filter, washing and cleaning done for my BMW car. The team is committed to making sure that the problems you are having with your vehicle are solved to your satisfaction. The team is committed to making sure that the problems you are having with your vehicle are solved to your satisfaction.",
        img: Testimonial4BMW5Series.jpg, alt: Testimonial4BMW5Series.alt, imgWebp: Testimonial4BMW5Series.webp, w: Testimonial4BMW5Series.w, h: Testimonial4BMW5Series.h,
        title: "Oil and Oil Filter Replacement and Washing and Cleaning"
    },
    {
        content: "Recently took my vehicle to Mycar Multibrand for the first time for Suspension Replacement, Timing Chain Replacement & Periodic Maintenance Service. The team is very friendly, did great job, did not charge me any extra fees above their quote and got it done on time. Very happy with the experience.",
        img: Testimonial5MarutiSuzukiCiaz.jpg, alt: Testimonial5MarutiSuzukiCiaz.alt, imgWebp: Testimonial5MarutiSuzukiCiaz.webp, w: Testimonial5MarutiSuzukiCiaz.w, h: Testimonial5MarutiSuzukiCiaz.h,
        title: "Suspension Replacement, Timing Chain Replacement, Periodic Maintenence Service"
    },
    {
        content: "They always get me in quickly, the prices are great, and the waiting area is really nice and comfy. Definitely the nicest car service centre I have ever been to. I love that through their careful diagnosis of my car they kept me informed at every turn.",
        img: Testimonial6AudiQ3.jpg, alt: Testimonial6AudiQ3.alt, imgWebp: Testimonial6AudiQ3.webp, w: Testimonial6AudiQ3.w, h: Testimonial6AudiQ3.h,
        title: "Washing and Cleaning"
    },
    {
        content: "Visited Mycar Multibrand car service centre for my Audi Shock absorber replacement.  Honest, efficient and prompt service always. Would highly highly recommend it. They give highly personalised service. They do not do any work which is not necessary. I am happy with the service.",
        img: Testimonial7AudiA4.jpg, alt: Testimonial7AudiA4.alt, imgWebp: Testimonial7AudiA4.webp, w: Testimonial7AudiA4.w, h: Testimonial7AudiA4.h,
        title: "Rear Shock Absorber Replacement "
    },
    {
        content: "I would recommend MyCar Multibrand car service centre to anyone without a doubt! These guys are very professional & reliable. Cleaning & Washing done for my vehicle. Best customer service and reasonable prices & I would recommend my family & friends this place.",
        img: Testimonial8MarutiSuzukiCiaz.jpg, alt: Testimonial8MarutiSuzukiCiaz.alt, imgWebp: Testimonial8MarutiSuzukiCiaz.webp, w: Testimonial8MarutiSuzukiCiaz.w, h: Testimonial8MarutiSuzukiCiaz.h,
        title: "Washing and Cleaning"
    },
    {
        content: "I got General Check-up, Key Remote Replacement and Cleaning & Washing done for My Vehicle- Chevrolet Cruze at Mycar Multibrand. I very much happy with service and customer support. The place is quite spacious to accommodate many vehicles. This is best place for high end cars service.",
        img: Testimonial9ChevroletCruze.jpg, alt: Testimonial9ChevroletCruze.alt, imgWebp: Testimonial9ChevroletCruze.webp, w: Testimonial9ChevroletCruze.w, h: Testimonial9ChevroletCruze.h,
        title: "General Checkup, Key Remote Replacement, Washing and Cleaning"
    },
];

const TestimonialCard = ({title, content, img, alt, imgWebp, w, h}: TestimonialCardParams) => {
    return (
        <div className={"testimonial-card card-panel center"}>
            <div className={"testimonial-card-img"}>
                <picture>
                    {imgWebp && <source type={"image/webp"} srcSet={imgWebp}/>}
                    <img
                        src={img} alt={alt?alt:"Testimonial"}
                        width={w?w:undefined} height={h?h:undefined}    
                    />
                </picture>
            </div>
            <div className={"testimonial-card-text"}>
                <h5 className={"secondary-color"}>{title}</h5>
                <p>{content}</p>
            </div>
        </div>
    )
}

interface TestimonialButtonParams {
    nextPage: () => void,
    prevPage: () => void,
}

const TestimonialButton = ({nextPage, prevPage}: TestimonialButtonParams) => {
    return (
        <>
            <div className={"testimonial-button-wrapper"}>
                <div className={"testimonial-button testimonial-button-1"}>
                    <button 
                        className={"button"} 
                        type={"button"} 
                        onClick={prevPage}
                    ><i className={"material-icons"}>chevron_left</i></button>
                </div>
                <div className={"testimonial-button testimonial-button-2"}>
                    <button
                        className={"button"} 
                        type={"button"} 
                        onClick={nextPage}
                    ><i className={"material-icons"}>chevron_right</i></button>
                </div>
            </div>
        </>
    )
}

const Testimonial = () => {

    const testimonials = testimonialCardData.map ( (data, index) => {
        return (
                <div key={data.title + index}>
                    <TestimonialCard
                        content={data.content}
                        img={data.img}
                        alt={data.alt}
                        imgWebp={data.imgWebp}
                        w={data.w}
                        h={data.h}
                        title={data.title}
                    />
                </div>
            );
    })

    const [counter, setCounter] = useState(0);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [resetTimerEffect, setResetTimerEffect] = useState<boolean>(false);
    const [pagedDivInstance, setPagedDivInstance] = useState<PagedDiv | null>(null);

    useEffect( () => {
        let _pagedDivInstance = new PagedDiv(".testimonial-paged", 0, false, true, true);
        setPagedDivInstance(_pagedDivInstance);

        _pagedDivInstance.addWindowResizeListener();

        return () => _pagedDivInstance.removeWindowResizeListener();
    }, []);

    //timer is used to slide carousel
    useEffect(() => {
        if (resetTimerEffect) {
            // will run useEffect twice... 
            //  first to reset the resetTimerEffect to initial and second to set the timer 
            // (because setResetTimerEffect is executed twice... one from callback and the other one from here)
            setResetTimerEffect(false);
            return;
        }
        const _timer = setInterval(() => {
            if (pagedDivInstance) {
                pagedDivInstance.slideLeft();
            }

            // counter should be updated on each loop
            setCounter(counter + 1);
        }, 10000);
        setTimer(_timer);
        return () => clearInterval(_timer);
    }, [counter, pagedDivInstance, resetTimerEffect]);

    // reset timer upon user interaction
    const resetTimer = useCallback( () => {
        if (timer) 
            clearInterval(timer);
        setResetTimerEffect(true);
    }, [timer]);

    const nextPage = useCallback( () => {
        if (pagedDivInstance) {
            pagedDivInstance.slideLeft();
        }
        resetTimer();
    }, [pagedDivInstance, resetTimer]);

    const prevPage = useCallback( () => {
        if (pagedDivInstance) {
            pagedDivInstance.slideRight();
        }
        resetTimer();
    }, [pagedDivInstance, resetTimer]);

    return (
        <div className={"testimonial-wrapper"}>
            <div className={"testimonial"}>
                <div>
                    <h5 className={"primary-color t-align-center"}>TESTIMONIAL</h5>
                </div>
                <TestimonialButton nextPage={nextPage} prevPage={prevPage}/>
                <div className={"paged testimonial-paged"}>
                    {testimonials}
                </div>
            </div>
        </div>
    )
}

export default Testimonial;