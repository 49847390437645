import SPF_MahindraThar_1Jpg from "./Images/1.jpg";
import SPF_MahindraThar_11Jpg from "./Images/11.jpg";
import SPF_MahindraThar_14Jpg from "./Images/14.jpg";
import SPF_MahindraThar_17Jpg from "./Images/17.jpg";
import SPF_MahindraThar_3Jpg from "./Images/3.jpg";
import SPF_MahindraThar_9Jpg from "./Images/9.jpg";

import SPF_MahindraThar_1Webp from "./Images/1.webp";
import SPF_MahindraThar_11Webp from "./Images/11.webp";
import SPF_MahindraThar_14Webp from "./Images/14.webp";
import SPF_MahindraThar_17Webp from "./Images/17.webp";
import SPF_MahindraThar_3Webp from "./Images/3.webp";
import SPF_MahindraThar_9Webp from "./Images/9.webp";

export const SPF_MahindraThar_1 = {jpg: SPF_MahindraThar_1Jpg, webp: SPF_MahindraThar_1Webp, alt: "Mahindra Thar - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_MahindraThar_11 = {jpg: SPF_MahindraThar_11Jpg, webp: SPF_MahindraThar_11Webp, alt: "Mahindra Thar - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_MahindraThar_14 = {jpg: SPF_MahindraThar_14Jpg, webp: SPF_MahindraThar_14Webp, alt: "Mahindra Thar - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_MahindraThar_17 = {jpg: SPF_MahindraThar_17Jpg, webp: SPF_MahindraThar_17Webp, alt: "Mahindra Thar - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_MahindraThar_3 = {jpg: SPF_MahindraThar_3Jpg, webp: SPF_MahindraThar_3Webp, alt: "Mahindra Thar - Sun Protection Film", w: "3024", h: "4032"};
export const SPF_MahindraThar_9 = {jpg: SPF_MahindraThar_9Jpg, webp: SPF_MahindraThar_9Webp, alt: "Mahindra Thar - Sun Protection Film", w: "1980", h: "3520"};