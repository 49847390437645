const base_path = "/new_images/ppf/1__MG_ZS_EV/";

const file_names: string[] = [
    "IMG_3687",
    "IMG_3688",
    "IMG_3725",
    "IMG_3753",
    "IMG_3767",
    "IMG_3787",
    "IMG_3793",
    "IMG_3958",
    "IMG_3962",
    "IMG_3971",
    "IMG_3973",
    "IMG_3998",
    "IMG_3999",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_3687": {w: 1080, h: 810},
    "IMG_3688": {w: 1242, h: 933},
    "IMG_3725": {w: 1080, h: 608},
    "IMG_3753": {w: 1080, h: 608},
    "IMG_3767": {w: 1080, h: 810},
    "IMG_3787": {w: 1080, h: 810},
    "IMG_3793": {w: 1080, h: 810},
    "IMG_3958": {w: 1080, h: 810},
    "IMG_3962": {w: 1080, h: 810},
    "IMG_3971": {w: 1080, h: 810},
    "IMG_3973": {w: 1080, h: 810},
    "IMG_3998": {w: 1080, h: 810},
    "IMG_3999": {w: 1080, h: 810},
}

export const MG_ZS_EV = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "MG ZS EV",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});