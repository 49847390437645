const base_path = "/new_images/ceramic_coating/15__Maruti_Suzuki_Ertiga/";

const file_names: string[] = [
    "IMG_4616",
    "IMG_4622", // preview
    "IMG_4623",
    "IMG_4629",
    "IMG_4630",
    "IMG_4631",
    "IMG_4632",
    "IMG_4635",
    "IMG_4636",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_4616": {w: 1920, h: 1440},
    "IMG_4622": {w: 1920, h: 1440},
    "IMG_4623": {w: 1920, h: 1440},
    "IMG_4629": {w: 1920, h: 1440},
    "IMG_4630": {w: 1920, h: 1440},
    "IMG_4631": {w: 1920, h: 1440},
    "IMG_4632": {w: 1920, h: 1440},
    "IMG_4635": {w: 1920, h: 1440},
    "IMG_4636": {w: 1920, h: 1440},
}

export const Maruti_Suzuki_Ertiga = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Maruti Suzuki Ertiga",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});