import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { hideAllPagePopupForm, IApp } from '../../../Store/Slices/App/AppSlice';
import { postPopupForm, reset } from '../../../Store/Slices/PopupForm/PopupFormSlice';
import { IPopupForm } from "../../Api/Form/Types/Form/Form";
import "./PopupForm.scss";

const PopupForm = () => {

    const app = useSelector((state: {app: IApp}) => state.app );
    const dispatch = useDispatch();

    const popup = useSelector( (state: {popupForm: IPopupForm}) => state.popupForm);

    const popupText = "FILL THE FORM TO AVAIL BEST OFFERS";
    // const popupSubText = <></>;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [emailSent, setEmailSent] = useState(false);
    const [emailRejected, setEmailRejected] = useState(false);
    const [emailPending, setEmailPending] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);

    const [emailValidationErrors, setEmailValidationErrors] = useState<string[]>([]);
    const [nameValidationErrors, setNameValidationErrors] = useState<string[]>([]);
    const [phoneValidationErrors, setPhoneValidationErrors] = useState<string[]>([]);

    useEffect(() => {
        return () => {
            /** DO NOT RESET AFTER FULFILLED!!! PLEASE RESET BEFORE SENDING A FORM, NOT AFTER FORM SUBMISSION. */
            dispatch(reset());
        };
    }, [dispatch])

    //check validation errors thrown by server
    useEffect(() => {
        setEmailValidationErrors(popup.detail.validation_errors.email);
    }, [popup.detail.validation_errors.email]);
    useEffect(() => {
        setNameValidationErrors(popup.detail.validation_errors.name);
    }, [popup.detail.validation_errors.name]);
    useEffect(() => {
        setPhoneValidationErrors(popup.detail.validation_errors.phone);
    }, [popup.detail.validation_errors.phone]);

    useEffect(() => {
        (popup.request_states.post.fulfilled)? setEmailSent(true):setEmailSent(false);
        (popup.request_states.post.rejected)? setEmailRejected(true):setEmailRejected(false);
        (popup.request_states.post.pending)? setEmailPending(true):setEmailPending(false);
        (popup.request_states.post.fulfilled || popup.request_states.post.pending || popup.request_states.post.rejected)? setShowInfoModal(true) : setShowInfoModal(false);
    }, [popup.request_states.post]);

    const toggleHidden = useCallback(() => {
        dispatch(hideAllPagePopupForm());
    }, [dispatch]);

    const handlePopupFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        dispatch(reset());
        dispatch(postPopupForm({
            email: email,
            name: name,
            phone: phone,
        }));
    }

    return (
        <>
            {
                showInfoModal && (
                    <div className={"popup-form-msg-div-wrapper"}>
                        <div className={"card-panel"}>
                            <div>
                                {emailRejected && <h6 className={"primary-color t-align-center"}>ERROR! We regret for the inconvenience. Please try to fill out the form again. Thank you.</h6>}
                                {emailPending && <h6 className={"secondary-color t-align-center"}>SENDING...</h6>}
                                {emailSent && <h6 className={"secondary-color t-align-center"}>We have been notified. Thank you for filling out the form!</h6>}                        
                            </div>
                            <div className={"t-align-right"}>
                                <button className={"button"} onClick={() => {setShowInfoModal(false); toggleHidden();}}>CLOSE</button>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={"popup-form-div-wrapper " + (app.allPagePopupForm.isHidden? "hide" : "")}>
            <div className={"popup-form-div card-panel"}>
                <div className={"popup-form-div-inner"}>
                    <div className={"popup-form-div-close"}>
                        <button className={"button"} onClick={toggleHidden}><i className={"material-icons"}>close</i></button>
                    </div>
                    <div className={"col-wrapper-12"}>
                        <div className={"mb-col-12 t-col-12 d-col-5"}>
                            <div className={"card-panel popup-form-info-card"}>
                                <h4 className={"blue-gray-900"}>{popupText}</h4>
                                {/* <h6 className={""}>{popupSubText}</h6> */}
                            </div>
                        </div>
                        {/* <div className={"mb-col-12 t-col-12 d-col-3"}></div> */}
                        <div className={"mb-col-12 t-col-12 d-col-7"}>
                            <div className={"card-panel"}>
                                <form className={"popup-form-form"} onSubmit={handlePopupFormSubmit}>
                                    {/* {
                                        (emailSent) &&
                                            <div className={"popup-form-notified-div"}>
                                                <h6 className={"secondary-color t-align-center"}>
                                                    We have been notified. Thank you for filling out the form!
                                                </h6>
                                            </div>
                                    } */}
                                    <div className={"popup-form-error-div"}>
                                        {
                                            nameValidationErrors.map((errorMsg, index) => {
                                                return (
                                                    <p 
                                                        key={"name_validation_errors"+index}
                                                        className="t-align-center red-a700"
                                                    >{errorMsg}</p>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="input-div icon popup-form-input">
                                        <input 
                                            id="popup-form-input-name"
                                            className="border-white " 
                                            type="text" 
                                            placeholder=" " 
                                            value={name}
                                            onChange={e => { e.preventDefault(); setName(e.target.value); }}
                                            // required
                                        />
                                        <label htmlFor="popup-form-input-name">Name</label>
                                        <i className="material-icons">perm_identity</i>
                                    </div>
                                    <div className={"popup-form-error-div"}>
                                        {
                                            emailValidationErrors.map((errorMsg, index) => {
                                                return (
                                                    <p 
                                                        key={"email_validation_errors"+index}
                                                        className="t-align-center red-a700"
                                                    >{errorMsg}</p>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="input-div icon popup-form-input">
                                        <input 
                                            id="popup-form-input-email"
                                            className="border-white " 
                                            type="text" 
                                            placeholder=" " 
                                            value={email}
                                            onChange={e => { e.preventDefault(); setEmail(e.target.value); }}
                                            // required
                                        />
                                        <label htmlFor="popup-form-input-email">Email</label>
                                        <i className="material-icons">email</i>
                                    </div>
                                    <div className={"popup-form-error-div"}>
                                        {
                                            phoneValidationErrors.map((errorMsg, index) => {
                                                return (
                                                    <p key={"phone_validation_errors"+index} className="t-align-center red-a700">{errorMsg}</p>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="input-div icon popup-form-input">
                                        <input 
                                            id="popup-form-input-phone"
                                            className="border-white " 
                                            type="text" 
                                            placeholder=" " 
                                            value={phone}
                                            onChange={e => { e.preventDefault(); setPhone(e.target.value); }}
                                            required
                                        />
                                        <label htmlFor="popup-form-input-phone">Phone</label>
                                        <i className="material-icons">phone</i>
                                    </div>
                                    <div className="">
                                        <button
                                            className="button popup-form-send-btn"
                                            type="submit">SEND</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default PopupForm;