import { BrandLogoImagesTextArray } from "../../../Assets/Assets";
import BrandLogo from "../BrandLogo/BrandLogo";
import "./BrandLogoSection.scss";

const BrandLogoSection = () => {
    let logoFrag = [];

    for(let imgKey of Object.keys(BrandLogoImagesTextArray.BrandLogoImgsWithText)) {
        logoFrag.push(
            <BrandLogo
                img={BrandLogoImagesTextArray.BrandLogoImgsWithText[imgKey].png}
                alt={BrandLogoImagesTextArray.BrandLogoImgsWithText[imgKey].alt}
                imgWebp={BrandLogoImagesTextArray.BrandLogoImgsWithText[imgKey].webp}
                w={BrandLogoImagesTextArray.BrandLogoImgsWithText[imgKey].w}
                h={BrandLogoImagesTextArray.BrandLogoImgsWithText[imgKey].h}
                title={imgKey}
                key={imgKey}
            />
        );
    }

    return (
        <div className={"brand-logo-section"}>
            <div className={"brand-logo-section-title t-align-center"}>
                <h5 className={"primary-color"}>BRANDS THAT WE COVER</h5>
            </div>
            <div className={"brand-logo-section-content t-align-center"} >
                {logoFrag}
            </div>
        </div>
    )
}

export default BrandLogoSection;