import { useEffect, useRef, useState } from 'react';
import './Social.scss';

const Social = () => {

    const [counter, setCounter] = useState(0);
    // const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
    const [fbParsed, setFbParsed] = useState(false);
    const fbElemRef = useRef<HTMLDivElement>(null);

    //timer is used to try to reload fb iframe till fb sdk is downloaded (index.html)
    useEffect(() => {
        if (!fbElemRef.current) return;
        let fbElem = fbElemRef.current;
        let fbHtmlElem = fbElem.querySelector("iframe"); //html element inside fb iframe

        // if html element inside fb iframe is present then the fb script ran already
        if (fbHtmlElem) {
            return;
        }

        if (!fbParsed) {
            const timer = setInterval(() => {
                // skip first interval
                if (counter > 0) {
                    if (FB) {
                        if (!fbHtmlElem) {
                            FB.XFBML.parse();
                            setFbParsed(true);
                            clearInterval(timer);
                        }
                    }
                }
                setCounter(counter + 1);
            }, 3000);
            return () => clearInterval(timer);
        }
    }, [counter, fbParsed]);

    return (
        <div className={"social center"}>
            <div id="fb-root"></div>
            <div className={"col-wrapper-2"}>
                <div className={"mb-col-2 t-col-1 d-col-1"}>
                    <div className={"gmap-iframe-div t-align-center"}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15557.532864883988!2d77.5501132!3d12.8830623!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa56f57e07c827416!2sMY%20CAR%20Multi%20Brand!5e0!3m2!1sen!2sin!4v1610551347925!5m2!1sen!2sin" className={"gmap-iframe"} frameBorder="" style={{border: 0}} allowFullScreen={false} aria-hidden="false" tabIndex={0} title={"My Car"} loading={"lazy"}></iframe>
                    </div>
                </div>
                <div className={"mb-col-2 t-col-1 d-col-1"}>
                    <div className={"fb-iframe-div t-align-center"}>
                        <div ref={fbElemRef} className="fb-page" data-href="https://www.facebook.com/mycarmultibrand/" data-tabs="timeline" data-width="552" data-height="600" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/mycarmultibrand/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/mycarmultibrand/">MY CAR Multibrand</a></blockquote></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Social;