const base_path = "/new_images/ceramic_coating/13__Hyundai_Venue/";

const file_names: string[] = [
    "IMG_8223", // preview
    "IMG_8227",
    "IMG_8236",
    "IMG_8238",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_8223": {w: 1080, h: 810},
    "IMG_8227": {w: 1080, h: 810},
    "IMG_8236": {w: 1080, h: 810},
    "IMG_8238": {w: 810, h: 1080},
}

export const Hyundai_Venue = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Hyundai Venue",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});