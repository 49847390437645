const base_path = "/new_images/ceramic_coating/4__BMW_X1/";

const file_names: string[] = [
    "IMG_1647",
    "IMG_1651",
    "IMG_1654",
    "IMG_1682", // preview
    "IMG_1683",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_1647": {w: 1080, h: 810},
    "IMG_1651": {w: 1080, h: 810},
    "IMG_1654": {w: 1080, h: 810},
    "IMG_1682": {w: 1080, h: 810},
    "IMG_1683": {w: 1080, h: 810},
}

export const BMW_X1 = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "BMW X1",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});