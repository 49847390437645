import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteAsync } from "../../../Common/Api/CommonDelete";
import { IBlogDeleteRequest, IBlogDeleteErrorResponse, IBlogDeleteResponse, IBlog } from "../../../Common/Api/BlogApi/Interfaces/Blog";

export const deleteBlog = createAsyncThunk
<IBlogDeleteResponse, IBlogDeleteRequest, {rejectValue: IBlogDeleteErrorResponse}>(
    "blog/delete", async (blogData, thunkAPI) => {
        const {url} = blogData;
        const blogResponse = await deleteAsync({url: url, credentials: 'include', cache: 'no-cache'});

        if (blogResponse.responseStatus === 204) {
            return;
        }
        // else if error in deletion
        let err_msg = "";
        if (blogResponse.responseJson["detail"])
            err_msg = blogResponse.responseJson["detail"]
        else if (blogResponse.responseJson["message"])
            err_msg = blogResponse.responseJson["message"]
        let response = {
            error_description: err_msg,
        } as IBlogDeleteErrorResponse;
        return thunkAPI.rejectWithValue(response)
    }
)

export const deleteBlogBuilder = (builder: ActionReducerMapBuilder<IBlog>) => {
    builder.addCase(deleteBlog.pending, (state) => {
        state.request_states.delete = { pending: true, fulfilled: false, rejected: false };
        return state;
    })
    builder.addCase(deleteBlog.fulfilled, (state) => {
        state.request_states.delete = { pending: false, fulfilled: true, rejected: false };
        return state;
    })
    builder.addCase(deleteBlog.rejected, (state, action) => {
        if (action.payload) {
            state.request_states.delete.error_description = action.payload.error_description;
        }
        state.request_states.delete = { pending: false, fulfilled: false, rejected: true };
        return state;
    })
}