import { audi, bmw, chevrolet, citroen, datsun, fiat, ford, honda, hyundai, isuzu, jaguar, jeep, kia, landrover, lexus, mahindra, marutisuzuki, mercedesbenz, mg, mini, mitsubishi, nexa, nissan, opel, renault, skoda, tata, toyota, volkswagen, volvo } from "./BrandLogoImages"

export const BrandLogoText = [
    "AUDI",
    "BMW",
    "CHEVROLET",
    "CITROEN",
    "DATSUN",
    "FIAT",
    "FORD",
    "HONDA",
    "HYUNDAI",
    'ISUZU',
    'JAGUAR',
    'JEEP',
    'KIA',
    'LANDROVER',
    'LEXUS',
    'MAHINDRA',
    'MARUTI SUZUKI',
    'MERCEDES BENZ',
    'MITSUBISHI',
    'MINI',
    'NISSAN',
    'OPEL',
    'RENAULT',
    'TATA',
    'TOYOTA',
    'VOLKSWAGEN',
    'VOLVO',
    'MG',
    'SKODA',
    'NEXA',
]

export const BrandLogoImgsWithText: {[index: string]:any} = {
    "AUDI": audi,
    "BMW": bmw,
    "CHEVROLET": chevrolet,
    "CITROEN": citroen,
    "DATSUN": datsun,
    "FIAT": fiat,
    "FORD": ford,
    "HONDA": honda,
    "HYUNDAI": hyundai,
    'ISUZU': isuzu,
    'JAGUAR': jaguar,
    'JEEP': jeep,
    'KIA': kia,
    'LANDROVER': landrover,
    'LEXUS': lexus,
    'MAHINDRA': mahindra,
    'MARUTI SUZUKI': marutisuzuki,
    'MERCEDES BENZ': mercedesbenz,
    'MG': mg,
    'MINI': mini,
    'MITSUBISHI': mitsubishi,
    'NEXA': nexa,
    'NISSAN': nissan,
    'OPEL': opel,
    'RENAULT': renault,
    'SKODA': skoda,
    'TATA': tata,
    'TOYOTA': toyota,
    'VOLKSWAGEN': volkswagen,
    'VOLVO': volvo,
}