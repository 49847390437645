import React, { useEffect, useState } from "react";

import {
  faEnvelope,
  faMapMarkerAlt,
  faPaperPlane,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LogoMultiCarRectBlack } from "../../Assets/Assets";

import "./Footer.scss";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../Globals";

interface FooterLinkParams {
  content: string;
  link: string;
}

const FooterLink = ({ content, link }: FooterLinkParams) => {
  return (
    <p>
      <Link to={link}>
        <i className={"material-icons primary-color"}>keyboard_arrow_right</i>{" "}
        <span className={"white"}>{content}</span>
      </Link>
    </p>
  );
};

const FooterType1 = () => {
  const location = useLocation();
  const [isTextShown, setIsTextShown] = useState(false);

  useEffect(() => {
    if (location.pathname === ROUTES["CERAMIC_COATING"]) setIsTextShown(true);
    else setIsTextShown(false);
  }, [location]);

  return (
    <div className={"footer-1"}>
      <div className={"t-align-left footer-1-logo-div"}>
        <picture>
          <source type={"image/webp"} srcSet={LogoMultiCarRectBlack.webp} />
          <img
            src={LogoMultiCarRectBlack.png}
            alt={LogoMultiCarRectBlack.alt}
            width={LogoMultiCarRectBlack.w}
            height={LogoMultiCarRectBlack.h}
            className={"footer-1-logo"}
          />
        </picture>
      </div>
      {isTextShown ? (
        <p className={"t-align-center"}>
          Accredited by Sririus Pro; Premium Quality Nano Ceramic Coating.
        </p>
      ) : (
        <p>
          Best car care station for multi brand cars, with genuine car
          components for all the brands.
        </p>
      )}
      <p className={"footer-1-icon-para"}>
        <FontAwesomeIcon icon={faPhoneAlt} className={"primary-color"} />
        <span className={"footer-1-icon-phone"}>9945888417</span>
      </p>
      {/* <p className={"footer-1-icon-para"}>
                <FontAwesomeIcon icon={faPhoneAlt} className={"primary-color"}/><span className={"footer-1-icon-phone"}>9945888621</span>
            </p> */}
      <p className={"footer-1-icon-para"}>
        <FontAwesomeIcon icon={faEnvelope} className={"primary-color"} />
        <span className={"footer-1-icon-phone"}>
          contact@mycarmultibrand.com
        </span>
      </p>
      <div className={"footer-1-icon-para-div"}>
        <p>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={"primary-color"} />
        </p>
        <div className={"footer-1-icon-para-location"}>
          <p>#764/765, Narayana Nagar,</p>
          <p>1st block, Kanakapura Rd,</p>
          <p>Doddakallasandra, Uttarahalli Hobli,</p>
          <p>Bengaluru, Karnataka 560062</p>
        </div>
      </div>
    </div>
  );
};

interface FooterType2Params {
  title: string;
  links: FooterLinkParams[];
}
const FooterType2 = ({ title, links }: FooterType2Params) => {
  const Contents = links.map((link, index) => {
    return (
      <FooterLink content={link.content} link={link.link} key={title + index} />
    );
  });

  return (
    <div className={"footer-2"}>
      <div className={"footer-2-heading-div"}>
        <h2>{title}</h2>
      </div>
      <div className={"footer-2-para-div"}>{Contents}</div>
    </div>
  );
};

const FooterType3 = () => {
  return (
    <div className={"footer-3"}>
      <div className={"footer-3-heading-div"}>
        <h2>WORK HOURS</h2>
      </div>

      <div className={"footer-3-content-div"}>
        <div className={"footer-3-div-schedule"}>
          <p className={"footer-3-para-schedule-icon"}>
            <i className={"material-icons primary-color"}>schedule</i>
          </p>
          <p className={"footer-3-para-schedule white"}>
            9 AM - 6:30 PM , Monday - Saturday
          </p>
        </div>
        <p className={"footer-3-para-query"}>
          <span className={"white"}>
            Our Support and Sales team is available to answer your queries
          </span>
        </p>
      </div>

      <div>
        <a
          href={"tel:+91 9945888417"}
          className={"button footer-3-call-button"}
        >
          CALL US TODAY
        </a>
      </div>
    </div>
  );
};

interface FooterNewsletterParams {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}
const FooterNewsletter = ({ email, setEmail }: FooterNewsletterParams) => {
  return (
    <div className={"footer-newsletter"}>
      <div className={"card-panel"}>
        <div className={"col-wrapper-12"}>
          <div className={"d-col-1"}></div>
          <div className={"mb-col-12 t-col-12 d-col-6"}>
            <h4 className={"footer-newsletter-heading secondary-color"}>
              <FontAwesomeIcon
                className={"footer-newsletter-svg"}
                icon={faPaperPlane}
              />{" "}
              &nbsp;NEWSLETTER
            </h4>
            {/* <p className={""}>SIGNUP FOR OUR NEWSLETTER</p> */}
          </div>
          <div className={"mb-col-12 t-col-12 d-col-4"}>
            <form className={"footer-newsletter-form"}>
              <div className="input-div icon footer-newsletter-input">
                <input
                  id="footer-newsletter-input"
                  className="border-indigo-900 "
                  type="text"
                  placeholder=" "
                  value={email}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmail(e.target.value);
                  }}
                  required
                />
                <label htmlFor="footer-newsletter-input">Email</label>
                <i className="material-icons">email</i>
              </div>
              <div className="button-block-wrapper right">
                <button
                  className="button footer-newsletter-send-btn"
                  type="submit"
                >
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {

    const otherPagesFooterLinks = [ 
       { content: "Home", link: ROUTES["HOMEPAGE"]}, 
    //    { content: "About Us", link: ROUTES["ABOUT"]}, 
    //    { content: "Services", link: ROUTES["SERVICES"]}, 
       { content: "Contact", link: ROUTES["CONTACT"]}, 
       { content: "FAQ", link: ROUTES["FAQ"]},
    ] as FooterLinkParams[];

  const quickLinksFooterLinks = [
    { content: "Body Shop", link: ROUTES["BODY_SHOP"] },
    { content: "Cearamic Coating", link: ROUTES["CERAMIC_COATING"] },
    { content: "PPF", link: ROUTES["PPF"] },
    { content: "Car Detailing", link: ROUTES["CAR_DETAILING"] },
    { content: "Car Wrapping", link: ROUTES["CAR_WRAPPING"] },
    // { content: "Pricing", link: ROUTES["PRICING"]},
    //    { content: "Project", link: ROUTES[""]},
  ] as FooterLinkParams[];

  const [email, setEmail] = useState("");

  return (
    <>
      <div className={"footer"}>
        <div className={"footer-dummy"}></div>
        <div className={"footer-bg"}>
          <div className={"footer-overlay"}>
            <div className={"footer-col-wrapper"}>
              <FooterNewsletter email={email} setEmail={setEmail} />
              <div className={"footer-links-wrapper"}>
                <div className={"col-wrapper-12"}>
                  <div className={"mb-col-12 t-col-6 d-col-4"}>
                    <FooterType1 />
                  </div>

                  <div className={"mb-col-12 t-col-6 d-col-2"}>
                    <FooterType2
                      title={"OTHER PAGES"}
                      links={otherPagesFooterLinks}
                    />
                  </div>

                  <div className={"mb-col-12 t-col-6 d-col-2"}>
                    <FooterType2
                      title={"QUICK LINKS"}
                      links={quickLinksFooterLinks}
                    />
                  </div>

                  <div className={"mb-col-12 t-col-6 d-col-4"}>
                    <FooterType3 />
                  </div>
                </div>

                <div className={"col-wrapper-12"}>
                  <div className={"mb-col-12 t-col-12 d-col-12"}>
                    <div className="footer-notes">
                      <p className="t-align-center">
                        Copyright © 2020. All rights reserved. Developed and
                        designed by Digitalably.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
