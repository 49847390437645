import BodyShopBannerWebp from "./Images/header.webp";

// JPG START
import BodyShopBannerJpg from "./Images/header.jpg";

export const BodyShopBanner = {
  jpg: BodyShopBannerJpg,
  webp: BodyShopBannerWebp,
  alt: "Car Detailing",
  w: "1170",
  h: "460",
};

export {BodyShop as BodyShopGallery} from './Images/Gallery'