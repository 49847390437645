const base_path = "/new_images/ppf/7__Ford_Endeavour/";

const file_names: string[] = [
    "DSC_0165",
    "DSC_0240",
    "DSC_0260",
    "DSC_0270",
    "DSC_0273",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "DSC_0165": {w: 1080, h: 717},
    "DSC_0240": {w: 1080, h: 717},
    "DSC_0260": {w: 1080, h: 717},
    "DSC_0270": {w: 1080, h: 717},
    "DSC_0273": {w: 1080, h: 717},
}

export const Ford_Endeavour = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Ford Endeavour",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});