const base_path = "/new_images/ppf/3__Volkswagen_POLO/";

const file_names: string[] = [
    "IMG_2528",
    "IMG_2533",
    "IMG_2552",
    "IMG_2553",
    "IMG_2565",
    "IMG_2578",
    "IMG_2591",
    "IMG_2755",
    "IMG_2756",
    "IMG_2758",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_2528": {w: 3520, h: 1980},
    "IMG_2533": {w: 3520, h: 1980},
    "IMG_2552": {w: 1080, h: 810},
    "IMG_2553": {w: 1080, h: 810},
    "IMG_2565": {w: 1080, h: 608},
    "IMG_2578": {w: 1080, h: 608},
    "IMG_2591": {w: 4032, h: 3024},
    "IMG_2755": {w: 1080, h: 810},
    "IMG_2756": {w: 1080, h: 810},
    "IMG_2758": {w: 1080, h: 810},
}

export const PPF_Volkswagen_POLO = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Volkswagen POLO",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});