const base_path = "/new_images/ceramic_coating/8__BMW_S_1000_RR/";

const file_names: string[] = [
    "1", // preview
    "DSC_0090",
    "DSC_0129",
    "DSC_0133",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "1": {w: 1080, h: 717},
    "DSC_0090": {w: 1080, h: 717},
    "DSC_0129": {w: 1080, h: 717},
    "DSC_0133": {w: 1080, h: 717},
}

export const BMW_S_1000_RR = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "BMW S 1000 RR",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});