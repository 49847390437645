import SPF_MGHector_12Jpg from "./Images/12.jpg";
import SPF_MGHector_2Jpg from "./Images/2.jpg";
import SPF_MGHector_5Jpg from "./Images/5.jpg";
import SPF_MGHector_6Jpg from "./Images/6.jpg";
import SPF_MGHector_7Jpg from "./Images/7.jpg";
import SPF_MGHector_9Jpg from "./Images/9.jpg";

import SPF_MGHector_12Webp from "./Images/12.webp";
import SPF_MGHector_2Webp from "./Images/2.webp";
import SPF_MGHector_5Webp from "./Images/5.webp";
import SPF_MGHector_6Webp from "./Images/6.webp";
import SPF_MGHector_7Webp from "./Images/7.webp";
import SPF_MGHector_9Webp from "./Images/9.webp";

export const SPF_MGHector_12 = {jpg: SPF_MGHector_12Jpg, webp: SPF_MGHector_12Webp, alt: "MG Hector - Sun Protection Film", w: "1280", h: "960"};
export const SPF_MGHector_2 = {jpg: SPF_MGHector_2Jpg, webp: SPF_MGHector_2Webp, alt: "MG Hector - Sun Protection Film", w: "4032", h: "3024"};
export const SPF_MGHector_5 = {jpg: SPF_MGHector_5Jpg, webp: SPF_MGHector_5Webp, alt: "MG Hector - Sun Protection Film", w: "960", h: "1280"};
export const SPF_MGHector_6 = {jpg: SPF_MGHector_6Jpg, webp: SPF_MGHector_6Webp, alt: "MG Hector - Sun Protection Film", w: "960", h: "1280"};
export const SPF_MGHector_7 = {jpg: SPF_MGHector_7Jpg, webp: SPF_MGHector_7Webp, alt: "MG Hector - Sun Protection Film", w: "1280", h: "960"};
export const SPF_MGHector_9 = {jpg: SPF_MGHector_9Jpg, webp: SPF_MGHector_9Webp, alt: "MG Hector - Sun Protection Film", w: "1280", h: "960"};