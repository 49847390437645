import { useCallback, useEffect, useState } from "react";
import { PagedDiv } from "../../../Utils/js/Utils";

import "./PhotoGallery.scss";

export interface Photo {
    img: string, alt?: string, imgWebp?: string, w?: string, h?: string,
    title: string,
    titleColorClass?: string,
}

export interface PhotoGalleryParams {
    photos: Photo[],
    currentIndex: number
}

interface PhotoGalleryButtonParams {
    nextPage: () => void,
    prevPage: () => void,
}
const PhotoGalleryButton = ({nextPage, prevPage}: PhotoGalleryButtonParams) => {
    return (
        <>
            <div className={"photo-gallery-button-wrapper"}>
                <div className={"photo-gallery-button photo-gallery-button-1"}>
                    <button 
                        className={"button"} 
                        type={"button"} 
                        onClick={prevPage}
                    ><i className={"material-icons"}>chevron_left</i></button>
                </div>
                <div className={"photo-gallery-button photo-gallery-button-2"}>
                    <button
                        className={"button"} 
                        type={"button"} 
                        onClick={nextPage}
                    ><i className={"material-icons"}>chevron_right</i></button>
                </div>
            </div>
        </>
    )
}

export const PhotoGallery = ({photos, currentIndex}: PhotoGalleryParams) => {

    const [counter, setCounter] = useState(0);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [resetTimerEffect, setResetTimerEffect] = useState<boolean>(false);
    const [pagedDivInstance, setPagedDivInstance] = useState<PagedDiv | null>(null);

    useEffect( () => {
        let _pagedDivInstance = new PagedDiv(".photo-gallery-paged", currentIndex, false, true, true);
        setPagedDivInstance(_pagedDivInstance);

        _pagedDivInstance.addWindowResizeListener();

        return () => _pagedDivInstance.removeWindowResizeListener();
    }, []);

    // //timer is used to slide carousel
    // useEffect(() => {
    //     const _timer = setInterval(() => {
    //         if (pagedDivInstance) {
    //             pagedDivInstance.slideLeft();
    //         }

    //         // counter should be updated on each loop
    //         setCounter(counter + 1);
    //     }, 3000);
    //     return () => clearInterval(_timer);
    // }, [counter, pagedDivInstance]);

    //timer is used to slide carousel
    useEffect(() => {
        if (resetTimerEffect) {
            // will run useEffect twice... 
            //  first to reset the resetTimerEffect to initial and second to set the timer 
            // (because setResetTimerEffect is executed twice... one from callback and the other one from here)
            setResetTimerEffect(false);
            return;
        }
        const _timer = setInterval(() => {
            if (pagedDivInstance) {
                pagedDivInstance.slideLeft();
            }

            // counter should be updated on each loop
            setCounter(counter + 1);
        }, 3000);
        setTimer(_timer);
        return () => clearInterval(_timer);
    }, [counter, pagedDivInstance, resetTimerEffect]);

    // reset timer upon user interaction
    const resetTimer = useCallback( () => {
        if (timer) 
            clearInterval(timer);
        setResetTimerEffect(true);
    }, [timer]);

    const nextPage = useCallback( () => {
        if (pagedDivInstance) {
            pagedDivInstance.slideLeft();
        }
        resetTimer();
    }, [pagedDivInstance, resetTimer]);

    const prevPage = useCallback( () => {
        if (pagedDivInstance) {
            pagedDivInstance.slideRight();
        }
        resetTimer();
    }, [pagedDivInstance, resetTimer]);

    const _photos = photos.map( (photo, index) => {
        return (
            <div className={"photo-gallery-paged-child"} key={ photo.title + index } >
                <h5 className={photo.titleColorClass}>{photo.title}</h5>
                <picture>
                    {photo.imgWebp && <source type={"image/webp"} srcSet={photo.imgWebp}/>}
                    <img
                        src={photo.img} alt={photo.alt?photo.alt:"gallery"}
                        width={photo.w?photo.w:undefined} height={photo.h?photo.h:undefined}    
                    />
                </picture>
            </div>
        );
    } )

    return (
        <div className={"photo-gallery"}>
            <PhotoGalleryButton nextPage={nextPage} prevPage={prevPage}/>
            <div className={"paged photo-gallery-paged"}>
                {_photos}
            </div>
        </div>
    )
}