import audiPng from "./Images/audi.png";
import bmwPng from "./Images/bmw.png";
import chevroletPng from "./Images/chevrolet.png";
import citroenPng from "./Images/citroen.png";
import datsunPng from "./Images/datsun.png";
import fiatPng from "./Images/fiat.png";
import fordPng from "./Images/ford.png";
import hondaPng from "./Images/honda.png";
import hyundaiPng from "./Images/hyundai.png";
import isuzuPng from "./Images/isuzu.png";
import jaguarPng from "./Images/jaguar.png";
import jeepPng from "./Images/jeep.png";
import kiaPng from "./Images/kia.png";
import landroverPng from "./Images/landrover.png";
import lexusPng from "./Images/lexus.png";
import mahindraPng from "./Images/mahindra.png";
import marutisuzukiPng from "./Images/marutisuzuki.png";
import mercedesbenzPng from "./Images/mercedesbenz.png";
import mitsubishiPng from "./Images/mitsubishi.png";
import miniPng from "./Images/mini.png";
import nissanPng from "./Images/nissan.png";
import opelPng from "./Images/opel.png";
import renaultPng from "./Images/renault.png";
import tataPng from "./Images/tata.png";
import toyotaPng from "./Images/toyota.png";
import volkswagenPng from "./Images/volkswagen.png";
import volvoPng from "./Images/volvo.png";
import mgPng from "./Images/mg.png";
import skodaPng from "./Images/skoda.png";
import nexaPng from "./Images/nexa.png";

import audiWebp from "./Images/audi.webp";
import bmwWebp from "./Images/bmw.webp";
import chevroletWebp from "./Images/chevrolet.webp";
import citroenWebp from "./Images/citroen.webp";
import datsunWebp from "./Images/datsun.webp";
import fiatWebp from "./Images/fiat.webp";
import fordWebp from "./Images/ford.webp";
import hondaWebp from "./Images/honda.webp";
import hyundaiWebp from "./Images/hyundai.webp";
import isuzuWebp from "./Images/isuzu.webp";
import jaguarWebp from "./Images/jaguar.webp";
import jeepWebp from "./Images/jeep.webp";
import kiaWebp from "./Images/kia.webp";
import landroverWebp from "./Images/landrover.webp";
import lexusWebp from "./Images/lexus.webp";
import mahindraWebp from "./Images/mahindra.webp";
import marutisuzukiWebp from "./Images/marutisuzuki.webp";
import mercedesbenzWebp from "./Images/mercedesbenz.webp";
import mitsubishiWebp from "./Images/mitsubishi.webp";
import miniWebp from "./Images/mini.webp";
import nissanWebp from "./Images/nissan.webp";
import opelWebp from "./Images/opel.webp";
import renaultWebp from "./Images/renault.webp";
import tataWebp from "./Images/tata.webp";
import toyotaWebp from "./Images/toyota.webp";
import volkswagenWebp from "./Images/volkswagen.webp";
import volvoWebp from "./Images/volvo.webp";
import mgWebp from "./Images/mg.webp";
import skodaWebp from "./Images/skoda.webp";
import nexaWebp from "./Images/nexa.webp";

const audi = {png: audiPng, webp: audiWebp, alt: "audi", w: "209", h: "209"};
const bmw = {png: bmwPng, webp: bmwWebp, alt: "bmw", w: "209", h: "209"};
const chevrolet = {png: chevroletPng, webp: chevroletWebp, alt: "chevrolet", w: "209", h: "209"};
const citroen = {png: citroenPng, webp: citroenWebp, alt: "citroen", w: "88", h: "63"};
const datsun = {png: datsunPng, webp: datsunWebp, alt: "datsun", w: "209", h: "209"};
const fiat = {png: fiatPng, webp: fiatWebp, alt: "fiat", w: "209", h: "209"};
const ford = {png: fordPng, webp: fordWebp, alt: "ford", w: "209", h: "209"};
const honda = {png: hondaPng, webp: hondaWebp, alt: "honda", w: "209", h: "209"};
const hyundai = {png: hyundaiPng, webp: hyundaiWebp, alt: "hyundai", w: "209", h: "209"};
const isuzu = {png: isuzuPng, webp: isuzuWebp, alt: "isuzu", w: "300", h: "300"};
const jaguar = {png: jaguarPng, webp: jaguarWebp, alt: "jaguar", w: "209", h: "209"};
const jeep = {png: jeepPng, webp: jeepWebp, alt: "jeep", w: "101", h: "101"};
const kia = {png: kiaPng, webp: kiaWebp, alt: "kia", w: "209", h: "209"};
const landrover = {png: landroverPng, webp: landroverWebp, alt: "landrover", w: "209", h: "209"};
const lexus = {png: lexusPng, webp: lexusWebp, alt: "lexus", w: "100", h: "100"};
const mahindra = {png: mahindraPng, webp: mahindraWebp, alt: "mahindra", w: "209", h: "209"};
const marutisuzuki = {png: marutisuzukiPng, webp: marutisuzukiWebp, alt: "marutisuzuki", w: "209", h: "209"};
const mercedesbenz = {png: mercedesbenzPng, webp: mercedesbenzWebp, alt: "mercedesbenz", w: "209", h: "209"};
const mg = {png: mgPng, webp: mgWebp, alt: "mg", w: "300", h: "294"};
const mini = {png: miniPng, webp: miniWebp, alt: "mini", w: "100", h: "100"};
const mitsubishi = {png: mitsubishiPng, webp: mitsubishiWebp, alt: "mitsubishi", w: "209", h: "209"};
const nexa = {png: nexaPng, webp: nexaWebp, alt: "nexa", w: "300", h: "150"};
const nissan = {png: nissanPng, webp: nissanWebp, alt: "nissan", w: "209", h: "209"};
const opel = {png: opelPng, webp: opelWebp, alt: "opel", w: "209", h: "209"};
const renault = {png: renaultPng, webp: renaultWebp, alt: "renault", w: "209", h: "209"};
const skoda = {png: skodaPng, webp: skodaWebp, alt: "skoda", w: "1076", h: "840"};
const tata = {png: tataPng, webp: tataWebp, alt: "tata", w: "209", h: "209"};
const toyota = {png: toyotaPng, webp: toyotaWebp, alt: "toyota", w: "209", h: "209"};
const volkswagen = {png: volkswagenPng, webp: volkswagenWebp, alt: "volkswagen", w: "209", h: "209"};
const volvo = {png: volvoPng, webp: volvoWebp, alt: "volvo", w: "209", h: "209"};

export {
    audi,
    bmw,
    chevrolet,
    citroen,
    datsun,
    fiat,
    ford,
    honda,
    hyundai,
    isuzu,
    jaguar,
    jeep,
    kia,
    landrover,
    lexus,
    mahindra,
    marutisuzuki,
    mercedesbenz,
    mitsubishi,
    mini,
    nissan,
    opel,
    renault,
    tata,
    toyota,
    volkswagen,
    volvo,
    mg,
    nexa,
    skoda,
};