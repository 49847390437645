import { useCallback } from "react";

import "./ScrollTo.scss";

const ScrollTo = ({
    targetRef
}: {
    targetRef: React.RefObject<HTMLElement>
}) => {

    const handleClick = useCallback(() => {
        if (!targetRef || !targetRef.current) return;
        console.log(targetRef)
        targetRef.current.scrollIntoView();
    }, [targetRef])
    return (
        <div className={"scroll-to"}>
            <button type={"button"} className={"button"} onClick={handleClick}>CLICK TO VIEW IMAGES</button>
        </div>
    )
}

export default ScrollTo;