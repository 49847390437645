import "./Mission.scss";

const Mission = () => {
    return (
        <div className={"mission"}>
            <h6 className={"primary-color t-align-center"}>MISSION</h6>
            <p className={"secondary-color"}>
                We are committed to provide quality service with help our highly skilled technicians. To provide top notch service to the range of multi brand car care service spectrum.
                <br></br>
                We also have service at your door steps , you can stay safe at home & we will take care of your car; these days requirement.
            </p>
        </div>
    )
}

export default Mission;