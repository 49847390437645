import { faSuperpowers } from "@fortawesome/free-brands-svg-icons";
import {
  faHandHoldingUsd,
  faScrewdriver,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../../Store/Slices/QuoteRequestForm/QuoteRequestFormSlice";
import { postQuoteRequestForm } from "../../../Store/Slices/QuoteRequestForm/postQuoteRequestForm";
import { IQuoteRequestForm } from "../../Api/Form/Types/Form/Form";
import AnimatedCard from "../AnimatedCard/AnimatedCard";

import "./IntroContactForm.scss";

const IntroContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");

  const [emailSent, setEmailSent] = useState(false);

  const dispatch = useDispatch();
  const quoteRequest = useSelector(
    (state: { quoteRequestForm: IQuoteRequestForm }) => state.quoteRequestForm
  );

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    quoteRequest.request_states.post.fulfilled
      ? setEmailSent(true)
      : setEmailSent(false);
  }, [quoteRequest.request_states.post.fulfilled]);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(reset());
    dispatch(
      postQuoteRequestForm({
        email: email,
        name: name,
        phone: phone,
        // subject: subject,
        message: msg,
      })
    );
  };

  return (
    <div className={"intro-contact-form"}>
      <div className={"col-wrapper-7"}>
        <div className={"mb-col-7 t-col-4 d-col-4"}>
          <div className={"intro-contact-form-exp center"}>
            <h6 className={"intro-contact-form-exp-title"}>WHO WE ARE</h6>
            {/* <h3 className={"secondary-color"}>WE HAVE 16 YEARS OF EXPERIENCE IN THIS FIELD</h3>
                        <p>
                            Car service and repair, ceramic coating, body shop, washing, interior detailing, exterior detailing, paint protection film, wrapping, accessories, insurance renewal, used car sell/buy/exchange.
                            <br/>
                            One stop solution for all your car’s needs.
                        </p> */}
            <h3 className={"secondary-color"}>
              WE HAVE 16 YEARS OF EXPERIENCE IN THIS FIELD
            </h3>
            <p>
              Experienced professionals specializing in Ceramic Coating, PPF,
              and Body Shop services, delivering top-notch quality and customer
              satisfaction with expertise.
            </p>
            {/* <p>Car care at your door steps!!!</p> */}
          </div>
          <div className={"intro-contact-form-exp-cards center"}>
            <div className={"col-wrapper-12"}>
              <div className={"mb-col-12 t-col-6 d-col-6"}>
                <AnimatedCard
                  title={"BEST TECHNICIANS"}
                  faIcon={faScrewdriver}
                  useFontAwesome={true}
                />
              </div>
              <div className={"mb-col-12 t-col-6 d-col-6"}>
                <AnimatedCard
                  title={"QUALITY SERVICES"}
                  faIcon={faSuperpowers}
                  useFontAwesome={true}
                />
              </div>
              <div className={"mb-col-12 t-col-12 d-col-12"}>
                <AnimatedCard
                  title={"AFFORDABLE PRICES"}
                  faIcon={faHandHoldingUsd}
                  useFontAwesome={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"mb-col-7 t-col-3 d-col-3"}>
          <div className={"intro-contact-form-quote center"}>
            <div className="intro-contact-form-quote-title t-align-center white">
              <h4>REQUEST A QUOTE</h4>
            </div>
            <form
              className={"intro-contact-form-quote-form"}
              onSubmit={handleFormSubmit}
            >
              {emailSent && (
                <div className={"intro-contact-form-notified-div"}>
                  <h6 className={"secondary-color t-align-center"}>
                    We have been notified. Thank you for filling out the form!
                  </h6>
                </div>
              )}
              <div className={"intro-contact-form-error-div"}>
                {quoteRequest.detail.validation_errors.name.map(
                  (errorMsg, index) => {
                    return (
                      <p
                        key={"name_validation_errors" + index}
                        className="t-align-center red-a700"
                      >
                        {errorMsg}
                      </p>
                    );
                  }
                )}
              </div>
              <div className="input-div icon intro-contact-form-quote-input">
                <input
                  id="intro-contact-form-quote-input-name"
                  className="border-white "
                  type="text"
                  placeholder=" "
                  value={name}
                  onChange={(e) => {
                    e.preventDefault();
                    setName(e.target.value);
                  }}
                  // required
                />
                <label htmlFor="intro-contact-form-quote-input-name">
                  Name
                </label>
                <i className="material-icons">perm_identity</i>
              </div>
              <div className={"intro-contact-form-error-div"}>
                {quoteRequest.detail.validation_errors.email.map(
                  (errorMsg, index) => {
                    return (
                      <p
                        key={"email_validation_errors" + index}
                        className="t-align-center red-a700"
                      >
                        {errorMsg}
                      </p>
                    );
                  }
                )}
              </div>
              <div className="input-div icon intro-contact-form-quote-input">
                <input
                  id="intro-contact-form-quote-input-email"
                  className="border-white "
                  type="text"
                  placeholder=" "
                  value={email}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmail(e.target.value);
                  }}
                  // required
                />
                <label htmlFor="intro-contact-form-quote-input-email">
                  Email
                </label>
                <i className="material-icons">email</i>
              </div>
              <div className={"intro-contact-form-error-div"}>
                {quoteRequest.detail.validation_errors.phone.map(
                  (errorMsg, index) => {
                    return (
                      <p
                        key={"phone_validation_errors" + index}
                        className="t-align-center red-a700"
                      >
                        {errorMsg}
                      </p>
                    );
                  }
                )}
              </div>
              <div className="input-div icon intro-contact-form-quote-input">
                <input
                  id="intro-contact-form-quote-input-phone"
                  className="border-white "
                  type="text"
                  placeholder=" "
                  value={phone}
                  onChange={(e) => {
                    e.preventDefault();
                    setPhone(e.target.value);
                  }}
                  required
                />
                <label htmlFor="intro-contact-form-quote-input-phone">
                  Phone
                </label>
                <i className="material-icons">phone</i>
              </div>
              {/* <div className={"intro-contact-form-error-div"}>
                                {
                                    quoteRequest.detail.validation_errors.subject.map((errorMsg, index) => {
                                        return (
                                            <p key={"subject_validation_errors"+index}>{errorMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            <div className="input-div icon intro-contact-form-quote-input">
                                <input 
                                    id="intro-contact-form-quote-input-subject"
                                    className="border-white " 
                                    type="text" 
                                    placeholder=" " 
                                    value={subject}
                                    onChange={e => { e.preventDefault(); setSubject(e.target.value); }}
                                    // required
                                />
                                <label htmlFor="intro-contact-form-quote-input-subject">Subject</label>
                                <i className="material-icons">subject</i>
                            </div> */}
              <div className={"intro-contact-form-error-div"}>
                {quoteRequest.detail.validation_errors.message.map(
                  (errorMsg, index) => {
                    return (
                      <p key={"msg_validation_errors" + index}>{errorMsg}</p>
                    );
                  }
                )}
              </div>
              <div className="input-div icon intro-contact-form-quote-input-textarea">
                <textarea
                  className="border-white"
                  id="intro-contact-form-quote-input-msg"
                  placeholder=" "
                  value={msg}
                  onChange={(e) => {
                    e.preventDefault();
                    setMsg(e.target.value);
                  }}
                  rows={5}
                  // required
                />
                <label htmlFor="intro-contact-form-quote-input-msg">
                  Message
                </label>
                <i className="material-icons">message</i>
              </div>
              <div className="">
                <button
                  className="button intro-contact-form-quote-send-btn"
                  type="submit"
                >
                  SUBMIT INQUERY
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContactForm;
