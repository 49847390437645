import { useCallback, useEffect, useRef, useState } from "react";
import FullscreenPhotoGallery from "../FullscreenPhotoGallery/FullscreenPhotoGallery";
import { Photo } from "../PhotoGallery/PhotoGallery";
import "./GalleryBox.scss";

export interface GalleryBoxParams {
  title: string;
  content: string;
  className?: string;
  img: string;
  alt?: string;
  imgWebp?: string;
  w?: string;
  h?: string;
  photos?: Photo[];
  index?: number
}

const GalleryBox = ({
  title,
  content,
  img,
  className = "",
  photos,
  alt,
  imgWebp,
  w,
  h,
  index = 0,
}: GalleryBoxParams) => {
  const [isClosed, setIsClosed] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imgRef = useRef<HTMLImageElement>(null);
  const galleryBoxDiv = useRef<HTMLDivElement>(null);
  const outerDivRef = useRef<HTMLDivElement>(null);
  const overlayDivRef = useRef<HTMLDivElement>(null);
  const textDivRef = useRef<HTMLDivElement>(null);

  const toggleClosed = () => {
    setCurrentIndex(0);
    setIsClosed(!isClosed);
  };

  const FullScreenDiv = photos ? (
    <FullscreenPhotoGallery
      photos={photos}
      toggleClosed={toggleClosed}
      currentIndex={currentIndex}
    />
  ) : null;

  const changeOuterDivHeight = useCallback(() => {
    if (imgRef.current && outerDivRef.current) {
      outerDivRef.current.style.height = imgRef.current.scrollHeight + "px";
    }
    if (imgRef.current && galleryBoxDiv.current) {
      galleryBoxDiv.current.style.height = imgRef.current.scrollHeight + "px";
    }
    if (imgRef.current && outerDivRef.current) {
      outerDivRef.current.style.height = imgRef.current.scrollHeight + "px";
    }
    if (imgRef.current && overlayDivRef.current) {
      overlayDivRef.current.style.height = imgRef.current.scrollHeight + "px";
    }
    if (imgRef.current && textDivRef.current) {
      textDivRef.current.style.height = imgRef.current.scrollHeight + "px";
    }
  }, []);

  useEffect(() => {
    changeOuterDivHeight();
    window.addEventListener("resize", changeOuterDivHeight);
    return () => window.removeEventListener("resize", changeOuterDivHeight);
  }, [changeOuterDivHeight, isLoaded]);

  const handlePhotoClick = (index: number) => {
    setCurrentIndex(index); // Update current photo index when a photo is clicked
    setIsClosed(false); // Open the full-screen gallery
  };

  return (
    <>
      <div className={"gallery-box " + className} ref={galleryBoxDiv}>
        <picture>
          {imgWebp && <source type={"image/webp"} srcSet={imgWebp} />}
          <img
            src={img}
            alt={alt ? alt : "gallery"}
            ref={imgRef}
            onLoad={() => setIsLoaded(true)}
            width={w ? w : undefined}
            height={h ? h : undefined}
          />
        </picture>
        {(photos || title || content) && (
          <div className={"gallery-box-overlay-outer"} ref={outerDivRef}>
            <div className={"gallery-box-overlay"} ref={overlayDivRef}></div>
            <div
              className={"gallery-box-text-section t-align-center"}
              ref={textDivRef}
            >
              <div className={"gallery-box-text-section-inner"}>
                <h5 className={"white"}>{title}</h5>
                <p className={"white"}>{content}</p>
                {photos && photos.length > 0 && (
                  <button
                    className={"button"}
                    type={"button"}
                    onClick={() => handlePhotoClick(index)}
                  >
                    VIEW
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isClosed && FullScreenDiv}
    </>
  );
};

export default GalleryBox;
