import { useEffect, useRef } from "react";
import { Photo, PhotoGallery } from "../PhotoGallery/PhotoGallery";

import "./FullscreenPhotoGallery.scss";

export interface FullscreenPhotoGalleryParams {
    photos: Photo[],
    toggleClosed: () => void,
    currentIndex: number,
}
const FullscreenPhotoGallery = ({photos, toggleClosed, currentIndex}: FullscreenPhotoGalleryParams) => {

    const fullscreenRef = useRef<HTMLDivElement>(null);

    const fullscreenPhotoGalleryHeight = () => {
        document.documentElement.style.setProperty('--fullscreen-photo-gallery-height', `${window.innerHeight}px`)
        return window.innerHeight;
    }


    useEffect(() => {
        if (fullscreenRef.current) {
            fullscreenPhotoGalleryHeight()
            window.addEventListener('resize', fullscreenPhotoGalleryHeight)

            return () => window.removeEventListener('resize', fullscreenPhotoGalleryHeight);
        }
    }, []);

    return (
        <div className={"fullscreen-photo-gallery-wrapper"}>
            <div className={"fullscreen-photo-gallery"} ref={fullscreenRef}>
                <div className={"photo-gallery-wrapper"}>
                    <button className={"button photo-gallery-close-button"} onClick={toggleClosed}><i className={"material-icons"}>close</i></button>
                    <PhotoGallery photos={photos} currentIndex={currentIndex}/>
                </div>
            </div>
        </div>
    )
}

export default FullscreenPhotoGallery;