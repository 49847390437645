import Mission from "../Mission/Mission";
import Vision from "../Vision/Vision";
import "./MissionAndVision.scss";

const MissionAndVision = () => {
    return (
        <div className={"mission-and-vision"}>
            {/* <div className={"mission-and-vision-bg"}>
                <div></div>
            </div> */}
            <div className={"mission-and-vision-content"}>
                <div className={"col-wrapper-12"}>
                    <div className={"mb-col-12 t-col-5 d-col-5"}>
                        <Mission />
                    </div>
                    <div className={"mb-col-12 t-col-2 d-col-2 middle"}>
                        <div></div>
                    </div>
                    <div className={"mb-col-12 t-col-5 d-col-5"}><Vision /></div>
                </div>                
            </div>
        </div>
    )
}

export default MissionAndVision;