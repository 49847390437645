const base_path = "/new_images/ceramic_coating/11__Volkswagen_POLO/";

const file_names: string[] = [
    "1", // preview
    "DSC_0426",
    "DSC_0430",
    "DSC_0435",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "1": {w: 1080, h: 717},
    "DSC_0426": {w: 1080, h: 608},
    "DSC_0430": {w: 1080, h: 608},
    "DSC_0435": {w: 1080, h: 608},
}

export const Volkswagen_POLO = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Volkswagen POLO",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});