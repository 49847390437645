const base_path = "/new_images/ceramic_coating/2__Mini_Cooper_JCW/";

const file_names: string[] = [
    "IMG_5154",
    "IMG_5159",
    "IMG_5168",
    "IMG_5204",
    "IMG_5516",
    "IMG_5517",
    "IMG_5531",
    "IMG_5626",
    "IMG_5647",
    "IMG_5790",
    "IMG_5793",
    "IMG_5834",
    "IMG_6029",
    "IMG_6031",
    "IMG_6035",
    "IMG_6089",
    "IMG_6120",
    "IMG_6212",
    "IMG_6293",
    "IMG_6564", // preview
    "IMG_6568",
    "IMG_6571",
    "IMG_6584",
    "IMG_6608",
    "IMG_6615",
    "IMG_6627",
    "IMG_6628",
    "IMG_6629",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_5154": {w: 1080, h: 810},
    "IMG_5159": {w: 810, h: 1080},
    "IMG_5168": {w: 1080, h: 810},
    "IMG_5204": {w: 810, h: 1080},
    "IMG_5516": {w: 810, h: 1080},
    "IMG_5517": {w: 810, h: 1080},
    "IMG_5531": {w: 810, h: 1080},
    "IMG_5626": {w: 608, h: 1080},
    "IMG_5647": {w: 608, h: 1080},
    "IMG_5790": {w: 608, h: 1080},
    "IMG_5793": {w: 608, h: 1080},
    "IMG_5834": {w: 810, h: 1080},
    "IMG_6029": {w: 608, h: 1080},
    "IMG_6031": {w: 608, h: 1080},
    "IMG_6035": {w: 810, h: 1080},
    "IMG_6089": {w: 608, h: 1080},
    "IMG_6120": {w: 608, h: 1080},
    "IMG_6212": {w: 608, h: 1080},
    "IMG_6293": {w: 608, h: 1080},
    "IMG_6564": {w: 810, h: 1080},
    "IMG_6568": {w: 810, h: 1080},
    "IMG_6571": {w: 1080, h: 810},
    "IMG_6584": {w: 810, h: 1080},
    "IMG_6608": {w: 810, h: 1080},
    "IMG_6615": {w: 1080, h: 810},
    "IMG_6627": {w: 1080, h: 810},
    "IMG_6628": {w: 810, h: 1080},
    "IMG_6629": {w: 810, h: 1080},
}

export const Mini_Cooper_JCW = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Mini Cooper JCW",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});