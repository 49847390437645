const base_path = "/new_images/ceramic_coating/14__Maruti_Suzuki_Brezza/";

const file_names: string[] = [
    "IMG_4221", // preview
    "IMG_4224",
    "IMG_4243",
    "IMG_4255",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_4221": {w: 1920, h: 1440},
    "IMG_4224": {w: 1920, h: 1440},
    "IMG_4243": {w: 1440, h: 1920},
    "IMG_4255": {w: 1920, h: 1440},
}

export const Maruti_Suzuki_Brezza = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Maruti Suzuki Brezza",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});