import PPFPageLocationJpg from "./Images/PageLocation/PPFPageLocation.jpg";
import StekDynoShieldJpg from "./Images/Products/stek_dyno_shield.jpg";
import SuntekUltraJpg from "./Images/Products/suntek_ultra.jpg";
import XpelUltimatePlusJpg from "./Images/Products/xpel_ultimate_plus.jpg";

import PPFPageLocationWebp from "./Images/PageLocation/PPFPageLocation.webp";
import StekDynoShieldWebp from "./Images/Products/stek_dyno_shield.webp";
import SuntekUltraWebp from "./Images/Products/suntek_ultra.webp";
import XpelUltimatePlusWebp from "./Images/Products/xpel_ultimate_plus.webp";

const PPFPageLocation = {jpg: PPFPageLocationJpg, webp: PPFPageLocationWebp, alt: "Paint Protection Film", w: "1000", h: "664"};
const StekDynoShield = {jpg: StekDynoShieldJpg, webp: StekDynoShieldWebp, alt: "Stek DynoShield", w: "500", h: "189"};
const SuntekUltra = {jpg: SuntekUltraJpg, webp: SuntekUltraWebp, alt: "Suntek Ultra", w: "677", h: "309"};
const XpelUltimatePlus = {jpg: XpelUltimatePlusJpg, webp: XpelUltimatePlusWebp, alt: "Xpel Ultimate Plus", w: "339", h: "149"};

export {
    PPFPageLocation,
    StekDynoShield, SuntekUltra, XpelUltimatePlus,
}

export * from "./Images/1__MG_ZS_EV";
export * from "./Images/2__Toyota_Fortuner_Legender";
export * from "./Images/3__Volkswagen_POLO";
export * from "./Images/4__Mahindra_Thar";
export * from "./Images/5__Maruti_Suzuki_S_Cross";
export * from "./Images/6__Volkswagen_T_Roc";
export * from "./Images/7__Ford_Endeavour";
export * from "./Images/8__Maruti_Suzuki_Brezza";
export * from "./Images/9__Bikes";

export {PPF  as PPF_Images} from './Images/Gallery';