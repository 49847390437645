import SPF_3MJpg from "./3M.jpg";
import SPF_GarwareIcecoolJpg from "./garware-icecool.jpg";
import SPF_GarwareSunControlFilmJpg from "./garware-sun-control-film.jpg";
import SPF_V_KOOLJpg from "./v-kool.jpg";

import SPF_3MWebp from "./3M.webp";
import SPF_GarwareIcecoolWebp from "./garware-icecool.webp";
import SPF_GarwareSunControlFilmWebp from "./garware-sun-control-film.webp";
import SPF_V_KOOLWebp from "./v-kool.webp";

const SPF_3M = {jpg: SPF_3MJpg, webp: SPF_3MWebp, alt: "3M", w: "1000", h: "1000"};
const SPF_GarwareIcecool = {jpg: SPF_GarwareIcecoolJpg, webp: SPF_GarwareIcecoolWebp, alt: "Garware Icecool", w: "1500", h: "750"};
const SPF_GarwareSunControlFilm = {jpg: SPF_GarwareSunControlFilmJpg, webp: SPF_GarwareSunControlFilmWebp, alt: "Garware Sun Control Film", w: "474", h: "182"};
const SPF_V_KOOL = {jpg: SPF_V_KOOLJpg, webp: SPF_V_KOOLWebp, alt: "V KOOL", w: "800", h: "600"};

export {
    SPF_3M,
    SPF_GarwareIcecool,
    SPF_GarwareSunControlFilm,
    SPF_V_KOOL,
}

export * from "./MGHector/MGHector";
export * from "./KiaSeltosGTLine/KiaSeltosGTLine";
export * from "./MahindraThar/MahindraThar";
export * from "./HyundaiCreta/HyundaiCreta";
export * from "./MGHectorWindowMesh/MGHectorWindowMesh";
export * from "./ToyotaInnovaCrysta/ToyotaInnovaCrysta";
export { SUN_PF as SUN_PF_Gallery } from "./Gallery"