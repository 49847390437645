
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './AnimatedCard.scss'

interface AnimatedCardParams {
    icon?: string,
    faIcon?: IconProp,
    title: string,
    useFontAwesome: boolean
}

const AnimatedCard = ({icon, faIcon, title, useFontAwesome}: AnimatedCardParams) => {
    let iconFrag;
    if (useFontAwesome) {
        if (faIcon)
            iconFrag = <FontAwesomeIcon icon={faIcon} className={"fa-icons primary-color"}/>
    }
    else {
        if (icon)
            iconFrag = <i className={"material-icons primary-color"}>{icon}</i>
    }
    return (
        <>
            <div className={"animated-card"}>
                <div className={"card-panel t-align-center"}>
                    <p>{iconFrag}</p>
                    <h6>{title}</h6>
                </div>
            </div>
        </>
    )
}

export default AnimatedCard;