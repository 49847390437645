import { configureStore } from '@reduxjs/toolkit';
import AppSlice from "./Slices/App/AppSlice";
import PopupFormSlice from './Slices/PopupForm/PopupFormSlice';
import QuoteRequestFormSlice from './Slices/QuoteRequestForm/QuoteRequestFormSlice';
import blogReducer from "./Slices/Blog/BlogSlice";
import blogListReducer from "./Slices/BlogList/BlogListSlice";
import commentListReducer from "./Slices/CommentList/CommentListSlice";

export default configureStore({
    reducer: {
        app: AppSlice,
        popupForm: PopupFormSlice,
        quoteRequestForm: QuoteRequestFormSlice,
        blog: blogReducer,
        blogList: blogListReducer,
        commentList: commentListReducer,
    },
    devTools: false,
});