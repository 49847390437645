const base_path = "/new_images/ppf/4__Mahindra_Thar/";

const file_names: string[] = [
    "IMG_7926",
    "IMG_7938",
    "IMG_7951",
    "IMG_7985",
    "IMG_8398",
    "IMG_8402",
    "IMG_8414",
    "IMG_9082",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_7926": {w: 1080, h: 810},
    "IMG_7938": {w: 1080, h: 608},
    "IMG_7951": {w: 1080, h: 608},
    "IMG_7985": {w: 1080, h: 608},
    "IMG_8398": {w: 810, h: 1080},
    "IMG_8402": {w: 1080, h: 810},
    "IMG_8414": {w: 810, h: 1080},
    "IMG_9082": {w: 810, h: 1080},
}

export const Mahindra_Thar = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Mahindra Thar",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});