import MercedesBenzGLGreenBeforeAfter11_7Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-11_7.jpg";
import MercedesBenzGLGreenBeforeAfter12_17Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-12_17.jpg";
import MercedesBenzGLGreenBeforeAfter13_22Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-13_22.jpg";
import MercedesBenzGLGreenBeforeAfter4_2Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-4_2.jpg";
import MercedesBenzGLGreenBeforeAfter5_3Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-5_3.jpg";
import MercedesBenzGLGreenBeforeAfter6_4Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-6_4.jpg";
import MercedesBenzGLGreenBeforeAfter7_5Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-7_5.jpg";
import MercedesBenzGLGreenBeforeAfter8_21Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-8_21.jpg";
import MercedesBenzGLGreenBeforeAfter9_10Jpg from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-9_10.jpg";
import CarWrappingPageLocationJpg from "./Images/CarWrappingPageLocation.jpg";

import MercedesBenzGLGreenBeforeAfter11_7Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-11_7.webp";
import MercedesBenzGLGreenBeforeAfter12_17Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-12_17.webp";
import MercedesBenzGLGreenBeforeAfter13_22Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-13_22.webp";
import MercedesBenzGLGreenBeforeAfter4_2Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-4_2.webp";
import MercedesBenzGLGreenBeforeAfter5_3Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-5_3.webp";
import MercedesBenzGLGreenBeforeAfter6_4Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-6_4.webp";
import MercedesBenzGLGreenBeforeAfter7_5Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-7_5.webp";
import MercedesBenzGLGreenBeforeAfter8_21Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-8_21.webp";
import MercedesBenzGLGreenBeforeAfter9_10Webp from "./Images/MercedesBenzGLGreen/BeforeAfter/My_car_website_images-9_10.webp";
import CarWrappingPageLocationWebp from "./Images/CarWrappingPageLocation.webp";

import AveryDimensionPng from "./Images/avery_dimension.png";
import AveryDimensionWebp from "./Images/avery_dimension.webp";

const MercedesBenzGLGreenBeforeAfter11_7 = {jpg: MercedesBenzGLGreenBeforeAfter11_7Jpg, webp: MercedesBenzGLGreenBeforeAfter11_7Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter12_17 = {jpg: MercedesBenzGLGreenBeforeAfter12_17Jpg, webp: MercedesBenzGLGreenBeforeAfter12_17Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter13_22 = {jpg: MercedesBenzGLGreenBeforeAfter13_22Jpg, webp: MercedesBenzGLGreenBeforeAfter13_22Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter4_2 = {jpg: MercedesBenzGLGreenBeforeAfter4_2Jpg, webp: MercedesBenzGLGreenBeforeAfter4_2Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter5_3 = {jpg: MercedesBenzGLGreenBeforeAfter5_3Jpg, webp: MercedesBenzGLGreenBeforeAfter5_3Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter6_4 = {jpg: MercedesBenzGLGreenBeforeAfter6_4Jpg, webp: MercedesBenzGLGreenBeforeAfter6_4Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter7_5 = {jpg: MercedesBenzGLGreenBeforeAfter7_5Jpg, webp: MercedesBenzGLGreenBeforeAfter7_5Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter8_21 = {jpg: MercedesBenzGLGreenBeforeAfter8_21Jpg, webp: MercedesBenzGLGreenBeforeAfter8_21Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const MercedesBenzGLGreenBeforeAfter9_10 = {jpg: MercedesBenzGLGreenBeforeAfter9_10Jpg, webp: MercedesBenzGLGreenBeforeAfter9_10Webp, alt: "Mercedes Benz GL - Before / After", w: "1130", h: "375"};
const CarWrappingPageLocation = {jpg: CarWrappingPageLocationJpg, webp: CarWrappingPageLocationWebp, alt: "Car Wrapping", w: "1130", h: "376"};

const AveryDimension = {png: AveryDimensionPng, webp: AveryDimensionWebp, alt: "Avery Dimension", w: "1280", h: "283"};

export {
    AveryDimension, CarWrappingPageLocation,
    MercedesBenzGLGreenBeforeAfter11_7, MercedesBenzGLGreenBeforeAfter12_17, MercedesBenzGLGreenBeforeAfter13_22,
    MercedesBenzGLGreenBeforeAfter4_2, MercedesBenzGLGreenBeforeAfter5_3, MercedesBenzGLGreenBeforeAfter6_4,
    MercedesBenzGLGreenBeforeAfter7_5, MercedesBenzGLGreenBeforeAfter8_21, MercedesBenzGLGreenBeforeAfter9_10,
}

export * from "./Images/Hyundai_SantaFe";
export * from "./Images/Mercedes_Benz_ML_350_CDI";
export {CarWarpping as CarWarppingGallery} from "./Images/Gallery";