import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { postBlogAsyc } from "../../../Common/Api/BlogApi/BlogApi";
import { IBlog, IBlogErrorResponse, IBlogPostData, IBlogResponse } from "../../../Common/Api/BlogApi/Interfaces/Blog";

export const postBlog = createAsyncThunk
<IBlogResponse, IBlogPostData, {rejectValue: IBlogErrorResponse}>(
    "blog/post", async (blogData, thunkAPI) => {
        const blogResponse = await postBlogAsyc(blogData);

        if (blogResponse.responseStatus === 400) {
            let err_msg = "";
            if (blogResponse.responseJson["detail"])
                err_msg = blogResponse.responseJson["detail"]
            else if (blogResponse.responseJson["message"])
                err_msg = blogResponse.responseJson["message"]
            let response = {
                validation_errors: {
                    ...blogResponse.responseJson,
                },
                error_description: err_msg,
            }
            return thunkAPI.rejectWithValue((response) as IBlogErrorResponse)
        }

        //not 201: not created response
        if (blogResponse.responseStatus !== 201) {   
            let err_msg = "";
            if (await blogResponse.responseJson["detail"])
                err_msg = await blogResponse.responseJson["detail"]
            else if (await blogResponse.responseJson["message"])
                err_msg = await blogResponse.responseJson["message"]
            let response = {
                error_description: err_msg,
                ...blogResponse.responseJson
            }
            return thunkAPI.rejectWithValue((response) as IBlogErrorResponse)
        }

        // 201
        return {
            ...blogResponse.responseJson
        } as IBlogResponse;
    }
)

export const postBlogBuilder = (builder: ActionReducerMapBuilder<IBlog>) => {
    builder.addCase(postBlog.pending, (state) => {
        return {
            ...state,
            request_states: {
                ...state.request_states,
                post: {
                    ...state.request_states.post,
                    pending: true, fulfilled: false, rejected: false,
                }
            },
        }
    })
    builder.addCase(postBlog.fulfilled, (state, action) => {
        return {
            ...state,
            ...action.payload,
            request_states: {
                ...state.request_states,
                post: {
                    ...state.request_states.post,
                    pending: false, fulfilled: true, rejected: false,
                }
            },
        };
    })
    builder.addCase(postBlog.rejected, (state, action) => {
        return {
            ...state,
            validation_errors: {
                ...state.validation_errors, ...action.payload?.validation_errors
            },
            request_states: {
                ...state.request_states,
                post: {
                    ...state.request_states.post,
                    pending: false, fulfilled: false, rejected: true,
                    error_description: action.payload?.error_description,
                }
            },
        };
    })
}