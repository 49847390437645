const base_path = "/new_images/ppf/2__Toyota_Fortuner_Legender/";

const file_names: string[] = [
    "IMG_5249",
    "IMG_5274",
    "IMG_5300",
    "IMG_5314",
    "IMG_5319",
    "IMG_5342",
    "IMG_5424",
    "IMG_5437",
    "IMG_5447",
    "IMG_5470",
    "IMG_5480",
    "IMG_5482",
    "IMG_5486",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_5249": {w: 1920, h: 1440},
    "IMG_5274": {w: 1920, h: 1440},
    "IMG_5300": {w: 1920, h: 1440},
    "IMG_5314": {w: 1920, h: 1440},
    "IMG_5319": {w: 1920, h: 1080},
    "IMG_5342": {w: 1920, h: 1440},
    "IMG_5424": {w: 1920, h: 1440},
    "IMG_5437": {w: 1920, h: 1440},
    "IMG_5447": {w: 1920, h: 1440},
    "IMG_5470": {w: 1920, h: 1440},
    "IMG_5480": {w: 1920, h: 1440},
    "IMG_5482": {w: 1920, h: 1440},
    "IMG_5486": {w: 1920, h: 1440},
}

export const Toyota_Fortuner_Legender = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Toyota Fortuner Legender",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});