const base_path = "/new_images/ceramic_coating/6__KIA_Seltos/";

const file_names: string[] = [
    "IMG_3732", // preview
    "IMG_3747",
    "IMG_3787",
    "IMG_3788",
    "IMG_3797",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_3732": {w: 810, h: 1080},
    "IMG_3747": {w: 810, h: 1080},
    "IMG_3787": {w: 810, h: 1080},
    "IMG_3788": {w: 810, h: 1080},
    "IMG_3797": {w: 810, h: 1080},
}

export const KIA_Seltos = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "KIA Seltos",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});