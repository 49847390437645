const PatchUserProfileLoading = () => {
    return (
        <>
            <div>
                <h5>Patching User... Please Wait</h5>
            </div>
        </>
    )
}

export default PatchUserProfileLoading;