const base_path = "/new_images/ppf/6__Volkswagen_T_Roc/";

const file_names: string[] = [
    "IMG_3026",
    "IMG_3032",
    "IMG_3457",
    "IMG_3468",
    "IMG_3487",
    "IMG_3495",
    "IMG_3592",
    "IMG_3618",
    "IMG_3627",
    "IMG_3635",
    "IMG_3643",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_3026": {w: 1080, h: 810},
    "IMG_3032": {w: 1080, h: 810},
    "IMG_3457": {w: 1080, h: 608},
    "IMG_3468": {w: 1080, h: 608},
    "IMG_3487": {w: 1080, h: 810},
    "IMG_3495": {w: 1080, h: 810},
    "IMG_3592": {w: 1080, h: 810},
    "IMG_3618": {w: 1080, h: 810},
    "IMG_3627": {w: 1080, h: 810},
    "IMG_3635": {w: 1080, h: 810},
    "IMG_3643": {w: 1080, h: 810},
}

export const Volkswagen_T_Roc = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Volkswagen T Roc",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});