import AccessoriesJpg from './Images/MyCar/Accessories.jpg';
import CarWash2Jpg from './Images/MyCar/CarWash2.jpg';
import CarInsuranceJpg from './Images/MyCar/CarInsurance.jpg';
import CarInteriorDetailingJpg from './Images/MyCar/CarInteriorDetailing.jpg';
import CarExteriorDetailingJpg from './Images/MyCar/CarExteriorDetailing.jpg';
import CarServiceJpg from './Images/MyCar/CarService.jpg';
import BodyShopJpg from './Images/MyCar/BodyShop.jpg';
import CarWrappingJpg from './Images/MyCar/CarWrapping.jpg';
import PPFJpg from './Images/MyCar/PPF.jpg';
import SunFilmJpg from './Images/MyCar/SunFilm.jpg';
import UsedCarsJpg from './Images/MyCar/UsedCars.jpg';

import AccessoriesWebp from './Images/MyCar/Accessories.webp';
import CarWash2Webp from './Images/MyCar/CarWash2.webp';
import CarInsuranceWebp from './Images/MyCar/CarInsurance.webp';
import CarInteriorDetailingWebp from './Images/MyCar/CarInteriorDetailing.webp';
import CarExteriorDetailingWebp from './Images/MyCar/CarExteriorDetailing.webp';
import CarServiceWebp from './Images/MyCar/CarService.webp';
import BodyShopWebp from './Images/MyCar/BodyShop.webp';
import CarWrappingWebp from './Images/MyCar/CarWrapping.webp';
import PPFWebp from './Images/MyCar/PPF.webp';
import SunFilmWebp from './Images/MyCar/SunFilm.webp';
import UsedCarsWebp from './Images/MyCar/UsedCars.webp';

import ServicesGallery1Jpg from "./Images/Gallery/1.jpg";
import ServicesGallery2Jpg from "./Images/Gallery/2.jpg";
import ServicesGallery3Jpg from "./Images/Gallery/3.jpg";
import ServicesGallery4Jpg from "./Images/Gallery/4.jpg";
import ServicesGallery5Jpg from "./Images/Gallery/5.jpg";
import ServicesGallery6Jpg from "./Images/Gallery/6.jpg";

import ServicesGallery1Webp from "./Images/Gallery/1.webp";
import ServicesGallery2Webp from "./Images/Gallery/2.webp";
import ServicesGallery3Webp from "./Images/Gallery/3.webp";
import ServicesGallery4Webp from "./Images/Gallery/4.webp";
import ServicesGallery5Webp from "./Images/Gallery/5.webp";
import ServicesGallery6Webp from "./Images/Gallery/6.webp";

import Services_Accessories_DSC_0531_Webp from "./Images/Accessories/DSC_0531.webp";
import Services_Accessories_DSC_0532_Webp from "./Images/Accessories/DSC_0532.webp";
import Services_Accessories_DSC_0533_Webp from "./Images/Accessories/DSC_0533.webp";
import Services_Accessories_DSC_0534_Webp from "./Images/Accessories/DSC_0534.webp";
import Services_Accessories_DSC_0539_Webp from "./Images/Accessories/DSC_0539.webp";
import Services_Accessories_DSC_0540_Webp from "./Images/Accessories/DSC_0540.webp";
import Services_Accessories_DSC_0543_Webp from "./Images/Accessories/DSC_0543.webp";
import Services_Accessories_DSC_0544_Webp from "./Images/Accessories/DSC_0544.webp";
import Services_Accessories_DSC_0546_Webp from "./Images/Accessories/DSC_0546.webp";
import Services_Accessories_DSC_0547_Webp from "./Images/Accessories/DSC_0547.webp";
import Services_Accessories_DSC_0548_Webp from "./Images/Accessories/DSC_0548.webp";
import Services_Accessories_DSC_0549_Webp from "./Images/Accessories/DSC_0549.webp";
import Services_Accessories_DSC_0550_Webp from "./Images/Accessories/DSC_0550.webp";
import Services_Accessories_DSC_0551_Webp from "./Images/Accessories/DSC_0551.webp";
import Services_Accessories_DSC_0552_Webp from "./Images/Accessories/DSC_0552.webp";
import Services_Accessories_DSC_0553_Webp from "./Images/Accessories/DSC_0553.webp";
import Services_Accessories_DSC_0554_Webp from "./Images/Accessories/DSC_0554.webp";
import Services_Accessories_DSC_0555_Webp from "./Images/Accessories/DSC_0555.webp";
import Services_Accessories_DSC_0556_Webp from "./Images/Accessories/DSC_0556.webp";
import Services_Accessories_DSC_0557_Webp from "./Images/Accessories/DSC_0557.webp";
import Services_Accessories_DSC_0558_Webp from "./Images/Accessories/DSC_0558.webp";
import Services_Accessories_DSC_0563_Webp from "./Images/Accessories/DSC_0563.webp";
import Services_Accessories_DSC_0564_Webp from "./Images/Accessories/DSC_0564.webp";
import Services_Accessories_DSC_0565_Webp from "./Images/Accessories/DSC_0565.webp";
import Services_Accessories_DSC_0570_Webp from "./Images/Accessories/DSC_0570.webp";
import Services_Accessories_DSC_0576_Webp from "./Images/Accessories/DSC_0576.webp";
import Services_Accessories_DSC_0577_Webp from "./Images/Accessories/DSC_0577.webp";
import Services_Accessories_DSC_0578_Webp from "./Images/Accessories/DSC_0578.webp";
import Services_Accessories_DSC_0581_Webp from "./Images/Accessories/DSC_0581.webp";
import Services_Accessories_DSC_0582_Webp from "./Images/Accessories/DSC_0582.webp";
import Services_Accessories_DSC_0583_Webp from "./Images/Accessories/DSC_0583.webp";
import Services_Accessories_DSC_0584_Webp from "./Images/Accessories/DSC_0584.webp";
import Services_Accessories_DSC_0585_Webp from "./Images/Accessories/DSC_0585.webp";

import Services_Accessories_DSC_0531_Jpg from "./Images/Accessories/DSC_0531.jpg";
import Services_Accessories_DSC_0532_Jpg from "./Images/Accessories/DSC_0532.jpg";
import Services_Accessories_DSC_0533_Jpg from "./Images/Accessories/DSC_0533.jpg";
import Services_Accessories_DSC_0534_Jpg from "./Images/Accessories/DSC_0534.jpg";
import Services_Accessories_DSC_0539_Jpg from "./Images/Accessories/DSC_0539.jpg";
import Services_Accessories_DSC_0540_Jpg from "./Images/Accessories/DSC_0540.jpg";
import Services_Accessories_DSC_0543_Jpg from "./Images/Accessories/DSC_0543.jpg";
import Services_Accessories_DSC_0544_Jpg from "./Images/Accessories/DSC_0544.jpg";
import Services_Accessories_DSC_0546_Jpg from "./Images/Accessories/DSC_0546.jpg";
import Services_Accessories_DSC_0547_Jpg from "./Images/Accessories/DSC_0547.jpg";
import Services_Accessories_DSC_0548_Jpg from "./Images/Accessories/DSC_0548.jpg";
import Services_Accessories_DSC_0549_Jpg from "./Images/Accessories/DSC_0549.jpg";
import Services_Accessories_DSC_0550_Jpg from "./Images/Accessories/DSC_0550.jpg";
import Services_Accessories_DSC_0551_Jpg from "./Images/Accessories/DSC_0551.jpg";
import Services_Accessories_DSC_0552_Jpg from "./Images/Accessories/DSC_0552.jpg";
import Services_Accessories_DSC_0553_Jpg from "./Images/Accessories/DSC_0553.jpg";
import Services_Accessories_DSC_0554_Jpg from "./Images/Accessories/DSC_0554.jpg";
import Services_Accessories_DSC_0555_Jpg from "./Images/Accessories/DSC_0555.jpg";
import Services_Accessories_DSC_0556_Jpg from "./Images/Accessories/DSC_0556.jpg";
import Services_Accessories_DSC_0557_Jpg from "./Images/Accessories/DSC_0557.jpg";
import Services_Accessories_DSC_0558_Jpg from "./Images/Accessories/DSC_0558.jpg";
import Services_Accessories_DSC_0563_Jpg from "./Images/Accessories/DSC_0563.jpg";
import Services_Accessories_DSC_0564_Jpg from "./Images/Accessories/DSC_0564.jpg";
import Services_Accessories_DSC_0565_Jpg from "./Images/Accessories/DSC_0565.jpg";
import Services_Accessories_DSC_0570_Jpg from "./Images/Accessories/DSC_0570.jpg";
import Services_Accessories_DSC_0576_Jpg from "./Images/Accessories/DSC_0576.jpg";
import Services_Accessories_DSC_0577_Jpg from "./Images/Accessories/DSC_0577.jpg";
import Services_Accessories_DSC_0578_Jpg from "./Images/Accessories/DSC_0578.jpg";
import Services_Accessories_DSC_0581_Jpg from "./Images/Accessories/DSC_0581.jpg";
import Services_Accessories_DSC_0582_Jpg from "./Images/Accessories/DSC_0582.jpg";
import Services_Accessories_DSC_0583_Jpg from "./Images/Accessories/DSC_0583.jpg";
import Services_Accessories_DSC_0584_Jpg from "./Images/Accessories/DSC_0584.jpg";
import Services_Accessories_DSC_0585_Jpg from "./Images/Accessories/DSC_0585.jpg";

import Services_Scanner_DSC_0497_Jpg from "./Images/Scanner/DSC_0497.jpg";
import Services_Scanner_DSC_0501_Jpg from "./Images/Scanner/DSC_0501.jpg";
import Services_Scanner_DSC_0502_Jpg from "./Images/Scanner/DSC_0502.jpg";
import Services_Scanner_DSC_0505_Jpg from "./Images/Scanner/DSC_0505.jpg";
import Services_Scanner_DSC_0506_Jpg from "./Images/Scanner/DSC_0506.jpg";
import Services_Scanner_DSC_0507_Jpg from "./Images/Scanner/DSC_0507.jpg";
import Services_Scanner_DSC_0509_Jpg from "./Images/Scanner/DSC_0509.jpg";
import Services_Scanner_DSC_0510_Jpg from "./Images/Scanner/DSC_0510.jpg";
import Services_Scanner_DSC_0512_Jpg from "./Images/Scanner/DSC_0512.jpg";
import Services_Scanner_DSC_0513_Jpg from "./Images/Scanner/DSC_0513.jpg";
import Services_Scanner_DSC_0514_Jpg from "./Images/Scanner/DSC_0514.jpg";
import Services_Scanner_DSC_0517_Jpg from "./Images/Scanner/DSC_0517.jpg";
import Services_Scanner_DSC_0520_Jpg from "./Images/Scanner/DSC_0520.jpg";
import Services_Scanner_DSC_0521_Jpg from "./Images/Scanner/DSC_0521.jpg";
import Services_Scanner_DSC_0526_Jpg from "./Images/Scanner/DSC_0526.jpg";
import Services_Scanner_DSC_0527_Jpg from "./Images/Scanner/DSC_0527.jpg";
import Services_Scanner_DSC_0528_Jpg from "./Images/Scanner/DSC_0528.jpg";
import Services_Scanner_DSC_0530_Jpg from "./Images/Scanner/DSC_0530.jpg";

import Services_Scanner_DSC_0497_Webp from "./Images/Scanner/DSC_0497.webp";
import Services_Scanner_DSC_0501_Webp from "./Images/Scanner/DSC_0501.webp";
import Services_Scanner_DSC_0502_Webp from "./Images/Scanner/DSC_0502.webp";
import Services_Scanner_DSC_0505_Webp from "./Images/Scanner/DSC_0505.webp";
import Services_Scanner_DSC_0506_Webp from "./Images/Scanner/DSC_0506.webp";
import Services_Scanner_DSC_0507_Webp from "./Images/Scanner/DSC_0507.webp";
import Services_Scanner_DSC_0509_Webp from "./Images/Scanner/DSC_0509.webp";
import Services_Scanner_DSC_0510_Webp from "./Images/Scanner/DSC_0510.webp";
import Services_Scanner_DSC_0512_Webp from "./Images/Scanner/DSC_0512.webp";
import Services_Scanner_DSC_0513_Webp from "./Images/Scanner/DSC_0513.webp";
import Services_Scanner_DSC_0514_Webp from "./Images/Scanner/DSC_0514.webp";
import Services_Scanner_DSC_0517_Webp from "./Images/Scanner/DSC_0517.webp";
import Services_Scanner_DSC_0520_Webp from "./Images/Scanner/DSC_0520.webp";
import Services_Scanner_DSC_0521_Webp from "./Images/Scanner/DSC_0521.webp";
import Services_Scanner_DSC_0526_Webp from "./Images/Scanner/DSC_0526.webp";
import Services_Scanner_DSC_0527_Webp from "./Images/Scanner/DSC_0527.webp";
import Services_Scanner_DSC_0528_Webp from "./Images/Scanner/DSC_0528.webp";
import Services_Scanner_DSC_0530_Webp from "./Images/Scanner/DSC_0530.webp";

const Services_Accessories_DSC_0531 = {jpg: Services_Accessories_DSC_0531_Jpg, webp: Services_Accessories_DSC_0531_Webp, alt: "Accessories", w: "4288", h: "2848"};
const Services_Accessories_DSC_0532 = {jpg: Services_Accessories_DSC_0532_Jpg, webp: Services_Accessories_DSC_0532_Webp, alt: "Accessories", w: "4288", h: "2848"};
const Services_Accessories_DSC_0533 = {jpg: Services_Accessories_DSC_0533_Jpg, webp: Services_Accessories_DSC_0533_Webp, alt: "Accessories", w: "4288", h: "2848"};
const Services_Accessories_DSC_0534 = {jpg: Services_Accessories_DSC_0534_Jpg, webp: Services_Accessories_DSC_0534_Webp, alt: "Accessories", w: "4288", h: "2848"};
const Services_Accessories_DSC_0539 = {jpg: Services_Accessories_DSC_0539_Jpg, webp: Services_Accessories_DSC_0539_Webp, alt: "Accessories", w: "3891", h: "2584"};
const Services_Accessories_DSC_0540 = {jpg: Services_Accessories_DSC_0540_Jpg, webp: Services_Accessories_DSC_0540_Webp, alt: "Accessories", w: "4155", h: "2760"};
const Services_Accessories_DSC_0543 = {jpg: Services_Accessories_DSC_0543_Jpg, webp: Services_Accessories_DSC_0543_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0544 = {jpg: Services_Accessories_DSC_0544_Jpg, webp: Services_Accessories_DSC_0544_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0546 = {jpg: Services_Accessories_DSC_0546_Jpg, webp: Services_Accessories_DSC_0546_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0547 = {jpg: Services_Accessories_DSC_0547_Jpg, webp: Services_Accessories_DSC_0547_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0548 = {jpg: Services_Accessories_DSC_0548_Jpg, webp: Services_Accessories_DSC_0548_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0549 = {jpg: Services_Accessories_DSC_0549_Jpg, webp: Services_Accessories_DSC_0549_Webp, alt: "Accessories", w: "2782", h: "4188"};
const Services_Accessories_DSC_0550 = {jpg: Services_Accessories_DSC_0550_Jpg, webp: Services_Accessories_DSC_0550_Webp, alt: "Accessories", w: "2782", h: "4188"};
const Services_Accessories_DSC_0551 = {jpg: Services_Accessories_DSC_0551_Jpg, webp: Services_Accessories_DSC_0551_Webp, alt: "Accessories", w: "2782", h: "4188"};
const Services_Accessories_DSC_0552 = {jpg: Services_Accessories_DSC_0552_Jpg, webp: Services_Accessories_DSC_0552_Webp, alt: "Accessories", w: "2782", h: "4188"};
const Services_Accessories_DSC_0553 = {jpg: Services_Accessories_DSC_0553_Jpg, webp: Services_Accessories_DSC_0553_Webp, alt: "Accessories", w: "2782", h: "4188"};
const Services_Accessories_DSC_0554 = {jpg: Services_Accessories_DSC_0554_Jpg, webp: Services_Accessories_DSC_0554_Webp, alt: "Accessories", w: "2782", h: "4188"};
const Services_Accessories_DSC_0555 = {jpg: Services_Accessories_DSC_0555_Jpg, webp: Services_Accessories_DSC_0555_Webp, alt: "Accessories", w: "2733", h: "4115"};
const Services_Accessories_DSC_0556 = {jpg: Services_Accessories_DSC_0556_Jpg, webp: Services_Accessories_DSC_0556_Webp, alt: "Accessories", w: "2745", h: "4133"};
const Services_Accessories_DSC_0557 = {jpg: Services_Accessories_DSC_0557_Jpg, webp: Services_Accessories_DSC_0557_Webp, alt: "Accessories", w: "3676", h: "2442"};
const Services_Accessories_DSC_0558 = {jpg: Services_Accessories_DSC_0558_Jpg, webp: Services_Accessories_DSC_0558_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0563 = {jpg: Services_Accessories_DSC_0563_Jpg, webp: Services_Accessories_DSC_0563_Webp, alt: "Accessories", w: "4183", h: "2778"};
const Services_Accessories_DSC_0564 = {jpg: Services_Accessories_DSC_0564_Jpg, webp: Services_Accessories_DSC_0564_Webp, alt: "Accessories", w: "3848", h: "2556"};
const Services_Accessories_DSC_0565 = {jpg: Services_Accessories_DSC_0565_Jpg, webp: Services_Accessories_DSC_0565_Webp, alt: "Accessories", w: "3542", h: "2353"};
const Services_Accessories_DSC_0570 = {jpg: Services_Accessories_DSC_0570_Jpg, webp: Services_Accessories_DSC_0570_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0576 = {jpg: Services_Accessories_DSC_0576_Jpg, webp: Services_Accessories_DSC_0576_Webp, alt: "Accessories", w: "3485", h: "2315"};
const Services_Accessories_DSC_0577 = {jpg: Services_Accessories_DSC_0577_Jpg, webp: Services_Accessories_DSC_0577_Webp, alt: "Accessories", w: "3490", h: "2318"};
const Services_Accessories_DSC_0578 = {jpg: Services_Accessories_DSC_0578_Jpg, webp: Services_Accessories_DSC_0578_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0581 = {jpg: Services_Accessories_DSC_0581_Jpg, webp: Services_Accessories_DSC_0581_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0582 = {jpg: Services_Accessories_DSC_0582_Jpg, webp: Services_Accessories_DSC_0582_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0583 = {jpg: Services_Accessories_DSC_0583_Jpg, webp: Services_Accessories_DSC_0583_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0584 = {jpg: Services_Accessories_DSC_0584_Jpg, webp: Services_Accessories_DSC_0584_Webp, alt: "Accessories", w: "4188", h: "2782"};
const Services_Accessories_DSC_0585 = {jpg: Services_Accessories_DSC_0585_Jpg, webp: Services_Accessories_DSC_0585_Webp, alt: "Accessories", w: "4188", h: "2782"};

const Services_Scanner_DSC_0497 = {jpg: Services_Scanner_DSC_0497_Jpg, webp: Services_Scanner_DSC_0497_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0501 = {jpg: Services_Scanner_DSC_0501_Jpg, webp: Services_Scanner_DSC_0501_Webp, alt: "Scanner", w: "3306", h: "2196"};
const Services_Scanner_DSC_0502 = {jpg: Services_Scanner_DSC_0502_Jpg, webp: Services_Scanner_DSC_0502_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0505 = {jpg: Services_Scanner_DSC_0505_Jpg, webp: Services_Scanner_DSC_0505_Webp, alt: "Scanner", w: "3729", h: "2477"};
const Services_Scanner_DSC_0506 = {jpg: Services_Scanner_DSC_0506_Jpg, webp: Services_Scanner_DSC_0506_Webp, alt: "Scanner", w: "3568", h: "2370"};
const Services_Scanner_DSC_0507 = {jpg: Services_Scanner_DSC_0507_Jpg, webp: Services_Scanner_DSC_0507_Webp, alt: "Scanner", w: "3696", h: "2520"};
const Services_Scanner_DSC_0509 = {jpg: Services_Scanner_DSC_0509_Jpg, webp: Services_Scanner_DSC_0509_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0510 = {jpg: Services_Scanner_DSC_0510_Jpg, webp: Services_Scanner_DSC_0510_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0512 = {jpg: Services_Scanner_DSC_0512_Jpg, webp: Services_Scanner_DSC_0512_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0513 = {jpg: Services_Scanner_DSC_0513_Jpg, webp: Services_Scanner_DSC_0513_Webp, alt: "Scanner", w: "4085", h: "2713"};
const Services_Scanner_DSC_0514 = {jpg: Services_Scanner_DSC_0514_Jpg, webp: Services_Scanner_DSC_0514_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0517 = {jpg: Services_Scanner_DSC_0517_Jpg, webp: Services_Scanner_DSC_0517_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0520 = {jpg: Services_Scanner_DSC_0520_Jpg, webp: Services_Scanner_DSC_0520_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0521 = {jpg: Services_Scanner_DSC_0521_Jpg, webp: Services_Scanner_DSC_0521_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0526 = {jpg: Services_Scanner_DSC_0526_Jpg, webp: Services_Scanner_DSC_0526_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0527 = {jpg: Services_Scanner_DSC_0527_Jpg, webp: Services_Scanner_DSC_0527_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0528 = {jpg: Services_Scanner_DSC_0528_Jpg, webp: Services_Scanner_DSC_0528_Webp, alt: "Scanner", w: "4288", h: "2848"};
const Services_Scanner_DSC_0530 = {jpg: Services_Scanner_DSC_0530_Jpg, webp: Services_Scanner_DSC_0530_Webp, alt: "Scanner", w: "4288", h: "2848"};

const Accessories = {jpg: AccessoriesJpg, webp: AccessoriesWebp, alt: "Accessories", w: "1000", h: "625"};
const BodyShop = {jpg: BodyShopJpg, webp: BodyShopWebp, alt: "BodyShop", w: "1000", h: "625"};
const CarExteriorDetailing = {jpg: CarExteriorDetailingJpg, webp: CarExteriorDetailingWebp, alt: "Car Exterior Detailing", w: "768", h: "480"};
const CarInsurance = {jpg: CarInsuranceJpg, webp: CarInsuranceWebp, alt: "Car Insurance", w: "470", h: "303"};
const CarInteriorDetailing = {jpg: CarInteriorDetailingJpg, webp: CarInteriorDetailingWebp, alt: "Car Interior Detailing", w: "1000", h: "625"};
const CarService = {jpg: CarServiceJpg, webp: CarServiceWebp, alt: "Car Service", w: "1000", h: "625"};
const CarWash2 = {jpg: CarWash2Jpg, webp: CarWash2Webp, alt: "Car Wash", w: "1000", h: "781"};
const CarWrapping = {jpg: CarWrappingJpg, webp: CarWrappingWebp, alt: "Car Wrapping", w: "1000", h: "626"};
const PPF = {jpg: PPFJpg, webp: PPFWebp, alt: "PPF", w: "1000", h: "625"};
const SunFilm = {jpg: SunFilmJpg, webp: SunFilmWebp, alt: "Sun Film", w: "1000", h: "626"};
const UsedCars = {jpg: UsedCarsJpg, webp: UsedCarsWebp, alt: "Used Cars", w: "470", h: "293"};

const ServicesGallery1 = {jpg: ServicesGallery1Jpg, webp: ServicesGallery1Webp, alt: "Services", w: "1000", h: "664"};
const ServicesGallery2 = {jpg: ServicesGallery2Jpg, webp: ServicesGallery2Webp, alt: "Services", w: "1000", h: "664"};
const ServicesGallery3 = {jpg: ServicesGallery3Jpg, webp: ServicesGallery3Webp, alt: "Services", w: "1000", h: "664"};
const ServicesGallery4 = {jpg: ServicesGallery4Jpg, webp: ServicesGallery4Webp, alt: "Services", w: "1000", h: "664"};
const ServicesGallery5 = {jpg: ServicesGallery5Jpg, webp: ServicesGallery5Webp, alt: "Services", w: "1000", h: "664"};
const ServicesGallery6 = {jpg: ServicesGallery6Jpg, webp: ServicesGallery6Webp, alt: "Services", w: "1000", h: "750"};

export {
    Accessories, PPF, CarWash2, CarInsurance, CarInteriorDetailing, CarExteriorDetailing,
    CarService, BodyShop, CarWrapping, SunFilm, UsedCars,
    ServicesGallery1, ServicesGallery2, ServicesGallery3, ServicesGallery4,
    ServicesGallery5, ServicesGallery6,

    Services_Scanner_DSC_0497,
    Services_Scanner_DSC_0501,
    Services_Scanner_DSC_0502,
    Services_Scanner_DSC_0505,
    Services_Scanner_DSC_0506,
    Services_Scanner_DSC_0507,
    Services_Scanner_DSC_0509,
    Services_Scanner_DSC_0510,
    Services_Scanner_DSC_0512,
    Services_Scanner_DSC_0513,
    Services_Scanner_DSC_0514,
    Services_Scanner_DSC_0517,
    Services_Scanner_DSC_0520,
    Services_Scanner_DSC_0521,
    Services_Scanner_DSC_0526,
    Services_Scanner_DSC_0527,
    Services_Scanner_DSC_0528,
    Services_Scanner_DSC_0530,

    Services_Accessories_DSC_0531,
    Services_Accessories_DSC_0532,
    Services_Accessories_DSC_0533,
    Services_Accessories_DSC_0534,
    Services_Accessories_DSC_0539,
    Services_Accessories_DSC_0540,
    Services_Accessories_DSC_0543,
    Services_Accessories_DSC_0544,
    Services_Accessories_DSC_0546,
    Services_Accessories_DSC_0547,
    Services_Accessories_DSC_0548,
    Services_Accessories_DSC_0549,
    Services_Accessories_DSC_0550,
    Services_Accessories_DSC_0551,
    Services_Accessories_DSC_0552,
    Services_Accessories_DSC_0553,
    Services_Accessories_DSC_0554,
    Services_Accessories_DSC_0555,
    Services_Accessories_DSC_0556,
    Services_Accessories_DSC_0557,
    Services_Accessories_DSC_0558,
    Services_Accessories_DSC_0563,
    Services_Accessories_DSC_0564,
    Services_Accessories_DSC_0565,
    Services_Accessories_DSC_0570,
    Services_Accessories_DSC_0576,
    Services_Accessories_DSC_0577,
    Services_Accessories_DSC_0578,
    Services_Accessories_DSC_0581,
    Services_Accessories_DSC_0582,
    Services_Accessories_DSC_0583,
    Services_Accessories_DSC_0584,
    Services_Accessories_DSC_0585,
}