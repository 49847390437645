import { useEffect, useRef } from "react";
import "./ServicesBox.scss";

export interface ServicesBoxParams {
    title: string,
    content: string,
    className?: string,
    img: string, imgWebp?: string, w?: string, h?: string, alt?: string,
}

const ServicesBox = ({title, content, img, alt, imgWebp, w, h, className = ""}: ServicesBoxParams) => {

    // const style = {
    //     background: 'url("'+ img +'")',
    //     backgroundSize: "cover",
    //     backgroundPosition: "center center",
    //     backgroundRepeat: "no-repeat",
    // }

    const imgRef = useRef<HTMLImageElement>(null);
    const servicesDivRef = useRef<HTMLDivElement>(null);
    const servicesOverlayWrapperDivRef = useRef<HTMLDivElement>(null);

    const resizeDivToImgHeight = () => {
        if (imgRef.current && servicesDivRef.current && servicesOverlayWrapperDivRef.current) {
            const height = imgRef.current.offsetHeight;
            servicesDivRef.current.style.height = height + "px";
            servicesOverlayWrapperDivRef.current.style.height = height + "px";
        }
    }

    useEffect(() => {
        window.addEventListener("resize", resizeDivToImgHeight);
        return () => window.removeEventListener("resize", resizeDivToImgHeight);
    }, [])

    return (
        <>
            <div className={"services-box " + className} ref={servicesDivRef}>
                <picture>
                    {imgWebp && <source type={"image/webp"} srcSet={imgWebp}/>}
                    <img
                        src={img} alt={alt?alt:title} ref={imgRef}
                        onLoad={resizeDivToImgHeight}
                        width={w?w:undefined} height={h?h:undefined}   
                    />
                </picture>
                <div className={"services-box-overlay-wrapper"} ref={servicesOverlayWrapperDivRef}>
                    <div className={"services-box-overlay"}></div>
                    <div className={"services-box-text-section t-align-center"} >
                        <h5 className={"white"}>{title}</h5>
                        <p className={"white"}>{content}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesBox;