import { useEffect, useMemo, useState } from "react";
import { PagedDiv } from "../../../Utils/js/Utils";

import "./ServicesGallery.scss";

export interface ServicesPhoto {
    img: string, alt?: string, imgWebp?: string, w?: string, h?: string,
}

export interface ServicesGalleryParams {
    photos: ServicesPhoto[],
}

export const ServicesGallery = ({photos}: ServicesGalleryParams) => {

    const [counter, setCounter] = useState(0);
    const [pagedDivInstanceDesktop, setPagedDivInstanceDesktop] = useState<PagedDiv | null>(null);
    const [pagedDivInstance, setPagedDivInstance] = useState<PagedDiv | null>(null);

    const [photosGrouped, setPhotosGrouped] = useState<ServicesPhoto[][] | null>(null);
    const [photosIndividual, setPhotosIndividual] = useState<ServicesPhoto[] | null>(null);

    useEffect( () => {
        let _pagedDivInstance = new PagedDiv(".services-gallery-paged", 0, true, true);
        let _pagedDivInstanceDesktop = new PagedDiv(".services-gallery-paged-d", 0, true, true);
        setPagedDivInstance(_pagedDivInstance);
        setPagedDivInstanceDesktop(_pagedDivInstanceDesktop);
    }, []);

    //timer is used to slide carousel
    useEffect(() => {
        const _timer = setInterval(() => {
            if (pagedDivInstance) {
                pagedDivInstance.slideLeft();
            }
            if (pagedDivInstanceDesktop) {
                pagedDivInstanceDesktop.slideLeft();
            }

            // counter should be updated on each loop
            setCounter(counter + 1);
        }, 3000);
        return () => clearInterval(_timer);
    }, [counter, pagedDivInstance, pagedDivInstanceDesktop]);

    useEffect(() => {
        const _photosFourGrouped = [];

        const IMAGES_PER_SLIDE = 2;
        for(let i = 0; i < photos.length; i = i + IMAGES_PER_SLIDE) {
            _photosFourGrouped.push(photos.slice(i, i + IMAGES_PER_SLIDE));
        }
        setPhotosGrouped(_photosFourGrouped);
        setPhotosIndividual(photos);
    }, [photos]);

    const photosGroupedJSX = useMemo(() => {
        const _photos = photosGrouped?.map( (photosGroup, index) => {
            const photosDiv = photosGroup?.map((photo, index) => {
                return (
                    <div className={"mb-col-12 t-col-6 d-col-6"} key={ photo.img + index }>
                        <div className={"services-gallery-paged-child"}>
                            <picture>
                                {photo.imgWebp && <source type={"image/webp"} srcSet={photo.imgWebp}/>}
                                <img
                                    src={photo.img} alt={photo.alt?photo.alt:"services"}
                                    width={photo.w?photo.w:undefined} height={photo.h?photo.h:undefined}    
                                />
                            </picture>
                        </div>
                    </div>
                );
            })
            return (
                <div className={"col-wrapper-12"} key={index}>
                    {photosDiv}
                </div>
            );
        });
        return _photos;
    }, [photosGrouped]);

    const photosIndividualJSX = useMemo(() => {
        let photoInd = photosIndividual?.map((photo, index) => {
            return  <div className={"services-gallery-paged-child"} key={ photo.img + index }>
                        <picture>
                            {photo.imgWebp && <source type={"image/webp"} srcSet={photo.imgWebp}/>}
                            <img
                                src={photo.img} alt={photo.alt?photo.alt:"services"}
                                width={photo.w?photo.w:undefined} height={photo.h?photo.h:undefined}    
                            />
                        </picture>
                    </div>
        })
        return photoInd;
    }, [photosIndividual]);

    return (
        <div className={"services-gallery"}>
            <div className={"hide-on-mb hide-on-t"}>
                <div className={"paged services-gallery-paged-d"}>
                    {photosGroupedJSX}
                </div>
            </div>
            <div className={"hide-on-d"}>
                <div className={"paged services-gallery-paged"}>
                    {photosIndividualJSX}
                </div>
            </div>
        </div>
    )
}