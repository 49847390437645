import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { LogoMultiCarRect } from '../../Assets/Assets';

import './Header.scss';
import { ROUTES } from '../Globals';

interface NavLinkParams {
    to: string,
    content: string,
}

const NavLink = ({to, content}: NavLinkParams) => {
    return (
        <h6><Link className={"secondary-color"} to={to}>{content}</Link></h6>
    );
}

interface NavLinksParams {
    navLinks: NavLinkParams[],
}

const NavLinks = ({navLinks}: NavLinksParams) => {

    const navLinkArray = navLinks.map( (navLink, index) => {
        return <NavLink to={navLink.to} content={navLink.content} key={navLink.to + navLink.content + index}/>
    });

    return (
        <>
            {navLinkArray}
        </>
    );
}

interface HeaderContactParams {
    icon: IconDefinition,
    title: string,
    content: string,
    className?: string,
}
const HeaderContact = ({icon, title, content, className = ""}: HeaderContactParams) => {
    return (
        <>
            <div className={"header-contact-wrapper"}>
                <div>
                    <h6 className={"header-contact-title"}>
                        <FontAwesomeIcon icon={icon} className={"header-contact-icon white"}/> {title}
                    </h6>
                    <p className={"header-contact-content"}>{content}</p>
                </div>
            </div>
        </>
    );
}

interface HeaderLocationParams {
    icon: IconDefinition,
    title: string,
    content: string,
    className?: string,
}
const HeaderLocation = ({icon, title, content, className = ""}: HeaderLocationParams) => {
    return (
        <>
            <div className={"header-location-wrapper"}>
                <div className={"header-location-title-wrapper"}>
                    <h6 className={"header-location-title"}>
                        {title} <FontAwesomeIcon icon={icon} className={"header-location-icon white"}/>
                    </h6>
                </div>
                <div>
                    <p className={"header-location-content"}>{content}</p>
                </div>
            </div>
        </>
    );
}

const HeaderOne = () => {
    return (
        <div className={"header-1"} id={"header-1"}>
            <div className={"hide-on-mb"}>
                <div className={"col-wrapper-12"}>
                    <div className={"mb-col-12 t-col-11 d-col-11"}>
                        <div className={"header-1-contact"}>
                            <HeaderLocation
                                icon={faMapMarkerAlt} title={"OUR LOCATION"} 
                                className={"address"}
                                content={"#764/765, Narayana Nagar, \n1st block, Kanakapura Rd, Doddakallasandra, \nUttarahalli Hobli, Bengaluru, Karnataka 560062"}/>
                            <HeaderContact
                                icon={faEnvelope} title={"CONTACT US"} 
                                className={"email"}
                                content={"9945888417 \ncontact@mycarmultibrand.com"}/>
                        </div>
                    </div>
                    <div className={"mb-col-12 t-col-1 d-col-1"}>
                        <div className={"header-follow-us"}>
                            <p>
                                <a target={"_blank"} rel="noreferrer" href={"https://www.facebook.com/mycarmultibrand/"}><FontAwesomeIcon icon={faFacebookF}/></a>
                            </p>
                            <p>
                                <a target={"_blank"} rel="noreferrer" href={"https://www.youtube.com/channel/UCr6GL8XJmYzEfvbYhwKc6_A"}><FontAwesomeIcon icon={faYoutube}/></a>
                            </p>
                            <p>
                                <a target={"_blank"} rel="noreferrer" href={"https://www.instagram.com/mycarmultibrand/"}><FontAwesomeIcon icon={faInstagram}/></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const HeaderTwo = () => {

    const [sidenav, setSideNav] = useState(false); // whether sidenav is opened

    // navigation bar links: content: name to be shown, to: href
    const navLinksArray = {
        navLinks: [
            {
                content: "HOME",
                to: ROUTES["HOMEPAGE"]
            },
            // {
            //     content: "ABOUT US",
            //     to: ROUTES["ABOUT"]
            // },
            // {
            //     content: "SERVICES",
            //     to: ROUTES["SERVICES"]
            // },
            {
                content: "BODY SHOP",
                to: ROUTES["BODY_SHOP"]
            },
            {
                content: "CERAMIC COATING",
                to: ROUTES["CERAMIC_COATING"]
            },
            {
                content: "PPF",
                to: ROUTES["PPF"]
            },
            {
                content: "CAR DETAILING",
                to: ROUTES["CAR_DETAILING"]
            },
            {
                content: " CAR WRAPPING ",
                to: ROUTES["CAR_WRAPPING"]
            },
            {
                content: "SUN PF",
                to: ROUTES["SPF"]
            },
            // {
            //     content: "PRICING",
            //     to: ROUTES["PRICING"]
            // },
            // {
            //     content: "FAQ",
            //     to: ROUTES["FAQ"]
            // },
            // {
            //     content: "BLOG",
            //     to: ROUTES["BLOG"]
            // },
            {
                content: "CONTACT",
                to: ROUTES["CONTACT"]
            },
        ]
    } as NavLinksParams;

    // toggle the side menu
    const toggleSideNav = useCallback(() => {
        let sidenavElem = document.querySelector(".sidenav") as HTMLElement;
        let dummySidenavElem = document.querySelector(".dummy-sidenav") as HTMLElement;

        if (!sidenavElem) {
            return;
        }

        if (sidenav) {
            dummySidenavElem.style.transform = "translateX(-150%)";
            sidenavElem.style.transform = "translateX(-150%)";
            setSideNav(false);
        }
        else {
            sidenavElem.style.transform = "translateX(0)";
            dummySidenavElem.style.transform = "translateX(0)";
            setSideNav(true);
        }
    }, [sidenav]);

    // detect whether user clicks outside the side nav menu
    const detectOutsideClickSideNav = useCallback ((e: any) => {
        let sidenavElem = document.querySelector(".sidenav") as HTMLElement;

        // if side nav is present, side nav is opened already and user didn't click side nav nor its children... 
        if (sidenavElem && sidenav && sidenavElem !== e.target && !sidenavElem.contains(e.target)) {
            // close it
            toggleSideNav();
        }
    }, [sidenav, toggleSideNav]);

    useEffect ( () => {
        document.addEventListener('click', detectOutsideClickSideNav);
        return () => {
            document.removeEventListener('click', detectOutsideClickSideNav);
        };
    }, [detectOutsideClickSideNav])

    //DO NOT DELETE THE FOLLOWING CODE!!! YOU WILL REGRET!!!
    //DO NOT DELETE THE FOLLOWING CODE!!! YOU WILL REGRET!!!
    //DO NOT DELETE THE FOLLOWING CODE!!! YOU WILL REGRET!!!
    // const navLinks =    <>
    //                         <h6><Link className={"secondary-color"} to={"#!"}>HOME</Link></h6>
    //                         <h6><Link className={"secondary-color"} to={"#!"}>ABOUT US</Link></h6>
    //                         <h6><Link className={"secondary-color"} to={"#!"}>SERVICES</Link></h6>
    //                         <h6><Link className={"secondary-color"} to={"#!"}>OUR TEAM</Link></h6>
    //                         <div className={"dropdown"}>
    //                             <h6><Link className={"secondary-color"} to={"#!"}>PAGES <i className={"material-icons"}>keyboard_arrow_down</i></Link></h6>
    //                             <div className="dropdown-content shadow-3 bg-white">
    //                                 <h6><Link className={"secondary-color"} to={"#!"}>APPOINMENTS</Link></h6>
    //                                 <h6><Link className={"secondary-color"} to={"#!"}>PRICING</Link></h6>
    //                                 <h6><Link className={"secondary-color"} to={"#!"}>FAQ</Link></h6>
    //                                 <h6><Link className={"secondary-color"} to={"#!"}>PROJECTS</Link></h6>
    //                                 <h6><Link className={"secondary-color"} to={"#!"}>PROJECT DETAILS</Link></h6>
    //                             </div>
    //                         </div>
    //                         <div className={"dropdown"}>
    //                             <h6><Link className={"secondary-color"} to={"#!"}>BLOG <i className={"material-icons"}>keyboard_arrow_down</i></Link></h6>
    //                             <div className="dropdown-content shadow-3 bg-white">
    //                                 <h6><Link className={"secondary-color"} to={"#!"}>BLOG</Link></h6>
    //                                 <h6><Link className={"secondary-color"} to={"#!"}>SINGLE BLOG</Link></h6>
    //                             </div>
    //                         </div>                                
    //                         <h6><Link className={"secondary-color"} to={"#!"}>CONTACT</Link></h6>
    //                     </>

    const navLinks = <NavLinks navLinks={navLinksArray.navLinks}/>

    return (
        <>
            <div className={"header-2"}>
                <div className={"col-wrapper-12"}>
                    <div className={"mb-col-12 t-col-12 d-col-12"}>
                        <div className={"header-2-links"}>
                            <Link to={ROUTES["HOMEPAGE"]}>
                                <picture>
                                    <source type={"image/webp"} srcSet={LogoMultiCarRect.webp}/>
                                    <img
                                        src={LogoMultiCarRect.png} alt={LogoMultiCarRect.alt}
                                        width={LogoMultiCarRect.w} height={LogoMultiCarRect.h}
                                        className={"header-logo"}
                                    />
                                </picture>
                            </Link>
                            <div className={"header-2-links-h6-wrapper"}>
                                {navLinks}
                                <div className={"header-2-menu-button-div"}>
                                    <button className={"button header-2-menu-button"} type={"button"} onClick={toggleSideNav}>
                                        <i className={"material-icons"}>menu</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"dummy-sidenav"}></div> { /* Required for translucent screen animation*/}
            <div className={"sidenav"}>
                <div className={"header-2-links-sidenav"}>
                    <div className={""}>
                        <button className={"right button header-2-menu-button"} type={"button"} onClick={toggleSideNav}>
                            <i className={"material-icons"}>close</i>
                        </button>
                    </div>
                    { navLinks }
                    <HeaderContact 
                        icon={faMapMarkerAlt} title={"OUR LOCATION"} 
                        className={"address"}
                        content={"#764/765, Narayana Nagar, \n1st block, Kanakapura Rd, Doddakallasandra, \nUttarahalli Hobli, Bengaluru, Karnataka 560062"}/>
                    <HeaderContact 
                        icon={faEnvelope} title={"CONTACT US"} 
                        className={"email"}
                        content={"9945888417\ncontact@mycarmultibrand.com"}/>
                </div>
            </div>
        </>
    );
}

const Header = () => {
    useEffect(() => {
        let header = document.getElementById("header-2-wrapper") as HTMLElement;
        let headerOne = document.getElementById("header-1") as HTMLElement;
        let main = document.getElementById("main") as HTMLElement;

        let sticky = header.offsetTop;

        window.addEventListener("scroll", () => {
            if (window.pageYOffset - headerOne.clientHeight > sticky) {
                header.classList.add("header-sticky");
                main.classList.add("main-padding");
            } else {
                header.classList.remove("header-sticky");
                main.classList.remove("main-padding");
            }
        })
    }, [])

    return (
        <>      
            <div className={""}>
                <HeaderOne />
                <div className={"header-2-wrapper"} id={"header-2-wrapper"}>  
                    <HeaderTwo />
                </div>
            </div>
        </>
    )
}

export default Header;