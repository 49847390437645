import CarDetailingBannerWebp from './Images/CarDetailing.webp';
import CarDetailingAllFinishWebp from './Images/CarDetailingAllFinish.webp';
import CarDetailingHeavyCutWebp from './Images/CarDetailingHeavyCut.webp';
import CarDetailingHeavyCutVOCFreeWebp from './Images/CarDetailingHeavyCutVOCFree.webp';
import CarDetailingMediumCutWebp from './Images/CarDetailingMediumCut.webp';
import CarDetailingProtectionWebp from './Images/CarDetailingProtection.webp';
import CarPolishingWebp from './Images/CarPolishing.webp';

// JPG START
import CarDetailingBannerJpg from './Images/CarDetailing.jpg';
import CarDetailingAllFinishJpg from './Images/CarDetailingAllFinish.jpg';
import CarDetailingHeavyCutJpg from './Images/CarDetailingHeavyCut.jpg';
import CarDetailingHeavyCutVOCFreeJpg from './Images/CarDetailingHeavyCutVOCFree.jpg';
import CarDetailingMediumCutJpg from './Images/CarDetailingMediumCut.jpg';
import CarDetailingProtectionJpg from './Images/CarDetailingProtection.jpg';
import CarPolishingJpg from './Images/CarPolishing.jpg';

export const CarDetailingBanner = {jpg: CarDetailingBannerJpg, webp: CarDetailingBannerWebp, alt: "Car Detailing", w: "1170", h: "460"};
export const CarDetailingAllFinish = {jpg: CarDetailingAllFinishJpg, webp: CarDetailingAllFinishWebp, alt: "Menzerna All Finish", w: "640", h: "640"};
export const CarDetailingHeavyCut = {jpg: CarDetailingHeavyCutJpg, webp: CarDetailingHeavyCutWebp, alt: "Menzerna Heavy Cut", w: "640", h: "640"};
export const CarDetailingHeavyCutVOCFree = {jpg: CarDetailingHeavyCutVOCFreeJpg, webp: CarDetailingHeavyCutVOCFreeWebp, alt: "Menzerna Heavy Cut VOC Free", w: "640", h: "640"};
export const CarDetailingMediumCut = {jpg: CarDetailingMediumCutJpg, webp: CarDetailingMediumCutWebp, alt: "Menzerna Medium Cut", w: "640", h: "639"};
export const CarDetailingProtection = {jpg: CarDetailingProtectionJpg, webp: CarDetailingProtectionWebp, alt: "Menzerna Protection", w: "640", h: "640"};
export const CarPolishing = {jpg: CarPolishingJpg, webp: CarPolishingWebp, alt: "Car Polishing", w: "640", h: "640"};

const base_path = "/new_images/car_detailing/";

const file_names: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    // "23",
    // "24",
    // "25",
    // "26",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "1": {w: 1080, h: 810},
    "2": {w: 1080, h: 810},
    "3": {w: 1080, h: 810},
    "4": {w: 1080, h: 810},
    "5": {w: 1080, h: 810},
    "6": {w: 1080, h: 810},
    "7": {w: 1080, h: 810},
    "8": {w: 1080, h: 810},
    "9": {w: 1080, h: 810},
    "10": {w: 810, h: 1080},
    "11": {w: 810, h: 1080},
    "12": {w: 810, h: 1080},
    "13": {w: 1080, h: 717},
    "14": {w: 1080, h: 717},
    "15": {w: 1080, h: 717},
    "16": {w: 1080, h: 810},
    "17": {w: 1080, h: 810},
    "18": {w: 1080, h: 810},
    "19": {w: 1080, h: 810},
    "20": {w: 1080, h: 810},
    "21": {w: 1080, h: 810},
    "22": {w: 810, h: 1080},
    // "23": {w: 1080, h: 810},
    // "24": {w: 1080, h: 810},
    // "25": {w: 1080, h: 810},
    // "26": {w: 810, h: 1080},
}

export const Car_Detailing = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});