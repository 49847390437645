import ShowroomTwentySevenJpg from './Images/showroom27.jpg';
import ShowroomTwentyNineJpg from './Images/showroom29.jpg';
import ShowroomThirtyOneJpg from './Images/showroom31.jpg';
import ShowroomThirtyEightJpg  from './Images/showroom38.jpg';
import ShowroomThirtyNineJpg  from './Images/showroom39.jpg';
import ShowroomFortyJpg  from './Images/showroom40.jpg';
import ShowroomFortyOneJpg  from './Images/showroom41.jpg';
import ShowroomFortyTwoJpg  from './Images/showroom42.jpg';
import ShowroomFortyThreeJpg  from './Images/showroom43.jpg';
import ShowroomFortyFourJpg  from './Images/showroom44.jpg';
import ShowroomFortyFiveJpg  from './Images/showroom45.jpg';
import ShowroomFortySixJpg  from './Images/showroom46.jpg';

import ShowroomTwentySevenWebp from './Images/showroom27.webp';
import ShowroomTwentyNineWebp from './Images/showroom29.webp';
import ShowroomThirtyOneWebp from './Images/showroom31.webp';
import ShowroomThirtyEightWebp  from './Images/showroom38.webp';
import ShowroomThirtyNineWebp  from './Images/showroom39.webp';
import ShowroomFortyWebp  from './Images/showroom40.webp';
import ShowroomFortyOneWebp  from './Images/showroom41.webp';
import ShowroomFortyTwoWebp  from './Images/showroom42.webp';
import ShowroomFortyThreeWebp  from './Images/showroom43.webp';
import ShowroomFortyFourWebp  from './Images/showroom44.webp';
import ShowroomFortyFiveWebp  from './Images/showroom45.webp';
import ShowroomFortySixWebp  from './Images/showroom46.webp';

const ShowroomTwentySeven = {jpg: ShowroomTwentySevenJpg, webp: ShowroomTwentySevenWebp, w: "1000", h: "664", alt: "My Car Multibrand Showroom"};
const ShowroomTwentyNine = {jpg: ShowroomTwentyNineJpg, webp: ShowroomTwentyNineWebp, w: "1000", h: "664", alt: "My Car Multibrand Showroom"};
const ShowroomThirtyOne = {jpg: ShowroomThirtyOneJpg, webp: ShowroomThirtyOneWebp, w: "920", h: "1385", alt: "My Car Multibrand Showroom"};
const ShowroomThirtyEight = {jpg: ShowroomThirtyEightJpg, webp: ShowroomThirtyEightWebp, w: "1000", h: "665", alt: "My Car Multibrand Showroom"};
const ShowroomThirtyNine = {jpg: ShowroomThirtyNineJpg, webp: ShowroomThirtyNineWebp, w: "1000", h: "664", alt: "My Car Multibrand Showroom"};
const ShowroomForty = {jpg: ShowroomFortyJpg, webp: ShowroomFortyWebp, w: "1000", h: "664", alt: "My Car Multibrand Showroom"};
const ShowroomFortyOne = {jpg: ShowroomFortyOneJpg, webp: ShowroomFortyOneWebp, w: "1000", h: "664", alt: "My Car Multibrand Showroom"};
const ShowroomFortyTwo = {jpg: ShowroomFortyTwoJpg, webp: ShowroomFortyTwoWebp, w: "1000", h: "664", alt: "My Car Multibrand Showroom"};
const ShowroomFortyThree = {jpg: ShowroomFortyThreeJpg, webp: ShowroomFortyThreeWebp, w: "1000", h: "750", alt: "My Car Multibrand Showroom"};
const ShowroomFortyFour = {jpg: ShowroomFortyFourJpg, webp: ShowroomFortyFourWebp, w: "1000", h: "664", alt: "My Car Multibrand Showroom"};
const ShowroomFortyFive = {jpg: ShowroomFortyFiveJpg, webp: ShowroomFortyFiveWebp, w: "1024", h: "768", alt: "My Car Multibrand Showroom"};
const ShowroomFortySix = {jpg: ShowroomFortySixJpg, webp: ShowroomFortySixWebp, w: "750", h: "1000", alt: "My Car Multibrand Showroom"};

export {
    ShowroomTwentySeven,
    ShowroomTwentyNine,
    ShowroomThirtyOne,
    ShowroomThirtyEight,
    ShowroomThirtyNine,
    ShowroomForty,
    ShowroomFortyOne,
    ShowroomFortyTwo,
    ShowroomFortyThree,
    ShowroomFortyFour,
    ShowroomFortyFive,
    ShowroomFortySix,
};