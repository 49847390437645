const base_path = "/new_images/ceramic_coating/12__Toyota_Innova_Crysta/";

const file_names: string[] = [
    "1",
    "2",
    "5",
    "7",
    "8", // preview
    "File_004",
    "IMG_1012",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "1": {w: 1080, h: 810},
    "2": {w: 1080, h: 810},
    "5": {w: 1080, h: 717},
    "7": {w: 1080, h: 717},
    "8": {w: 1080, h: 810},
    "File_004": {w: 810, h: 1080},
    "IMG_1012": {w: 1080, h: 810},
}

export const Toyota_Innova_Crysta = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Toyota Innova Crysta",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});