import MGHectorWindowMesh1Webp from "./Images/1.webp"
import MGHectorWindowMesh13Webp from "./Images/13.webp"
import MGHectorWindowMesh2Webp from "./Images/2.webp"
import MGHectorWindowMesh5Webp from "./Images/5.webp"
import MGHectorWindowMesh6Webp from "./Images/6.webp"
import MGHectorWindowMesh7Webp from "./Images/7.webp"

import MGHectorWindowMesh1Jpg from "./Images/1.jpg"
import MGHectorWindowMesh13Jpg from "./Images/13.jpg"
import MGHectorWindowMesh2Jpg from "./Images/2.jpg"
import MGHectorWindowMesh5Jpg from "./Images/5.jpg"
import MGHectorWindowMesh6Jpg from "./Images/6.jpg"
import MGHectorWindowMesh7Jpg from "./Images/7.jpg"

export const MGHectorWindowMesh1 = {jpg: MGHectorWindowMesh1Jpg, webp: MGHectorWindowMesh1Webp, alt: "MG Hector Window Mesh", w: "3024", h: "4032"}
export const MGHectorWindowMesh13 = {jpg: MGHectorWindowMesh13Jpg, webp: MGHectorWindowMesh13Webp, alt: "MG Hector Window Mesh", w: "4032", h: "3024"}
export const MGHectorWindowMesh2 = {jpg: MGHectorWindowMesh2Jpg, webp: MGHectorWindowMesh2Webp, alt: "MG Hector Window Mesh", w: "3024", h: "4032"}
export const MGHectorWindowMesh5 = {jpg: MGHectorWindowMesh5Jpg, webp: MGHectorWindowMesh5Webp, alt: "MG Hector Window Mesh", w: "3024", h: "4032"}
export const MGHectorWindowMesh6 = {jpg: MGHectorWindowMesh6Jpg, webp: MGHectorWindowMesh6Webp, alt: "MG Hector Window Mesh", w: "3024", h: "4032"}
export const MGHectorWindowMesh7 = {jpg: MGHectorWindowMesh7Jpg, webp: MGHectorWindowMesh7Webp, alt: "MG Hector Window Mesh", w: "3024", h: "4032"}