import CeramicCoatingOneJpg from './Images/ceramic-coating-1.jpg';
import SiriusPng from './Images/sirius.png';

import CeramicCoatingOneWebp from './Images/ceramic-coating-1.webp';
import SiriusWebp from './Images/sirius.webp';

export const CeramicCoatingOne = {jpg: CeramicCoatingOneJpg, webp: CeramicCoatingOneWebp, alt: "Ceramic Coating", w: "1029", h: "527"};
export const Sirius = {png: SiriusPng, webp: SiriusWebp, alt: "Sirius Pro", w: "1223", h: "1028"};

export * from "./Images/1__BMW_3_Series_330Li";
export * from "./Images/2__Mini_Cooper_JCW";
export * from "./Images/3__KIA_Carnival";
export * from "./Images/4__BMW_X1";
export * from "./Images/5__MG_Hector";
export * from "./Images/6__KIA_Seltos";
export * from "./Images/7__Toyota_Fortuner";
export * from "./Images/8__BMW_S_1000_RR";
export * from "./Images/9__Maruti_Suzuki_Kiazashi";
export * from "./Images/10__Hyundai_Creta";
export * from "./Images/11__Volkswagen_POLO";
export * from "./Images/12__Toyota_Innova_Crysta";
export * from "./Images/13__Hyundai_Venue";
export * from "./Images/14__Maruti_Suzuki_Brezza";
export * from "./Images/15__Maruti_Suzuki_Ertiga";
export * from "./Images/16__Others";
export {CeramicCoating as CC_Images} from "./Images/Gallery";