const base_path = "/new_images/ppf/5__Maruti_Suzuki_S_Cross/";

const file_names: string[] = [
    "IMG_4916",
    "IMG_4936",
    "IMG_4938",
    "IMG_4959",
    "IMG_4971",
    "IMG_4981",
    "IMG_4985",
    "IMG_5006",
    "IMG_5007",
    "IMG_5008",
    "IMG_5010",
]

const file_names_sizes: {[key: string]: {w: number, h: number}} = {
    "IMG_4916": {w: 1920, h: 1440},
    "IMG_4936": {w: 1920, h: 1440},
    "IMG_4938": {w: 1920, h: 1080},
    "IMG_4959": {w: 1920, h: 1080},
    "IMG_4971": {w: 1920, h: 1440},
    "IMG_4981": {w: 1920, h: 1440},
    "IMG_4985": {w: 1920, h: 1080},
    "IMG_5006": {w: 1920, h: 1440},
    "IMG_5007": {w: 1920, h: 1440},
    "IMG_5008": {w: 1920, h: 1440},
    "IMG_5010": {w: 1920, h: 1440},
}

export const Maruti_Suzuki_S_Cross = file_names.map((f) => {
    const size = file_names_sizes[f];

    return {
        jpg: base_path+f+".jpeg",
        webp: base_path+f+".webp",
        alt: "Maruti Suzuki S Cross",
        w: size.w.toString(),
        h: size.h.toString(),
    }
});