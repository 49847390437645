import "./PageLocation.scss";

interface PageLocationParams {
    title: string,
    locations: string[],
    img: string, alt?: string, imgWebp?: string, w?: string, h?: string,
}

const PageLocation = ({title, locations, img, alt, imgWebp, w, h}: PageLocationParams) => {
    let content = locations.map( (location, index, locations) => {
        if (index === locations.length - 1) {
            return <span key={index}>{location}</span>
        }
        return <span key={index}>{location} <i className={"material-icons primary-color"}>chevron_right</i></span>
    })

    return (
        <>
            <div className={"page-location"}>
                <picture>
                    {imgWebp && <source type={"image/webp"} srcSet={imgWebp}/>}
                    <img
                        src={img} alt={alt?alt:"page-location"}
                        width={w?w:undefined} height={h?h:undefined}    
                    />
                </picture>
                <div className={"page-location-content-outer"}>
                    <div className={"page-location-content white"}>
                        <h4 className={"t-align-center"}>{title}</h4>
                        <p className={"t-align-center white"}>
                            {content}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageLocation;