import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postQuoteRequestForm, reset } from "../../../Store/Slices/QuoteRequestForm/QuoteRequestFormSlice";
import { IQuoteRequestForm } from "../../Api/Form/Types/Form/Form";
import { YoutubeEmbed } from "../Components";

import "./ContactForm.scss";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    // const [subject, setSubject] = useState("");
    const [msg, setMsg] = useState("");

    const [emailSent, setEmailSent] = useState(false);

    const dispatch = useDispatch();
    const quoteRequest = useSelector( (state: {quoteRequestForm: IQuoteRequestForm}) => state.quoteRequestForm);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch])

    useEffect(() => {
        (quoteRequest.request_states.post.fulfilled)? setEmailSent(true):setEmailSent(false);
    }, [quoteRequest.request_states.post.fulfilled])

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        dispatch(reset());
        dispatch(postQuoteRequestForm({
            email: email,
            name: name,
            phone: phone,
            // subject: subject,
            message: msg,
        }));
    }
    
    return (
        <div className={"contact-form"}>
            <div className={"col-wrapper-7"}>
                <div className={"mb-col-7 t-col-4 d-col-4"}>
                    <div className={"contact-form-exp center"}>
                        <h3 className={"secondary-color"}>WATCH US</h3>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse</p> */}
                    </div>
                    <div className={"contact-form-exp-cards center"}>
                        <div className={"contact-form-big-yt"}>
                            <YoutubeEmbed 
                                link={"https://www.youtube.com/embed/khV9BN_RRXg?autoplay=1"}
                                title={"Video"}
                                id={"contact-yt-iframe"}
                            />
                        </div>
                    </div>
                </div>
                <div className={"mb-col-7 t-col-3 d-col-3"}>
                    <div className={"contact-form-quote center"}>
                        <div className="contact-form-quote-title t-align-center white">
                            <h4>REQUEST A QUOTE</h4>
                        </div>
                        <form className={"contact-form-quote-form"} onSubmit={handleFormSubmit}>
                            {
                                (emailSent) &&
                                    <div className={"contact-form-notified-div"}>
                                        <h6 className={"secondary-color t-align-center"}>
                                            We have been notified. Thank you for filling out the form!
                                        </h6>
                                    </div>
                            }
                            <div className={"contact-form-error-div"}>
                                {
                                    quoteRequest.detail.validation_errors.name.map((errorMsg, index) => {
                                        return (
                                            <p 
                                                key={"name_validation_errors"+index}
                                                className="t-align-center red-a700"
                                            >{errorMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            <div className="input-div icon contact-form-quote-input">
                                <input 
                                    id="contact-form-quote-input-name"
                                    className="border-white " 
                                    type="text" 
                                    placeholder=" " 
                                    value={name}
                                    onChange={e => { e.preventDefault(); setName(e.target.value); }}
                                    // required
                                />
                                <label htmlFor="contact-form-quote-input-name">Name</label>
                                <i className="material-icons">perm_identity</i>
                            </div>
                            <div className={"contact-form-error-div"}>
                                {
                                    quoteRequest.detail.validation_errors.email.map((errorMsg, index) => {
                                        return (
                                            <p 
                                                key={"email_validation_errors"+index}
                                                className="t-align-center red-a700"
                                            >{errorMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            <div className="input-div icon contact-form-quote-input">
                                <input 
                                    id="contact-form-quote-input-email"
                                    className="border-white " 
                                    type="text" 
                                    placeholder=" " 
                                    value={email}
                                    onChange={e => { e.preventDefault(); setEmail(e.target.value); }}
                                    // required
                                />
                                <label htmlFor="contact-form-quote-input-email">Email</label>
                                <i className="material-icons">email</i>
                            </div>
                            <div className={"contact-form-error-div"}>
                                {
                                    quoteRequest.detail.validation_errors.phone.map((errorMsg, index) => {
                                        return (
                                            <p key={"phone_validation_errors"+index} className="t-align-center red-a700">{errorMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            <div className="input-div icon contact-form-quote-input">
                                <input 
                                    id="contact-form-quote-input-phone"
                                    className="border-white " 
                                    type="text" 
                                    placeholder=" " 
                                    value={phone}
                                    onChange={e => { e.preventDefault(); setPhone(e.target.value); }}
                                    required
                                />
                                <label htmlFor="contact-form-quote-input-phone">Phone</label>
                                <i className="material-icons">phone</i>
                            </div>
                            {/* <div className={"contact-form-error-div"}>
                                {
                                    quoteRequest.detail.validation_errors.subject.map((errorMsg, index) => {
                                        return (
                                            <p key={"subject_validation_errors"+index}>{errorMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            <div className="input-div icon contact-form-quote-input">
                                <input 
                                    id="contact-form-quote-input-subject"
                                    className="border-white " 
                                    type="text" 
                                    placeholder=" " 
                                    value={subject}
                                    onChange={e => { e.preventDefault(); setSubject(e.target.value); }}
                                    // required
                                />
                                <label htmlFor="contact-form-quote-input-subject">Subject</label>
                                <i className="material-icons">subject</i>
                            </div> */}
                            <div className={"contact-form-error-div"}>
                                {
                                    quoteRequest.detail.validation_errors.message.map((errorMsg, index) => {
                                        return (
                                            <p key={"msg_validation_errors"+index}>{errorMsg}</p>
                                        )
                                    })
                                }
                            </div>
                            <div className="input-div icon contact-form-quote-input-textarea">
                                <textarea 
                                    className="border-white"
                                    id="contact-form-quote-input-msg"
                                    placeholder=" "
                                    value={msg}
                                    onChange={e => { e.preventDefault(); setMsg(e.target.value); }}
                                    rows={5}
                                    // required
                                />
                                <label htmlFor="contact-form-quote-input-msg">Message</label>
                                <i className="material-icons">message</i>
                            </div>
                            <div className="">
                                <button
                                    className="button contact-form-quote-send-btn"
                                    type="submit">SUBMIT INQUERY</button>
                            </div>     
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;