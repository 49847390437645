// const URL = "http://localhost/"
// const URL = "http://localhost:8001/"
// const URL = "http://13.127.82.144/"
const URL = "https://normal-user-api.mycarmultibrand.com/"
const API_BASE_URL = URL + "normal-user/api/";
const BLOG_URL = API_BASE_URL + "blog/";
export const BLOG_FILTER_URL = API_BASE_URL + "blog/?search=blogs";
export const COMMENT_URL = {
    "URL": `${API_BASE_URL}comment/`,
    "FILTER_BLOG": ({blogId}: {blogId: string}) => `${API_BASE_URL}comment/?blog=${blogId}`,
    "UPVOTE_ID": ({id}: {id: number}) => `${API_BASE_URL}comment/${id}/upvote/`,
    "UPVOTE_URL": ({url}: {url: string}) => `${url}upvote/`,
    "DOWNVOTE_ID": ({id}: {id: number}) => `${API_BASE_URL}comment/${id}/downvote/`,
    "DOWNVOTE_URL": ({url}: {url: string}) => `${url}downvote/`,
};

const DEFAULT_SCOPE = "normal-user-read normal-user-write";

const FORM_EMAIL_URL = {
    "popup_email": API_BASE_URL + "popup-form-submission/",
    "quote_request_email": API_BASE_URL + "quote-request-form-submission/",
}

const ROUTES = {
    'HOMEPAGE': '/',
    'ABOUT': '/about',
    'SERVICES': '/services',
    "PRICING": '/pricing',
    "FAQ": '/faq',
    'BLOG': '/blog',
    'BLOG_WITH_PARAMS': '/blog/:blogId/:blogSlug?',
    "CONTACT": '/contact',
    "CERAMIC_COATING": '/ceramic-coating',
    "CAR_DETAILING": '/car-detailing',
    "CAR_WRAPPING": '/car-wrapping',
    "PPF": '/paint-protection-film',
    "SPF": '/sun-protection-film',
    "BODY_SHOP": '/body-shop',
    "SINGLE_BLOG": '/blog/single-blog',
    'NOT_FOUND': '/404',
}

export {
    ROUTES,
    URL,
    FORM_EMAIL_URL,
    DEFAULT_SCOPE,
    BLOG_URL,
}
