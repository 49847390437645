import anime from "animejs";
import { useEffect, useState } from "react";
import { ShowroomThirtyNine } from "../../../Assets/Assets";
import { isInViewport } from "../../../Utils/js/Utils";

import "./ChooseUsSection.scss";

const ChooseUsSection = () => {

    const [animate, setAnimate] = useState(false);

    useEffect( () => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [])

    useEffect ( () => {
        if (animate) {
            anime({
                targets: '.choose-us-text-bar-1',
                width: '86%',
                easing: 'easeInOutQuad',
                direction: 'forwards',
                duration: 2500,
                loop: false
            });
            anime({
                targets: '.choose-us-text-bar-2',
                width: '89%',
                easing: 'easeInOutQuad',
                direction: 'forwards',
                duration: 2300,
                loop: false
            });
            anime({
                targets: '.choose-us-text-bar-3',
                width: '88%',
                easing: 'easeInOutQuad',
                direction: 'forwards',
                duration: 2000,
                loop: false
            });
        }
    }, [animate])

    const handleScroll = () => {   
        let  chooseUsDiv = document.querySelector(".choose-us-text") as HTMLDivElement;
        if (isInViewport(chooseUsDiv)) {
            setAnimate(true);
        }
    }

    return (
        <div className={"choose-us"}>
            <div className={"col-wrapper-12"}>
                <div className={"mb-col-12 t-col-6 d-col-6"}>
                    <div className={"choose-us-text center"}>
                        <h6 className={"choose-us-text-title primary-color"}>WHY CHOOSE US</h6>
                        <h3 className={"choose-us-text-para secondary-color"}>WE ARE QUALIFIED & PROFESSIONAL</h3>
                        <p className={"choose-us-text-para-1 secondary-color"}>EXPERIENCED TECHNICIANS</p>
                        <div className={"choose-us-text-bar-wrapper"}>
                            <div className={"choose-us-text-bar-1 choose-us-text-bar"}></div>
                        </div>
                        <p className={"choose-us-text-para-2 secondary-color"}>CUSTOMER SATISFACTION</p>
                        <div className={"choose-us-text-bar-wrapper"}>
                            <div className={"choose-us-text-bar-2 choose-us-text-bar"}></div>
                        </div>
                        <p className={"choose-us-text-para-3 secondary-color"}>QUALITY SERVICES</p>
                        <div className={"choose-us-text-bar-wrapper"}>
                            <div className={"choose-us-text-bar-3 choose-us-text-bar"}></div>
                        </div>
                    </div>
                </div>
                <div className={"mb-col-12 t-col-6 d-col-6 hide-on-mb"}>
                    <div className={"choose-us-img-wrapper"}>
                        <div className={"choose-us-img-overlay"}>
                            <div className={"choose-us-img"}>
                                <picture>
                                    {ShowroomThirtyNine.webp && <source type={"image/webp"} srcSet={ShowroomThirtyNine.webp}/>}
                                    <img
                                        src={ShowroomThirtyNine.jpg} alt={"Choose Us"}
                                        width={ShowroomThirtyNine.w?ShowroomThirtyNine.w:undefined} height={ShowroomThirtyNine.h?ShowroomThirtyNine.h:undefined}    
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseUsSection;